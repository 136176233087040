import React from 'react';
import { Form, Button, Spinner, Alert, Modal, Image } from 'react-bootstrap';
import firebase from '../firebase';
import Config from '../../firebase-configs.json';
import { connect } from 'react-redux';
// import { addQuotes, updateQuote } from "./quotes-fs";
import { profileAddEditModal } from '../redux/templ'
import { addListDataRow } from '../listdetails/listdetails-fs';

const mapStateToProps = (state) => {
    return {
        profileModal: state?.tempData?.profileModal,
        list: state?.application?.list,
        listData: state?.application?.listData
    }
}
class AddEditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.getInitialState;
        console.log("AddEditProfile:", props)
    }
    getInitialState = () => {
        return {
            form: {},
            validated: false,
            saving: false,
            uploadStatus: false
        }
    }
    handleClose = () => {
        profileAddEditModal({ show: false, type: null })
    }
    onInputChange = (e) => {
        console.log("AddEditProfile::onInputchange,", this.state)
        let obj = {};
        obj[e.target.id] = e.target.value;
        let newForm = { ...this.state.form, ...obj };
        this.setState({ ...this.state, form:newForm });
    }
    onSubmit = async (e) => {
        console.log("AddEditProfile::onSubmit,", this.props.profileModal)
        e.preventDefault();
        this.setState({ ...this.state, ...{ validated: true } })
        if (e.target.checkValidity()) {
            let frm = {...this.state.form};
            // frm.timestamp = new Date(this.state.timestamp).getTime()
            console.log(frm)
            this.setState({ ...this.state, saving: true });
            let listId = this.props?.listId;
            let isChild = this.props?.isChild;
            try {
                if (this.props?.profileModal?.type === 'add') {
                    // await addQuotes(listId, frm);
                    await addListDataRow(listId, frm, isChild);
                    this.setState(this.getInitialState())
                    this.handleClose();
                } else {
                    frm.id = this.state.id;
                    let oldImage = this.props.profileModal.data.name;
                    if (frm.name !== oldImage) {
                        await this.deleteImage(oldImage);
                    }
                    // await updateQuote(listId, frm.id, frm);
                    this.setState({})
                    this.handleClose();
                }
            } catch (error) {
                this.setState({ ...this.state, ...{ saving: false, msg: 'danger', msgbody: error.message } })
            }
        }
    }
    componentDidMount() {
        console.log("AddEditProfile:componentDidMount", this.state)
    }
    componentDidUpdate() {
        console.log("AddEditProfile:componentDidUpdate", this.state)
    }

    deleteImage = async (imageName) => {
        var storage = firebase.app().storage(Config.quotesBucket);
        var removeRef = storage.ref('/' + 'images' + '/').child(imageName);
        return removeRef.delete().then(() => {
            return true;
        }).catch((error) => {
            console.log('// File Not deleted. Any Problem.');
        });
    }

    handleImageUpload = (e, imageAttrName) => {
        let target;
        if(imageAttrName === 'imageUrl') {
            target = 'images';
        } else {
            target = 'profile-images';
        }
        let that = this;
        const image = e.target.files[0];
        if (image && image.name) {
            let storage = firebase.storage();
            let uploadTask = storage.ref(`/${target}/${image.name}`).put(image);
            uploadTask.on('state_changed',
                (snapShot) => {
                    // let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
                    // per = parseInt(per);
                    console.log("Uploading snapshot changed")
                    this.setState({ ...this.state, ...{ uploadStatus: true } })
                }, (err) => {
                    console.log("Uploading snapshot error")
                    this.setState({ ...this.state, ...{ uploadStatus: false } })
                }, () => {
                    storage.ref(target).child(image.name).getDownloadURL()
                        .then((fireBaseUrl) => {
                            let newForm = { ...this.state.form };
                            newForm[imageAttrName] = fireBaseUrl;
                            this.setState({ ...this.state, form: newForm, uploadStatus: false });
                        })
                })
        } else {
            console.log('pass image name')
        }
    }

    render() {
        console.log("AEP:render:", this.state)
        return (
            <>
                <Modal show={this.props?.profileModal?.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props?.profileModal?.type === 'add' ? 'Add data for a Row' : 'Edit data'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto'}}>
                        <Form onSubmit={this.onSubmit} noValidate validated={this.state.validated}>
                            <div className="row mt-3 justify-content-center">
                                {this.props?.columns?.map(col => {
                                    if(col.key === "imageUrl" || col.key === "bioImageUrl"){
                                        return (
                                            <div className="col-4  text-center">
                                            <Form.Group controlId={col.key} >
                                                <Form.Label>{col.name}</Form.Label><br/>
                                                <Image src={this.state?.url}
                                                    hidden={!this.state?.url} rounded thumbnail className={'col-6'} />
                                                <div className="row align-middle pl-3 mt-2 pr-3">
                                                    <Form.File type="file" accept="image/*" className="col-12 border pl-0"
                                                        onChange={(e) => this.handleImageUpload(e, col.key)}/>
                                                </div>
                                                <p hidden={!this.state.imageUploading}><Spinner animation="grow" size="sm" /> uploading...</p>
                                            </Form.Group>
                                            </div>
                                        )
                                    } else if(!col.hide) {
                                        return (
                                            <div className="col-4  text-center">
                                            <Form.Group controlId={col.key} >
                                                <Form.Label>{col.name}</Form.Label>
                                                <Form.Control 
                                                    type="text"
                                                    className="form-control"
                                                    defaultValue={this.state?.quoteText}
                                                    onChange={this.onInputChange}
                                                    required={col.key === "firstname" || col.key === "lastname" || col.key === "name"}
                                                />
                                            </Form.Group>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                            <div className="row mt-3 justify-content-center">
                                <div className="col-6  text-center">
                                    {
                                        this.state?.saving || this.state?.uploadStatus
                                        ? 
                                            this.state?.saving ? <Button>
                                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                saving...
                                            </Button>
                                            :
                                            this.state?.uploadStatus && <Button>
                                                <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                uploading...
                                            </Button>
                                        :
                                        <button type="submit" className="btn btn-primary btn-block text-white">SUBMIT</button>
                                    }
                                </div>
                            </div>
                            <br></br>
                            {/* <Alert variant={this.state.msg} style={{ textAlign: 'center' }}>
                                {this.state.msgbody}
                            </Alert> */}
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}



export default connect(mapStateToProps)(AddEditProfile);