import { SET_SUBDOMAINS, sdbRFHelper } from '../subdomains/subdomains-rx';
import { SET_PROFILE, LOGOUT } from '../auth/login-rx';
import { SET_LISTING_TYPES_BY_DOMAIN } from '../listingtypes/linstingtypes-rx';
import { LOAD_LIST, LOAD_CHILD_LIST, LOAD_LIST_DATA, LOAD_CHILD_LIST_DATA, LOAD_LIST_META_DATA, UPDATE_LIST, 
        LOAD_LISTING_TYPE, LOAD_CHILD_LISTING_TYPE, LOAD_ACTIVE_BIO, LOAD_LISTING_TYPES,
        LOAD_CATEGORY_GRID_DATA, REMOVE_CATEGORY_GRID_DATA, LOAD_ACTIVE_COUNTRY, LOADING_CONSUMER_DATA,
        LOAD_CATEGORY_DROPDOWN, LOAD_ACTIVE_BIO_QUOTE, REMOVE_ACTIVE_BIO_QUOTE 
    } 
from '../listdetails/listdetails-rx'
import { store } from '../store';
import { sortBy, isString } from 'underscore'
import _ from "underscore";
import { isMobileOnly } from 'react-device-detect';

const RESET_APP = 'RESET_APP';
//action creators
const loadLsToRxAc = () => {
    return {
        type: 'LS_TO_RX'
    }
}
const loadModeAc = (payload) => {
    return {
        type: 'LOAD_MODE',
        payload
    }
}
const setAppCacheAc = (payload) => {
    return {
        type: 'SET_APP_CACHE',
        payload
    }
}
const resetAppAc = (payload) => {
    return {
        type: RESET_APP,
        payload
    }
}
const loadAppBarVisiblilityAc = (payload) => {
    return {
        type: 'LOAD_APP_NAV_BAR_VISIBILITY',
        payload
    }
}
const updateEditedStylesAc = (payload) => {
    return {
        type: 'UPDATE_EDITED_STYLES',
        payload
    }
}
const exportExcelCountAc = (payload) => {
    return {
        type: 'EXPORT_EXCEL_COUNT',
        payload
    }
}
const ogMetaDetailAc = (payload) => {
    return {
        type: 'OG_META',
        payload
    }
}
const sortedAgTableDataAc = (payload) => {
    return {
        type: 'SORTED_AG_TABLE_DATA',
        payload
    }
}

//actions
export const loadLsToRx = () => {
    store.dispatch(loadLsToRxAc())
}

export const loadApplicationMode = (mode) => {
    store.dispatch(loadModeAc(mode))
}

export const setAppCache = (uuidAndYear) => {
    let cache = {...uuidAndYear};
    if(uuidAndYear.uuid === "socialEntrepreneurs"){
        cache.needsSquareBio = true;
        cache.categoryColumn = "subCategory";
        cache.sortCategoriesDropdown = false;
        cache.bioImageFolderName = 'images'
        cache.bioImageURLPropName = 'imageUrl'
        cache.needsTestimonial = false;
        cache.needsMainPageDescription = false;
        cache.needsVideo = false;
        cache.needsImage = true;
    } else if(uuidAndYear.uuid === "philanthropyLeaders"){
        cache.needsSquareBio = true;
        cache.categoryColumn = "subCategory";
        cache.sortCategoriesDropdown = false;
        cache.bioImageFolderName = 'images'
        cache.bioImageURLPropName = 'imageUrl'
        cache.needsTestimonial = true;
        cache.needsMainPageDescription = false;
        cache.needsVideo = true;
    } else if(uuidAndYear.uuid === "governmentLeaders"){
        cache.needsSquareBio = true;
        cache.categoryColumn = "subCategory";
        cache.sortCategoriesDropdown = false;
        cache.bioImageFolderName = 'images'
        cache.bioImageURLPropName = 'imageUrl'
        cache.needsTestimonial = false;
        cache.needsMainPageDescription = true;
        cache.needsVideo = true;
    } else {
        cache.needsSquareBio = false;
        cache.categoryColumn = "industry";
        cache.sortCategoriesDropdown = true;
        cache.bioImageFolderName = 'profile-images'
        cache.bioImageURLPropName = 'bioImageUrl'
        cache.needsTestimonial = false;
        cache.needsMainPageDescription = true;
        cache.needsVideo = true;
    }
    store.dispatch(setAppCacheAc(cache))
}

export const resetApp = (payload) => {
    store.dispatch(resetAppAc(payload))
}
export const loadAppBar = (validity) => {
    store.dispatch(loadAppBarVisiblilityAc(validity))
}

export const updatedEditedStyle = (payload) => {
    store.dispatch(updateEditedStylesAc(payload))
}
export const exportExcelCount = (payload) => {
    store.dispatch(exportExcelCountAc(payload))
}
export const ogMetaDetail = (payload) => {
    store.dispatch(ogMetaDetailAc(payload))
}
export const sortedAgTableData = (payload) => {
    store.dispatch(sortedAgTableDataAc(payload))
}

//reducer
let getProtoFunctions = () => {
    let getStyle = (key, json, applicationMode, edit, subEdit) => {
        if (json && json.length) {
            let defaultMeta = json[0];
            let draftMeta = json[1];
            let publishedMeta = json[2];
            if (applicationMode === 'admin') {
                const style = draftMeta[key]?.style || defaultMeta[key]?.style;
                return style;
            } else if (applicationMode === 'consumer') {
                return publishedMeta[key]?.style || defaultMeta[key]?.style
            }
            return;
        }
    }
    let getValue = (key, json, applicationMode, edit) => {
        if (json && json.length) {
            let defaultMeta = json[0];
            let draftMeta = json[1];
            let publishedMeta = json[2];
            if (applicationMode === 'admin') {
                return draftMeta[key]?.value || defaultMeta[key]?.value
            } else if (applicationMode === 'consumer') {
                return publishedMeta[key]?.value || defaultMeta[key]?.value
            }
            return;
        }
    }
    let getImage = (key, json, applicationMode, edit) => {
        if (json && json.length) {
            let defaultMeta = json[0];
            let draftMeta = json[1];
            let publishedMeta = json[2];
            if (applicationMode === 'admin') {
                return draftMeta[key]?.image?.url || defaultMeta[key]?.image?.url
            } else if (applicationMode === 'consumer') {
                return publishedMeta[key]?.image?.url || defaultMeta[key]?.image?.url
            }
            return;
        }
    }
    let getDataByClmn = (key, data) => {
        if (data && data.length) {
            let dt = []
            data.forEach(ele => {
                if (ele[key]) {
                    // let val = ele[key].toLowerCase();
                    dt.push(ele[key])
                }
            })
            let res = [...new Set(dt)];
            res.unshift('All');
            return res;
        } else {
            return []
        }
    }
    let getLabel = (key, json, edit) => {
        if (edit === 'clmnMeta') {
            let res = json?.columns?.filter((cl) => cl.key === key);
            if (res && res.length) {
                let cl = res[0];
                return cl.name;
            }
        }
    }
    return { getStyle, getValue, getDataByClmn, getLabel, getImage }
}

//This function is to compare the objects by its values.
const checkEquality = (state, stateVariable, actPayload) => {
    let newState;
    if(!_.isEqual(state?.[stateVariable], actPayload)) {
        newState = { ...state, ...{ [stateVariable]: actPayload } };
    } else {
        newState = { ...state };
    }
    return newState;
}

const checkEqualityByKey = (state, stateVariable, actPayload) => {
    let newState;
    const stateKey = stateVariable + actPayload.id;
    if(!_.isEqual(state?.[stateKey], actPayload)) {
        newState = { ...state, ...{ [stateKey]: actPayload } };
        // newState = { ...state };
        // if(state[stateKey])
        //     newState[stateKey] = [...state[stateKey], actPayload];
        // else 
        //     newState[stateKey] = actPayload;
    } else {
        newState = { ...state };
    }
    return newState;
}

const initialState = {
    "homeLayUtils": getProtoFunctions(),
    "appBar": "visible",
    "loadingConsumer": false,
}

const prepareColDefs = (columns, isGovt) => {
    let columnDefs = [];
    const colNames = ['firstname', 'lastname', 'country', 'dataAppointed']
    columns.forEach((cl) => {
        if (cl.showInTable === 'true') {
            let obj = { headerName: cl.name, field: cl.key };
            
            // not adding lastname as pinned property for lastname on mobile
            if(!isGovt || !isMobileOnly || (isGovt && cl.key !== "lastname")){
                if (cl.pinned) { obj.pinned = cl.pinned }
            }

            if (cl.width) { obj.width = parseInt(cl.width) }
            if(isGovt && colNames.includes(cl.key)){
                obj.maxWidth = obj.width;
                obj.minWidth = obj.width;
            }
            if (cl.sortable) { obj.sortable = cl.sortable }
            if (cl.sort) { obj.sort = cl.sort }
            if(cl.datatype && cl.datatype == "number"){
                obj.comparator = function(valueA, valueB, nodeA, nodeB, isInverted) {
                    let val1 = valueA ? valueA : 0;
                    let val2 = valueB ? valueB : 0;
                    if(isString(val1))
                        val1 = Number(valueA.split(" ")[0].replace(/[^0-9.-]+/g,""));
                    if(isString(val2))
                        val2 = Number(valueB.split(" ")[0].replace(/[^0-9.-]+/g,""));
                    return val1 - val2;
                }
            }
            columnDefs.push(obj);
        }
    })
    return columnDefs;
}

export const applicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBDOMAINS: return sdbRFHelper(state, action)
        case SET_LISTING_TYPES_BY_DOMAIN: return (() => {
            return { ...state, ...{ listingTypesByDomain: action.payload } }
        })()
        case RESET_APP: return (() => {
            return { ...initialState, ...action.payload }
        })()
        case SET_PROFILE: return (() => {
            return { ...state, ...{ profile: action.payload } }
        })()
        case LOADING_CONSUMER_DATA: return (() => {
            return { ...state, ...{ loadingConsumer: action.payload } }
        })()
        case LOAD_LIST: return (() => {
            return checkEquality(state, 'list', action.payload);
        })()
        case LOAD_CHILD_LIST: return (() => {
            return checkEqualityByKey(state, 'childList', action.payload);
        })()
        case LOAD_LIST_DATA: return (() => {
            return checkEquality(state, 'listData', action.payload);
        })()
        case LOAD_CHILD_LIST_DATA: return (() => {
            return checkEqualityByKey(state, 'childListData', action.payload);
        })()
        case LOAD_CATEGORY_DROPDOWN: return (() => {
            return checkEquality(state, 'categoryDropdown', action.payload);
        })()
        case LOAD_CATEGORY_GRID_DATA: return (() => {
            return checkEquality(state, 'categoryGridData', action.payload);
        })()
        case REMOVE_CATEGORY_GRID_DATA: return (() => {
            return {...state, categoryGridData: null};
        })()
        case LOAD_LIST_META_DATA: return (() => {
            return checkEquality(state, 'listMetaData', action.payload);
        })()
        case LOAD_LISTING_TYPES: return (() => {
            return checkEquality(state, 'listingTypes', action.payload);
        })()
        case LOAD_LISTING_TYPE: return (() => {
            let newState;
            if (action.payload.columns && action.payload.columns.length) {
                action.payload.columns = sortBy(action.payload.columns, function (o) { return parseInt(o.order); })
                let columnDefs = prepareColDefs(action.payload.columns, action.payload.uuid == "governmentLeaders");
                action.payload.agTableData = { columnDefs: columnDefs }
                if(state?.listingType){
                    const payload = JSON.parse(JSON.stringify(action.payload));
                    const listingTyp = JSON.parse(JSON.stringify(state.listingType));
                    if(!_.isEqual(payload, listingTyp)) {
                        newState = { ...state, ...{ listingType: action.payload } };
                    }
                } else {
                    newState = { ...state, ...{ listingType: action.payload } };
                }
            }
            if(!newState) {
                newState = { ...state };
            }
            return newState;
        })()
        case LOAD_CHILD_LISTING_TYPE: return (() => {
            let newState;
            if (action.payload.columns && action.payload.columns.length) {
                action.payload.columns = sortBy(action.payload.columns, function (o) { return parseInt(o.order); })
                let columnDefs = prepareColDefs(action.payload.columns);
                action.payload.agTableData = { columnDefs: columnDefs }
                if(state?.listingType){
                    const payload = JSON.parse(JSON.stringify(action.payload));
                    const listingTyp = JSON.parse(JSON.stringify(state.listingType));
                    if(!_.isEqual(payload, listingTyp)) {
                        newState = { ...state, ...{ [action.payload.id]: action.payload } };
                    }
                } else {
                    newState = { ...state, ...{ [action.payload.id]: action.payload } };
                }
            }
            if(!newState) {
                newState = { ...state };
            }
            return newState;
        })()

        case 'SORTED_AG_TABLE_DATA': return (() => {
            let columnDefs = state.listingType?.agTableData?.columnDefs
            if(columnDefs) {
                columnDefs.forEach(column => {
                    if(column.field === action.payload?.colId){
                        column.sort = action.payload?.sort
                    } else if(column.sort) {
                        column.sort = null
                    }
                });
            }
            let agTableData = {columnDefs: columnDefs};
            return { ...state, ...{ listingType: {...state.listingType, agTableData: agTableData}}}
        })()

        case UPDATE_LIST: return (() => {
            let newList = { ...state.list, ...action.payload }
            let newState = { ...state, ...{ list: newList } }
            return newState
        })()
        case 'LS_TO_RX': return (() => {
            let lsSTr = localStorage.getItem('app-state');
            if (lsSTr && lsSTr.length > 5) {
                let prsLst = JSON.parse(lsSTr)
                let newState = prsLst.application ? prsLst.application : {};
                newState.homeLayUtils = getProtoFunctions();
                if(newState?.listingType && newState?.listingType?.columns) {
                    let columnDefs = prepareColDefs(newState.listingType.columns);
                    newState.listingType.agTableData = { columnDefs: columnDefs }    
                }
                return { ...state, ...newState };
            }
            return state;
        })()
        case LOGOUT: return {}
        case LOAD_ACTIVE_BIO: return (() => {
            return checkEquality(state, 'activeBio', action.payload);
        })()
        case LOAD_ACTIVE_BIO_QUOTE: return (() => {
            return checkEquality(state, 'activeBioQuote', action.payload);
        })()
        case REMOVE_ACTIVE_BIO_QUOTE: return (() => {
            return {...state, activeBioQuote: null};
        })()
        case LOAD_ACTIVE_COUNTRY: return (() => {
            return { ...state, ...{ activeCountry: action.payload } }
        })()
        case 'LOAD_MODE': return (() => {
            return { ...state, ...{ mode: action.payload } }
        })()
        case 'SET_APP_CACHE': return (() => {
            return { ...state, ...{ cache: action.payload } }
        })()
        case 'LOAD_APP_NAV_BAR_VISIBILITY': return (() => {
            return { ...state, ...{ appBar: action.payload } }
        })()
        case 'UPDATE_EDITED_STYLES': return (() => {
            return { ...state, ...{ editedStyle: action.payload } }
        })()
        case 'EXPORT_EXCEL_COUNT': return (() => {
            return { ...state, ...{ exportExcelCount: action.payload } }
        })()
        case 'OG_META': return (() => {
            return { ...state, ...{ ogMeta: action.payload } }
        })()
        
        default: return (() => {
            return state
        })()
    }
}