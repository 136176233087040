import React from 'react';
import { Image } from 'react-bootstrap';

class Juror extends React.Component {

    render() {
        return (
            <div className="row mt-5 mb-5">
                <div className="col">
                    <div className="row">
                        <div className="col mb-3 d-block">
                            <div className="d-block">
                                <span className="juror-name pb-1">
                                    <span >{this.props.juror.firstName} {this.props.juror.lastName}</span>
                                </span>
                            </div>
                            {/* <div className="d-block pt-1">
                                <span className="juror-country">{this.props.juror.country}</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-8 col-sm-3 juror mb-2 mb-sm-auto">
                            <Image
                                src={require(`../../../../assets/img/jurors/${this.props.folder}/${this.props.juror.imageName}`)}
                                class="d-block pb-3"
                                fluid />
                        </div>
                        <div className="col-12 col-sm-9">
                            <p>
                            <span dangerouslySetInnerHTML={{ __html: this.props.juror.description }}></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Juror;