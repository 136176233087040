import React from 'react';
import { Form, Alert, Spinner } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from './login-fs'


const mapStateToProps = (state) => {
    return {
        profile: state.application.profile,
    }
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toSubDomain: false,
        }
    }

    login = async (event) => {
        event.preventDefault();
        let { email, password } = event.target.elements;
        if (!email.value) {
            this.setState({ ...this.state, ...{ validated: true } })
            return;
        }
        if (!password.value) {
            this.setState({ ...this.state, ...{ validated: true } })
            return;
        }
        this.setState({ ...this.state, ...{ disableSubmit: true, msgtype: '', msgbody: '' } })
        try {
            await login(email.value, password.value);
            this.setState({ ...this.state, ...{ toSubDomain: true, disableSubmit: false } })
        } catch (error) {
            this.setState({ ...this.state, ...{ disableSubmit: false, msgtype: 'danger', msgbody: error.info.message } })
        }
    }

    render() {
        if (this.state.toSubDomain === true || (this.props.profile && this.props.profile.id)) {
            return <Redirect to='/subdomains' />
        }
        return (
            <div className="mainContainer container-fluid mt-3">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-4 col-md-4 col-lg-4">
                        <div className="card  rounded-lg shadow-lg p-3 pt-5 pb-5  bg-white ">
                            <img src={require('../../assets/img/login-icon.png')} className="avatar-medium" alt="..." />
                            <div className="card-body">
                                <h5 className="card-title font-weight-bold text-center text-dark mb-5"><strong>User Authentication</strong></h5>
                                <Form onSubmit={this.login} noValidate validated={this.state.validated}>
                                    <Form.Group controlId="email">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control type="email" className="form-control custome-form-control rounded-0 border-top-0 border-left-0 border-right-0 font-weight-bold text-center text-primary" placeholder="Enter email" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter email.
                                    </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" className="form-control custome-form-control rounded-0 border-top-0 border-left-0 border-right-0 font-weight-bold text-center text-primary" placeholder="Password" required />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter password.
                                    </Form.Control.Feedback>
                                    </Form.Group>
                                    {/* {!this.state.disableSubmit ? <input type="submit" value="Submit" /> : <Spinner animation="border" />} */}
                                    <div className="card-footer bg-white border-0 p-0 mt-5">
                                        {!this.state.disableSubmit ? <button type="submit" value="Submit" className="btn btn-primary btn-block rounded text-white">Login</button>
                                            : <button className="btn btn-primary btn-block rounded text-white" >
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                Loading...
                                            </button>}
                                    </div>
                                </Form>
                                <Alert variant={this.state.msgtype}>
                                    {this.state.msgbody}
                                </Alert>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps)(Login);