import React from 'react';
import { getQuotes, deleteQuote } from './quotes-fs';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap'
import firebase from '../firebase';
import Config from '../../firebase-configs.json';
import { FiShare2 } from 'react-icons/fi'
import { GrCircleInformation } from 'react-icons/gr'
import { quotesAddEditModal } from "../redux/templ";
import InfiniteScroll from 'react-infinite-scroller';
import {
    FacebookShareButton, TwitterShareButton, LinkedinShareButton,
    FacebookIcon, TwitterIcon, LinkedinIcon
} from "react-share";
import ModalImage from "react-modal-image";

const mapStateToProps = (state) => {
    return {
        quotes: state.quotes?.quotesData,
        loadMoreQuotes: state.quotes?.loadMoreQuotes,
        application: state?.application?.mode,
        listData: state.application?.listData,
        list: state.application?.list
    }
}

class Quotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            biosNote: false
        }
    }
    componentDidMount = () => {
        if (!this.props?.quotes?.length) {
            if (this.props.application === 'consumer') {
                getQuotes(this.props.list?.id, false, this.props.application);
            } else {
                this.listId = this.props?.match?.params?.listId;
                getQuotes(this.listId, false, this.props.application);
            }
        }
    }
    editQuote = (quote) => {
        quotesAddEditModal({ show: true, type: 'edit', data: quote })

    }
    deleteImage = async (imageName) => {
        var storage = firebase.app().storage(Config.quotesBucket);
        var removeRef = storage.ref('/' + 'images' + '/').child(imageName);
        return removeRef.delete().then(() => {
            return true;
        }).catch((error) => {
            console.log('// File Not deleted. Any Problem.', error);
        });
    }
    deleteQuotes = async (quote) => {
        this.setState({ ...this.state, ...{ deleting: true } })
        await this.deleteImage(quote.name);
        await deleteQuote(this.props?.list?.id, quote.id);
        this.handleClose();
        this.setState({ ...this.state, ...{ deleting: false } })
    }
    handleClose = () => {
        this.setState({ ...this.state, ...{ show: false } })
    }
    geDeleteConfirmModal = () => {
        return <Modal show={this.state?.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete quote ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    No
                </Button>
                {this.state?.deleting ? <Button>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            deleting...
                        </Button> :
                    <Button variant="primary" onClick={() => this.deleteQuotes(this.state.quotesToDelete)}>
                        Yes
                </Button>
                }
            </Modal.Footer>
        </Modal>
    }

    BiosNote = (props) => {
        let selectedInfo = this.props?.listData?.filter(item => item.bioLink === this.state.bioLink);
        if (!selectedInfo) selectedInfo = [];
        return (
            <Modal
                {...props}
                dialogClassName="modal-70w"
                aria-labelledby="description"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="bionote" className="text-primary ml-3">
                        {selectedInfo[0]?.firstname}&nbsp;{selectedInfo[0]?.lastname}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="description" className="modal-body ml-3 mr-3">
                    <p>{selectedInfo[0]?.description}</p>
                    <p>{selectedInfo[0]?.description_2}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} block>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    ShareQuote = (props) => {
        let path = '';
        let hashChar = window.location.href?.slice(-1);
        if (hashChar === '#') {
            path = window.location.href?.slice(0, -1);
        } else {
            path = window.location.href;
        }
        let url = `${path}/${this.state?.selectedQuote?.id}`;

        return (
            <Modal
                {...props}
                dialogClassName="modal-40w"
                size="sm"
                aria-labelledby="description"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="bionote">
                        Share the quote
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body id="description">
                    <div className="card-columns">
                        <div className="row justify-content-center">
                            <FacebookShareButton url={url} className="card m-3 p-3 border">
                                <FacebookIcon size={32} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton url={url} className="card m-3 p-3 border">
                                <TwitterIcon size={32} round={true} />
                            </TwitterShareButton>
                            <LinkedinShareButton url={url} className="card m-3 p-3 border">
                                <LinkedinIcon size={32} round={true} />
                            </LinkedinShareButton>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide} block>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    showDeleteConfirmModal = (row) => {
        this.setState({ ...this.state, ...{ show: true, quotesToDelete: row } })
    }

    quoteCards = () => {
        let cards = this.props.quotes?.map((row) => {
            return <div className="card" key={row.id}>
                <ModalImage
                    small={row.url}
                    large={row.url}
                    alt=""
                    showRotate={false}
                    hideDownload={true}
                    hideZoom={true}
                />;
                {/* <img src={row.url} className="card-img-top" alt={row.name} /> */}
                <div className="card-footer">
                    <div className="row">
                        <div className="col-6  text-left">
                            <a href="#" className="card-link  text-muted" onClick={() => this.setState({ biosNote: true, bioLink: row.bioLink })}><GrCircleInformation /></a>
                        </div>
                        <div className="col-6  text-right">
                            <a href="#" className="card-link" onClick={() => this.setState({ shareQuote: true, selectedQuote: row })}><FiShare2 /></a>
                        </div>
                    </div>
                </div>
                <div className="card-footer border-0 text-center bg-transparent p-0" hidden={this.props.application === "consumer"}>
                    <button className="btn btn-primary m-2" onClick={() => this.editQuote(row)}>Edit</button>
                    <button className="btn btn-secondary" onClick={() => { this.showDeleteConfirmModal(row) }}>Delete</button>
                </div>
            </div>
        })
        return cards;
    }

    loadMore = () => {
        console.log('inside load more');
        getQuotes(this.props.list?.id, true, this.props?.application, this.props.quotes?.length);
    }
    // lastScrollTop = 0;
    isBottom(el) {
        // let len = el.getBoundingClientRect().bottom;
        // if (!len) return;
        // console.log(len);
        // console.log(window.innerHeight);
        // return len <= window.innerHeight;
    }
    scrollParent = (e) => {

        // uncomment this
        // const wrappedElement = document.getElementById('quotesScroll');
        // if (this.isBottom(wrappedElement)) {
        //     this.loadMore();
        // }



        // this is for your reference only dont uncomment it
        // var st = window.pageYOffset || document.documentElement.scrollTop;
        // if (st > this.lastScrollTop) {
        //     console.log(window.height, window);
        //     console.log(document.height, document);
        //     if (window.height > document.height - 100) {
        //         alert("near bottom!");
        //     }
        // } else {
        //     // upscroll code
        // }
        // this.lastScrollTop = st <= 0 ? 0 : st;
    }

    render() {
        return (
            <div className="container-fluid">
                {this.geDeleteConfirmModal()}
                <this.BiosNote
                    show={this.state.biosNote}
                    onHide={() => this.setState({ biosNote: false })}
                />
                <this.ShareQuote
                    show={this.state.shareQuote}
                    onHide={() => this.setState({ shareQuote: false })}
                />
                {this.props.urls?.quotesText && <div className="row align-content-center justify-content-center">
                    <a href={this.props.urls?.quotesText} target="_blank" className="text-secondary">
                        <h6 className="text-center under-line mb-4 pl-3 pr-3">QUOTES - TEXT VERSION</h6>
                    </a>
                </div>}
                <div className="row align-content-center justify-content-center">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                        <div className="card-columns">
                            <InfiniteScroll
                                id="quotesScroll"
                                loadMore={this.loadMore}
                                hasMore={this.props?.loadMoreQuotes}
                            >
                                {this.quoteCards()}
                            </InfiniteScroll>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Quotes);