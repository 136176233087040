import React from 'react';
import { connect } from 'react-redux';
import { Button, Spinner, Form } from 'react-bootstrap';
import { updateBasicDetails } from '../listdetails-fs'


const mapStateToProps = (state) => {
    return {
        list: state.application.list
    }
}

class BasicDetails extends React.Component {
    toggleBasicEditViews = (visiblity) => {
        this.setState({ ...this.state, ...{ isEdit: visiblity, form: this.props.list } })
    }
    onInputChange = (e) => {
        let obj = {}
        if (e.target.id === 'year') {
            obj[e.target.id] = parseInt(e.target.value);
        } else {
            obj[e.target.id] = e.target.value;
        }
        if (e.target.id === 'enableDescription') {
            if (e.target.value === 'true') {
                obj[e.target.id] = false;
            } else if (e.target.value === 'false') {
                obj[e.target.id] = true;
            }
        }
        let newForm = { ...this.state.form, ...obj }
        this.setState({ ...this.state, ...{ form: newForm } })
    }
    saveBasicDetails = (event) => {
        this.setState({ ...this.state, ...{ disableSubmit: true } })
        event.preventDefault();
        // console.log(event.target.elements);
        let form = { ...this.state.form }
        delete form.id;
        console.log(this.state.form);
        updateBasicDetails(this.props.list.id, this.state.form);
        this.setState({ ...this.state, ...{ disableSubmit: false, isEdit: false } })
    }
    getEditBasicDetailsForm = () => {
        return (
            <Form onSubmit={this.saveBasicDetails} noValidate validated={this.state?.validated}>
                <Form.Group controlId="name">
                    <Form.Label>List Name</Form.Label>
                    <Form.Control type="text" className="form-control custome-form-control rounded-0 border-top-0 border-left-0 border-right-0 font-weight-bold text-center text-primary" placeholder="Enter list name" required value={this.state?.form?.name} onChange={this.onInputChange} />
                    <Form.Control.Feedback type="invalid">
                        Please enter list name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="year">
                    <Form.Label>Year</Form.Label>
                    <Form.Control type="number" className="form-control custome-form-control rounded-0 border-top-0 border-left-0 border-right-0 font-weight-bold text-center text-primary" placeholder="Enter year" value={this.state?.form?.year} onChange={this.onInputChange} />
                </Form.Group>
                <Form.Group controlId="description">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows="8" placeholder="Enter Description" value={this.state?.form?.description} onChange={this.onInputChange} />
                </Form.Group>
                <Form.Group controlId="enableDescription">
                    <Form.Check label="Enable Description" type="checkbox" checked={this.state?.form?.enableDescription} value={this.state?.form?.enableDescription} onChange={this.onInputChange} />
                </Form.Group>
            </Form>
        );
    }

    render() {
        return (
            <div>
                <div className="card mb-5">
                    <div className="card-body">
                        <div className="row justify-content-around align-items-center  bg-light p-2">
                            <div className="col">
                                <h5 className="mb-0 font-weight-bolder">Basic Details - ({this.props?.list?.enableDescription ? 'Enabled' : 'Disabled'})</h5>
                            </div>
                            <div className="col text-right">
                                {
                                    this.state?.isEdit ?
                                        <div className="row justify-content-end">{!this.state?.disableSubmit ? <button onClick={this.saveBasicDetails} class="btn btn-primary text-white mr-2">Save</button>
                                            : <button class="btn btn-primary btn-block rounded text-white" >
                                                <Spinner
                                                    as="span"
                                                    animation="grow"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                        Loading...
                                                    </button>
                                        }

                                            <Button className="btn btn-secondary" onClick={() => this.toggleBasicEditViews(false)}>Cancel</Button>
                                        </div>
                                        : <Button className="btn btn-primary" onClick={() => this.toggleBasicEditViews(true)}>Edit</Button>
                                }
                            </div>
                        </div>
                        {/* <nav className="navbar navbar-light bg-light">
                            <ul class="nav navbar-nav navbar-right">
                                <li>
                                    {
                                        this.state?.isEdit ?
                                            <>{!this.state?.disableSubmit ?
                                                <button onClick={this.saveBasicDetails} class="btn btn-primary btn-block rounded text-white">Save</button>
                                                : <button class="btn btn-primary btn-block rounded text-white" >
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                                    Loading...
                                                    </button>
                                            }

                                                <Button onClick={() => this.toggleBasicEditViews(false)}>Cancel</Button></> :
                                            <Button onClick={() => this.toggleBasicEditViews(true)}>Edit</Button>
                                    }
                                </li>
                            </ul>
                        </nav> */}


                        {this.state?.isEdit ? this.getEditBasicDetailsForm() :
                            <>
                                <span className="navbar-brand">
                                    {this.props?.list?.year} {this.props?.list?.name}
                                </span>
                                <p dangerouslySetInnerHTML={{ __html: this.props?.list?.description }}></p>
                                {/* <span>
                                    {this.props?.list?.enableDescription ? 'Enabled' : 'Disabled'}
                                </span> */}
                            </>}
                    </div>
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps)(BasicDetails);