
const phil2021Jurors = [
    {
        country: "Australia",
        jurors: [
            {
                "firstName": "Peter",
                "lastName": "Varghese",
                "imageName":"Peter-Varghese.jpg",
                "country": "Australia",
                "description": "Peter Varghese began as Chancellor of The University of Queensland on 11 July 2016. Prior to this appointment, Mr Varghese’s extensive career in public service and diplomacy spanned 38 years and included senior positions in foreign affairs, trade policy and intelligence. Most recently, he served as Secretary of the Department of Foreign Affairs and Trade (2012-2016). Previous senior appointments included High Commissioner to India (2009-2012), High Commissioner to Malaysia (2000-2002), Director-General of the Office of National Assessments (2004-2009), and Senior Advisor (International) to the Prime Minister of Australia (2003-2004). Mr Varghese was the author of a comprehensive India Economic Strategy to 2035 commissioned by the Australian Prime Minister and submitted in July 2018."
            }
        ]
    },
    {
        country: "Canada",
        jurors: [
            {
                "firstName": "Krishan",
                "lastName": "Mehta",
                "imageName":"Krishnan-Mehta.jpg",
                "country": "Canada",
                "description": "Dr. Krishan Mehta is an advancement leader with extensive experience in transformational philanthropy and complex public-private partnerships, alumni and stakeholder engagement, senior volunteer relations, large capital campaigns, strategic planning, marketing and communications, stewardship, and building high-performing teams. Dr. Mehta is also a researcher who studies the charitable activities of immigrants and diaspora communities in Canada. He is a frequent speaker, educator and writer on a variety of topics across the charitable giving landscape. In 2020, Dr. Mehta was named Outstanding Fundraiser of the Year by the Association of Fundraising Professionals Toronto."
            }
        ]
    },
    {
        country: "India",
        jurors: [
            {
                "firstName": "Naghma",
                "lastName": "Mulla",
                "imageName":"Naghma-Mulla.jpg",
                "country": "India",
                "description": "Naghma Mulla is the CEO of EdelGive Foundation, the philanthropic initiative of Edelweiss Group – one of India’s leading diversified financial services group. As COO, Naghma has been instrumental in driving the vision of EdelGive Foundation towards the growth and scale of the organisations the foundation supports. Naghma plays a large role in enabling commitments to our diverse portfolio of NGOs, by creating valuable partnerships with reputed international and national stakeholders within the sector. Today, EdelGive has also built two successful collaboratives - The Collaborators for Transforming Education (in partnership with the Government of Maharashtra) and the Coalition for Women’s Empowerment – both developed and nurtured by her. Naghma is also a member of the Core Committee of the India Climate Collaborative – a collaboration of India’s leading philanthropies committed to addressing the climate crisis in India."
            },
            {
                "firstName": "Atul",
                "lastName": "Satija",
                "imageName":"Atul-Satija.jpg",
                "country": "India",
                "description": "Atul Satija is the Founder & CEO of The/Nudge Foundation. After 17 years of starting, scaling and turning around various businesses in some of the largest and most respected organisations globally, he decided to start The/Nudge Foundation to fulfill his passion of working on inequality and social issues facing India. Previously at his 5-year stint at InMobi, Atul helped scale the organisation from a small start-up to a global leader in mobile advertising. Prior to InMobi, Atul was the Head of Mobile Business for Japan & Asia-Pacific, and Head of Business Development for India at Google. Atul has also done various sales, consulting and business development assignments across telecom and technology companies including Adobe, Samsung and Infosys."
            },
        ]
    },
    {
        country: "Singapore",
        jurors: [
            {
                "firstName": "Vikram",
                "lastName": "Khanna",
                "imageName":"Vikram-Khanna.jpg",
                "country": "Singapore and Southeast Asia",
                "description": "Vikram Khanna is Associate Editor and economic affairs commentator at The Straits Times, Singapore's leading daily. Prior to joining the media industry in 1993, he served at the International Monetary Fund in Washington DC for seven years.He studied economics at Cambridge University UK. He is the author of the book: \"Headwinds and Hazards: Economic Snapshots in an age of populism\" and is Vice-President of the Economic Society of Singapore."
            }
        ]
    },
    {
        country: "United Arab Emirates",
        jurors: [
            {
                "firstName": "Abhishek",
                "lastName": "Sharma",
                "imageName":"Abishek-Sharma.jpg",
                "country": "U.A.E. and Middle East",
                "description": "Abhishek Sharma, Chief Executive Officer (CEO) founded Foundation Holdings, a strategic, global investment firm focused on building sustainable, industry defining companies in the healthcare, education and consumer sectors across India and the GCC in November 2016. Under his leadership, Foundation Holdings has won the Global Business Outlook Award 2018 for the <strong>Best Health Care Investment Management Firm.</strong>"
            }
        ]
    },
    {
        country: "United Kingdom",
        jurors: [
            {
                "firstName": "Sara",
                "lastName": "Kalim",
                "imageName":"Sara-Kalim.jpg",
                "country": "United Kingdom",
                "description": "As Director of Development at Somerville College, Sara oversees Somerville’s fundraising strategy and development, is responsible for fundraising campaigns (including for the Margaret Thatcher Scholarship Trust and the Oxford India Centre), and heads up the Development Team. She is also a member of the College’s Management Team."
            }
        ]
    },
    {
        country: "United States of America",
        jurors: [
            {
                "firstName": "Raj",
                "lastName": "Kumar",
                "imageName":"Raj-Kumar.jpg",
                "country": "United States of America",
                "description": "Raj Kumar is the founding President & Editor-in-Chief of Devex, the media platform for the global development community. A social entrepreneur and digital media executive, he's been a part of nine presidential campaigns and was a co-founder of a successful financial information company. Raj saw the world-shaping potential of Devex while a graduate student at the Harvard Kennedy School and promptly dropped out to build it. He has been profiled in Forbes, the Financial Times, Foreign Policy, and the Washington Post, where Devex was called the Bloomberg of foreign aid. Raj is an active investor in ground-breaking startups, a member of the Council on Foreign Relations and the Clinton Global Initiative, and is the former chair of the World Economic Forum's humanitarian council. He is the author of the book <strong>The Business of Changing the World</strong>, a go-to primer on the ideas, people, and technology disrupting the aid industry."
            },
            {
                "firstName": "Ankur",
                "lastName": "Vora",
                "country": "United States of America",
                "imageName":"Ankur-Vora.jpg",
                "description": "As the Chief Strategy Officer of the Bill and Melinda Gates Foundation, Ankur Vora serves as a principal advisor to the co-chairs, chief executive officer and Executive Leadership Team on the foundation’s overall strategic opportunities, risks, and tradeoffs. He leads the Strategy Office, which is responsible for enabling the highest and best use of the our resources - co-chair time, reputation, people, and dollars - to accelerate the foundation’s impact."
            },
        ]
    },
]

export default phil2021Jurors;