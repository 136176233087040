import { store } from '../store';
//enums
export const SET_LISTING_TYPES_BY_DOMAIN = 'SET_LISTING_TYPES_BY_DOMAIN'

//action creators
const setListingTypesByDomainAc = (payload) => {
    return {
        type: SET_LISTING_TYPES_BY_DOMAIN,
        payload
    }
}

export const setListingTypesByDomain = (data) => {
    store.dispatch(setListingTypesByDomainAc(data))
}
