import React from 'react';
import { Col, Row, Button, Tabs, Tab, Form, Toast } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { store } from './store';
import * as Layouts from './layouts';
import { getRequiredData } from './list-details-page-op'
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import { sortBy } from 'underscore';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getData, updateListData, updateListingTypesClmns } from './listdetails/listdetails-fs';
import BasicDetails from './listdetails/components/basic-details';
import ReactDataGrid from "react-data-grid";
import 'react-data-grid/dist/react-data-grid.css';
import VerticalModal from "./common/modal";
import News from "./news/news";
import Media from "./media/media";
import GridData from "./components/grid/grid-data";
import { newsAddEditModal } from './redux/templ'
import { mediaAddEditModal } from './redux/templ'
import AddEditNews from "./news/add-edit-news";
import AddEditMedia from "./media/add-edit-media";
import AddEditQuotes from "./quotes/add-edit-quotes";
import Quotes from "./quotes/quotes";
import { quotesAddEditModal } from './redux/templ'
import { DeleteFormatter } from './components/grid/delete-formatter';

const mapStateToProps = (state) => {
    const props = {
        listDetails: state.listDetails,
        list: state.application.list,
        listingType: state.application.listingType,
        listData: state.application.listData,
        exportExcelCount: state.application.exportExcelCount,
        newsModal: state.tempData.newsModal,
        mediaModal: state.tempData.mediaModal,
        quotesModal: state.tempData.quotesModal,
        profileModal: state.tempData.profileModal
    };
    const list = state.application.list;
    if(list && list.childLists){
        list.childLists.forEach(child => {
            let key = 'childList' + child.listId;
            let dataKey = 'childListData' + child.listId;
            const childListMetaData = state.application[key];
            const childListData = state.application[dataKey];
            if(childListMetaData && childListData){
                props[child.listId] = {};
                props[child.listId].meta = childListMetaData;
                props[child.listId].data = childListData;
                key = childListMetaData.listingTypeId;
                const childListTypeData = state.application[key];
                if(childListTypeData){
                    props[key] = {}
                    props[key].raw = childListTypeData;
                    const dataClmnsWithWidth = [];
                    processColumnsForDataGrid(childListTypeData.columns, dataClmnsWithWidth, child.listId, true);
                    props[key].columnsWithWidth = dataClmnsWithWidth;
                }
            }
        })
    }
    return props;
}


const booleanFormatter = (props) => {
    if (props.row[props.column.key]) {
        return props.row[props.column.key].toString();
    }
    return '';
}

const processColumnsForDataGrid = (columns, columnsWithWidth, listId, isChild) => {
    columnsWithWidth.push({
        key: "deleteCol",
        name: "Delete",
        width: 50,
        pinned: "left",
        frozen: true,
        hide: true,
        formatter: ({ row }) => <DeleteFormatter value={{data: row, listId, isChild}} />
    })
    columns.forEach((ele) => {
        let newEle = { ...ele };
        newEle.width = 150;
        newEle.resizable = true;
        newEle.sortable = true;
        if(newEle.key === "firstname" || newEle.key === "lastname")
            newEle.frozen = true;
        columnsWithWidth.push(newEle);
    })
}
class DropdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            row: props.row, 
            key: props.column.key,
            ddVal: props.row[props.column.key], 
            exportExcelModal: false
        };
    }

    getValue() {
        return this.state.row;
    }

    getInputNode() {
        return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
    }

    handleChangeComplete = e => {
        let newRow = { ...this.state.row, ...{ [this.state.key]: e.target.value } }
        this.setState({ ...this.state, ...{ ddVal: e.target.value, row: newRow } }, () => this.props.onCommit())
    };
    render() {
        let sort = this.state?.key === 'sort';
        return (
            <Form>
                <Form.Group controlId="dropdown" onChange={this.handleChangeComplete}>
                    <Form.Control as="select" custom value={this.state?.ddVal}>
                        <option hidden={!sort} value="asc">asc</option>
                        <option hidden={!sort} value="desc">desc</option>
                        <option hidden={!sort} value="">select</option>
                        <option hidden={sort} value="true">true</option>
                        <option hidden={sort} value="false">false</option>
                    </Form.Control>
                </Form.Group>
            </Form >
        );
    }
}

class ListDetailsPage extends React.Component {
    renderDynamicComponent = (layout) => {
        console.log(layout.name);
        let Layout = Layouts['NoLayoutFound']
        if (layout) {
            Layout = Layouts[layout.name];
        }
        return <Layout></Layout>
    }

    getLayStyleEditorUrl = (layout) => {
        let url = '/subdomains/' + this.subdomain + '/listingTypes/' + this.listingTypeId + '/list/' + this.listId + '/layout/' + layout.name;
        return url;
    }
    componentDidMount = async() => {
        this.listId = this.props.match.params.listId;
        this.listingTypeId = this.props.match.params.listingTypeId;
        this.subdomain = this.props.match.params.subdomain;
        await getData(this.listId, this.listingTypeId);
        store.dispatch(async () => {
            let res = await getRequiredData(this.listId)
            await res(store.dispatch);
        });
        store.subscribe(() => {
            let state = store.getState();
            if (state?.application?.listingType?.columns?.length) {
                //this code will set the width for admin side table
                let dataClmnsWithWidth = [];
                processColumnsForDataGrid(state.application.listingType.columns, dataClmnsWithWidth, this.listId, false);
                
                let defaultClm = state.application.listingType.columns[0];
                let gridRows = []
                state.application.listingType.columns.forEach((ele) => {
                    let newEle = { ...ele };
                    delete newEle.tableConfig
                    gridRows.push(newEle);
                })
                let gridColumns = []

                for (const property in defaultClm) {
                    if (property === 'editable' || property === 'tableConfig' || property === 'draft' || property === 'labelStyle' || property === 'valueStyle') { } else {
                        let obj = { 
                            key: property, 
                            name: property.charAt(0).toUpperCase() + property.slice(1), 
                            editable: property === "key" ? false : true 
                        };
                        if (defaultClm[property] === "true" || 
                            defaultClm[property] === "false" || 
                            defaultClm[property] === "" || 
                            defaultClm[property] === "asc" || 
                            defaultClm[property] === "desc") {
                                obj.editor = DropdownEditor;
                                obj.formatter = booleanFormatter
                        }
                        gridColumns.push(obj);
                    }
                }
                gridColumns = sortBy(gridColumns, function (o) { return o.key; })
                this.setState({ ...this.state, ...{ clmMetaClms: gridColumns, clmMetaRows: gridRows, dataClmnsWithWidth: dataClmnsWithWidth } })
            }
        })
    }
    
    getHomeTab = () => {
        return <BasicDetails />
    }
    
    handleColmnsMetaUpdate = async ({ fromRow, toRow, updated }) => {
        console.log(fromRow, toRow, updated);
        let clms = this.state.clmMetaRows.slice();
        let rowsTobeUpdatedToFb = this.props.listingType.columns.slice();
        let updateTable;
        if(updated.hasOwnProperty('sort')){
            rowsTobeUpdatedToFb.forEach((row, index) => {
                if(index === fromRow && row?.sort !== updated?.sort) {
                    updateTable = true;
                }
                if(row.hasOwnProperty('sort')) {
                    row.sort = "";
                }
            })
        }
        if(updateTable) {
            clms = rowsTobeUpdatedToFb;
        } else {
            rowsTobeUpdatedToFb[fromRow] = { ...rowsTobeUpdatedToFb[fromRow], ...updated };
        }
        clms[fromRow] = { ...clms[fromRow], ...updated };
        this.setState({ ...this.state, ...{ rowsTobeUpdatedToFb: rowsTobeUpdatedToFb, clmMetaRows: clms } })
    }
    updateClmsMeta = async () => {
        this.setState({ ...this.state, ...{ updatingClmnsMeta: true } })
        await updateListingTypesClmns(this.listingTypeId, this.state.clmMetaRows);
        this.setState({ ...this.state, ...{ rowsTobeUpdatedToFb: [], updatingClmnsMeta: false } })
    }
    
    getDataTab = () => {
        return <GridData 
            name="Data"
            isChild={false}
            dataClmnsWithWidth={this.state?.dataClmnsWithWidth} 
            listId={this.listId}
            list={this.props.list}
            listingType={this.props.listingType}
            listData={this.props.listData}
        ></GridData>
    }
    getLayoutsTab = () => {
        return <div>
            <div className="card">
                <Container-fluid style={{ padding: '50px' }}>
                    <Tabs defaultActiveKey="homeLay" id="uncontrolled-tab-example">
                        <Tab eventKey="homeLay" title="Home Layout">
                            <Row>
                                <Col>
                                    <div style={{ padding: '20px' }}>
                                        {this.props?.listDetails?.homeLayouts?.map((hl, index) => {
                                            return <div key={index}>
                                                <nav className="navbar navbar-light bg-light">
                                                    <span className="navbar-brand">
                                                        {hl.name}
                                                    </span>
                                                    <ul className="nav navbar-nav navbar-right">
                                                        <li>
                                                            <Link to={{ 'pathname': this.getLayStyleEditorUrl(hl), state: { data: { layout: hl } } }}><Button>Edit Styles</Button></Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                {this.renderDynamicComponent(hl)}
                                            </div>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="listingLayout" title="Listing Layout">
                            <Row>
                                <Col>
                                    <div style={{ padding: '20px' }}>
                                        {this.props?.listDetails?.listingLayouts?.map((ll, index) => {
                                            return <div key={index}>
                                                <nav className="navbar navbar-light bg-light">
                                                    <span className="navbar-brand">
                                                        {ll.name}
                                                    </span>
                                                    <ul className="nav navbar-nav navbar-right">
                                                        <li>
                                                            <Link to={{ 'pathname': this.getLayStyleEditorUrl(ll), state: { data: { layout: ll } } }}><Button>Edit Styles</Button></Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                {this.renderDynamicComponent(ll)}
                                            </div>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        {/* <Tab eventKey="aboutLayout" title="About Layout">
                            <Row>
                                <Col>
                                    <div style={{ padding: '20px' }}>
                                        {this.props?.listingType?.aboutLayouts?.map((al, index) => {
                                            return <div key={index}>
                                                <nav className="navbar navbar-light bg-light">
                                                    <span className="navbar-brand">
                                                        {al.name}
                                                    </span>
                                                    <ul className="nav navbar-nav navbar-right">
                                                        <li>
                                                            <Link to={{ 'pathname': this.getLayStyleEditorUrl(al), state: { data: { layout: al } } }}><Button>Edit Styles</Button></Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                {this.renderDynamicComponent(al)}
                                            </div>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="selectionProcess" title="Selection Process Layout">
                            <Row>
                                <Col>
                                    <div style={{ padding: '20px' }}>
                                        {this.props?.listingType?.selectionProcessLayouts?.map((spl, index) => {
                                            return <div key={index}>
                                                <nav className="navbar navbar-light bg-light">
                                                    <span className="navbar-brand">
                                                        {spl.name}
                                                    </span>
                                                    <ul className="nav navbar-nav navbar-right">
                                                        <li>
                                                            <Link to={{ 'pathname': this.getLayStyleEditorUrl(spl), state: { data: { layout: spl } } }}><Button>Edit Styles</Button></Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                                {this.renderDynamicComponent(spl)}
                                            </div>
                                        })}
                                    </div>
                                </Col>
                            </Row>
                        </Tab> */}
                    </Tabs>
                </Container-fluid>
            </div>
        </div>
    }

    onTabCLick() {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 10)
        // the following code is a hack to make the sidenav
        // not stick to the top of the page.
        const node = document.getElementById('sideNav');
        if (node)
            node.style.position = "relative";
    }

    showToast = () => {
        return (
            <div
            style={{
                width: '400px',
              }}
            >
            <Toast style={{
                position: 'absolute',
                top: "0",
                right: 0
            }} show={this.state?.exportExcelModal} onClose={() => this.setState({ exportExcelModal: false })} delay={5000} autohide>
                <Toast.Header className="bg-light">
                    <strong className="mr-auto text-success">Update</strong>
                </Toast.Header>
                <Toast.Body className="text-success bg-white">Row updated</Toast.Body>
            </Toast>

            </div>
        )
    }

    hideModal = () => {
        if(!this.state?.importingExel || !this.state?.updatingMultipleImages || !this.state?.updatingMultipleBioImages || !this.state?.uploadStatus){
            return true
        }
    }
    openNewsModal = () => {
        newsAddEditModal({ show: true, type: 'add' })
    }
    openMediaModal = () => {
        mediaAddEditModal({ show: true, type: 'add' })
    }
    openQuotesModal = () => {
        quotesAddEditModal({ show: true, type: 'add' })
    }

    render() {
        return (
            <div className="container-fluid">
                {this.props?.newsModal?.show ? <AddEditNews /> : ''}
                {this.props?.mediaModal?.show ? <AddEditMedia /> : ''}
                {this.props?.quotesModal?.show ? <AddEditQuotes /> : ''}
                {/* <VerticalModal show={this.state?.importingExel || this.state?.updatingMultipleImages || this.state?.updatingMultipleBioImages || this.state?.uploadStatus}
                    onHide={this.hideModal} 
                    bodyText= {this.state?.importingExel ? 'Importing Data' : 'Uploading Image'}
                    bodyText2={!this.state?.uploadStatus ? this.props?.exportExcelCount : ''}
                /> */}
                {this.state?.exportExcelModal ?
                    this.showToast()
                    : ''}
                <br></br>
                <Tabs defaultActiveKey="data" id="uncontrolled-tab-example" onSelect={this.onTabCLick}>
                    <Tab eventKey="data" title="Data">
                        {this.getDataTab()}
                        {this.props?.listDetails?.childLists?.map((child) => {
                            const childList = this.props[child.listId];
                            const listTypeData = this.props[childList?.meta?.listingTypeId]
                            if(childList && listTypeData){
                                const childListMeta = childList.meta;
                                const childListData = childList.data;
                                console.log("GAUTAM")
                                console.log(childList, listTypeData);
                                const listData = [];
                                return <GridData
                                    key={child.listId}
                                    name={child.name}
                                    isChild={true}
                                    dataClmnsWithWidth={listTypeData.columnsWithWidth} 
                                    listId={child.listId}
                                    list={childListMeta}
                                    listingType={listTypeData.raw}
                                    listData={childListData.data}
                                ></GridData>
                            } else {
                                return '';
                            }
                        })}
                    </Tab>
                    <Tab eventKey="columns" title="Columns" style={{ padding: '50px' }}>
                        <div>
                            {this.state?.rowsTobeUpdatedToFb?.length ?
                                <Button onClick={this.updateClmsMeta}>Update</Button> : ''
                            }
                            {this.state?.clmMetaClms?.length ?
                                <ReactDataGrid
                                    columns={this.state?.clmMetaClms}
                                    rows={this.state?.clmMetaRows}
                                    onRowsUpdate={this.handleColmnsMetaUpdate}
                                /> : ''
                            }
                        </div>
                    </Tab>
                    <Tab eventKey="layouts" title="Layouts">
                        {this.getLayoutsTab()}
                    </Tab>
                    <Tab eventKey="news" title="News">
                        <nav className="navbar navbar-light bg-light">
                            <span className="navbar-brand">
                                &nbsp;
                            </span>
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <Button onClick={this.openNewsModal}>Add News</Button>
                                </li>
                            </ul>
                        </nav>
                        <News match={this.props.match}/>
                    </Tab>
                    <Tab eventKey="media" title="Media">
                        <nav className="navbar navbar-light bg-light">
                            <span className="navbar-brand">
                                &nbsp;
                            </span>
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <Button onClick={this.openMediaModal}>Add Media</Button>
                                </li>
                            </ul>
                        </nav>
                        <Media match={this.props.match}/>
                    </Tab>
                    <Tab eventKey="quotes" title="Quotes">
                        <nav className="navbar navbar-light bg-light">
                            <span className="navbar-brand">
                                &nbsp;
                            </span>
                            <ul className="nav navbar-nav navbar-right">
                                <li>
                                    <Button onClick={this.openQuotesModal}>Add Quotes</Button>
                                </li>
                            </ul>
                        </nav>
                        <Quotes match={this.props.match}/>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
export default connect(mapStateToProps)(ListDetailsPage);