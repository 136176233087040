import React from 'react';
import { Card, Tabs, Tab, Button } from 'react-bootstrap';
import { getListingTypesBySubdomain } from './linstingtypes-fs';
import { connect } from 'react-redux';
import { getListWithUUIDLstTYID } from "../listdetails/listdetails-fs";

const mapStateToProps = (state) => {
    return {
        listingTypesByDomain: state.application.listingTypesByDomain
    }
}
class ListingTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount = async () => {
        this.subdomain = this.props.match.params.subdomain;
        getListingTypesBySubdomain(this.subdomain);
    }
    toListingTypeDetails = async(listingType) => {
        let lists = await getListWithUUIDLstTYID(listingType?.id, listingType?.uuid);
        let url = '/subdomains/' + listingType.subdomain + '/listingTypes/' + listingType.id + '/list/' + lists.id;
        this.props.history.push(url);
    }
    getListsJsx = () => {
        return (
            <div className="container">
                <div className="row justify-content-center align-items-center mt-5  p-2">
                    <div className="col-6">
                        <Card className="text-center bg-aqua" >
                            <div className="card-header">
                                <h3>Choose a List to configure</h3>
                            </div>
                            <Card.Body>
                                {
                                    this.props?.listingTypesByDomain?.map((lsType, index) => {
                                        return <div key={index} >
                                            <ul className="list-group list-group-flush p-4">
                                                <li className="list-group-item">
                                                    <Button className="btn-block btn btn-primary btn-lg" 
                                                        onClick={() => this.toListingTypeDetails(lsType)}>{lsType.name}
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    })
                                }
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
        )
    }

    render() {
        return (
            <>
                
                <div style={{ padding: '10px' }}>
                    <br></br>
                    <Tabs defaultActiveKey="lists" id="uncontrolled-tab-example">
                        <Tab eventKey="lists" title="Lists" style={{ padding: '10px' }}>
                            {this.getListsJsx()}
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(ListingTypes);