
const phil2021Jurors = [
    {
        country: "Australia",
        jurors: [
            {
                "firstName": "Jai",
                "lastName": "Patel",
                "imageName":"jai-patel.jpeg",
                "country": "Australia",
                "description": "Jai Patel is head of KPMG's Indian Business Practice (IBP) Australia, which has been operating for 15 years and acts as a single national point for all business matters Indian Australian."
            }
        ]
    },
    {
        country: "Canada",
        jurors: [
            {
                "firstName": "Raj",
                "lastName": "Narula",
                "imageName":"raj-narula.jpeg",
                "country": "Canada",
                "description": "Raj Narula is Founder and CEO, InCa Synergies. Raj is a serial entrepreneur and has been an active angel investor in eLearning, Web Portals, Security and IT companies. He started his first successful business at age twenty (22). He has helped launch numerous start-ups, taking strategic management roles, and helped to secure over $75 million in early and mid-stage funding. He also co-founded and operated many businesses in Ottawa spanning 35 years. Additionally, he has transacted M&A’s within the Technology and main stream domains. Raj has been dividing his time equally between North America and South East Asia for the past 15 years. Raj grew up globally, living in Iran, Italy, South America, India and speaks multiple languages. He has also been featured in business publications both in Canada and India."
            },
            {
                "firstName": "Sean",
                "lastName": "Languedoc",
                "imageName":"sean-languedoc.jpeg",
                "country": "Canada",
                "description": "After building 4 tech companies, he found that hiring senior tech talent is hard and outsourcing “sucks”. Global Talent Accelerator helps you de-risk outsourcing with a more rigorous vetting and selection process designed to match your specific needs with the best provider (local or remote)."
            }
        ]
    },
    {
        country: "Germany",
        jurors: [
            {
                "firstName": "Sushant",
                "lastName": "Palakurthi Rao",
                "imageName":"sushant-palakurthi-rao.jpeg",
                "country": "Germany",
                "description": "Sushant is a German–American dual citizen as well as Overseas Citizen of India and resides in Hamburg, Germany. He is the Managing Director of Global External Relations at Agility, a global leader and investor in supply chain services and innovation as well as the second largest shareholder in DSV, the world’s third largest logistics company. In this capacity, Sushant oversees group public affairs and supports the Agility board and the CEOs of its subsidiary companies in their business development, strategic initiatives, and partnership development. Prior to joining Agility, Sushant was the Head of Asia Pacific and a member of the Executive Committee at the World Economic Forum from 2005 to 2015 in Geneva, Switzerland. "
            }
        ]
    },
    {
        country: "India",
        jurors: [
            {
                "firstName": "Soumya",
                "lastName": "Rajan",
                "imageName":"soumya-rajan.jpeg",
                "country": "India",
                "description": "Soumya Rajan is an experienced finance professional with over 25 years of work experience. Founded Waterfield Advisors to make a difference to clients to enable them to receive holistic advice on a number of issues that they grapple with in relation to their financial and business well-being - investment management, succession and legacy planning, philanthropy, business advisory support and risk management. Also have philanthropic interests in the areas of primary education, healthcare, music and homelessness."
            },
            {
                "firstName": "Vani",
                "lastName": "Kola",
                "imageName":"vani-kola.jpeg",
                "country": "India",
                "description": "Vani Kola is the Founder and Managing Director of Kalaari Capital, one of India’s leading early-stage venture capital firms. She is a proponent for India’s digital opportunity to create next-gen large scale companies, which will scale globally. Having been a 2x entrepreneur in Silicon Valley, Vani's investment philosophy is derived from her successful entrepreneurial experiences. This includes recognizing young, first-time entrepreneurs with passion and perseverance. Vani values integrity in interactions as the key to building deep relationships. She enjoys mentoring entrepreneurs and guiding them into becoming seasoned business leaders. Vani has successfully led more than 30 investments at Kalaari in e-commerce, gaming, digital content, healthcare and more. Some of her successes include: Dream11, Myntra, Cure.fit, and Snapdeal. Vani currently serves on the board of several companies that are redefining the landscape of Indian business."
            },
        ]
    },
    {
        country: "Singapore",
        jurors: [
            {
                "firstName": "Jayesh",
                "lastName": "Parekh",
                "imageName":"jayesh-parekh.jpeg",
                "country": "Singapore",
                "description": "Jayesh is currently serving as a managing partner at Good Startup, a Singapore-based venture capital firm focused on alternative protein. Jayesh was a cofounder of Sony Entertainment Television, a major television network of Sony Pictures Entertainment, which he successfully exited in 2013. He was a Managing Partner for Jungle Ventures Fund I and II, a Pan-Asian Venture Capital technology fund based in Singapore. Jayesh is on the Boards of Sanctum Wealth Management, One Animation, and Milaap. He is on the Advisory Board of Akhand Jyoti Eye Hospital in Bihar. He is on the Investment Committees of Aavishkaar India II Fund, Aavishkaar Africa Fund and Aavishkaar Frontier Fund, which are social impact venture funds. He spent over 12 years at IBM in Houston and Singapore and was part of the team that brought IBM back to India. Jayesh is a published author of What Shall We Do With All This Money? Inspiring Perspectives on Wealth."
            }
        ]
    },
    {
        country: "United States of America",
        jurors: [
            {
                "firstName": "Raju",
                "lastName": "Reddy",
                "imageName":"raju-reddy.jpeg",
                "country": "United States of America",
                "description": "Raju is the Founding Patron of Kakatiya Sandbox. He is also the Founder and former CEO of Sierra Atlantic, a company that grew to be a best-in-class global services company over a period of 17 years. Under his leadership, Sierra Atlantic grew from a startup to over 2,400 employees worldwide and was consistently ranked among the best managed companies including the top 75 most admired companies in Silicon Valley, Top 25 great places to work in India and Top 10 best employers in South China. Sierra Atlantic was acquired by Hitachi Consulting in December 2010 and it is the largest ever acquisition by Hitachi Consulting to date. Prior to founding Sierra Atlantic in 1993, Raju spent 10 years at Intel in software development, marketing, and general management. Raju enjoys working with young entrepreneurs and mentoring to build great companies."
            },
            {
                "firstName": "Murali",
                "lastName": "Vullaganti",
                "imageName":"murali-vullaganti.jpeg",
                "country": "United States of America",
                "description": "Murali Vullaganti is a Founder and CEO of PeopleShores, a US based Public Benefit Corporation (PBC) focusing on empowering and transforming young adults in under-served communities in the US into knowledge professionals through Impact Sourcing. Prior to PeopleShores, Murali founded RuralShores, a similar social enterprise set up in 2009 in India with a vision to bring technology-oriented jobs to the rural youth. RuralShores now has 18 centers spread across 9 states employing 4000+ rural youth managing 80+ complex processes for 30+ reputed clients across 4 continents. RuralShores success story is a case study at Oxford, Harvard, IIM Bangalore and other management schools."
            },
        ]
    },
]

export default phil2021Jurors;