import React from 'react';
import { Button } from 'react-bootstrap';
import {confirm} from 'react-bootstrap-confirmation';
import { deleteListDataRow } from '../../listdetails/listdetails-fs';

async function deleteRow (listId, isChild, data) {
    const result = await confirm('Are you really sure?');
    if(result)
        deleteListDataRow(listId, data.id, isChild)
    console.log("Deleting", listId, isChild, data)
}
export function DeleteFormatter(row) {
    return (
        <Button variant="link" onClick={() => deleteRow(row.value.listId, row.value.isChild, row.value.data)}>Delete</Button>
    );
}