import React from 'react';
import { Modal, Button, Spinner, Toast } from 'react-bootstrap';
import StyleEditor from './index';
import { store } from '../../store';
import { updateListMetaData } from '../../listdetails/listdetails-fs'
import { updatedEditedStyle } from '../../redux/application';
import { connect } from 'react-redux';
import { isEqual } from 'underscore'
import { deleteImage } from "./operations";
import { setOldImage } from "../../redux/templ";

const mapStateToProps = (state) => {
    return {
        applicationMode: state.application.mode,
        editedStyle: state.application.editedStyle,
        oldImage: state.tempData?.oldImage
    }
}
class SEButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    onSEClick = () => {
        this.setState({ ...this.state, ...{ show: true } })
    }
    handleClose = () => {
        this.setState({ ...this.state, ...{ show: false } });
        updatedEditedStyle({});
    }
    checkIfDraftAndLiveClmnMetaEqual(parentObj) {
        const obj1 = parentObj[this.props.subEdit];
        const obj2 = parentObj.draft[this.props.subEdit];
        if (isEqual(obj1, obj2)) {
            return true;
        }
    }
    moveChangesToLive = async () => {
        let application = store.getState().application
        // let listingType = application.listingType;

        let listMetaData = application.listMetaData;
        let list = application.list;
        let draftMetaData = listMetaData[1];
        let publishedMetaData = listMetaData[2];
        if (isEqual(publishedMetaData[this.props.sekey], draftMetaData[this.props.sekey])) {
            this.setState({ ...this.state, showToastModal: true, toastMessage: 'Draft and Production are already Same!!' })
            return;
        }
        let newData = { [this.props.sekey]: draftMetaData[this.props.sekey] }
        try {
            this.setState({ ...this.state, ...{ moveToLiveProgress: true } })
            await this.saveListMeta(list.id, 'published', newData);
            this.setState({ ...this.state, ...{ moveToLiveProgress: false, message: 'updated' } })
            updatedEditedStyle({});
            setTimeout(() => {
                this.setState({ ...this.state, ...{ show: false } })
            }, 1000)
        } catch (error) {
            this.setState({ ...this.state, ...{ moveToLiveProgress: false, message: 'error please try again' } })
        }
    }

    resetChanges = async (dataFromChild) => {
        await this.saveChanges(dataFromChild, true);
        setTimeout(() => {
            this.setState({ ...this.state, ...{ show: false } })
        }, 500)
    }
    saveListMeta = async (listId, type, data) => {
        await updateListMetaData(listId, type, data);
    }
    saveChanges = async (dataFromChild, isReset) => {
        let application = store.getState().application
        let editedStyle = application.editedStyle;

        if(!editedStyle?.image?.url && this.props?.oldImage?.url){
           await deleteImage(this.props.oldImage.name, 'metadata');
           setOldImage({});
        }

        // When key === 'img'
        if(editedStyle?.image?.url && editedStyle?.style?.backgroundColor) {
            this.setState({ ...this.state, showToastModal: true, toastMessage: 'Please Select only one.' })
            return;
        }

        if(editedStyle && editedStyle.grad) {
            const grad = editedStyle.grad;
            if(!grad.gradDirection)
                grad.gradDirection = 'Select';
            if((!grad.gradColorStart && !grad.gradColorEnd && !grad.gradDirection) || 
               (grad.gradColorStart && grad.gradColorEnd && grad.gradDirection !== "Select") ||
               (!grad.gradColorStart && !grad.gradColorEnd && grad.gradDirection === "Select")){
                        this.setState({fieldsNotSelectedWarning: false});
                    } else {
                        this.setState({fieldsNotSelectedWarning: true});
                        return;
            }
        }

        let list = application.list;
        if (!isReset && !editedStyle) {
            this.setState({ ...this.state, showToastModal: true, toastMessage: 'Nothing to Save!!' })
            return;
        }
        this.deleteUnwantedStates(editedStyle);

        let saveObj = {};
        if (isReset) {
            saveObj[this.props.sekey] = {};
        } else {
            saveObj[this.props.sekey] = editedStyle;
        }


        try {
            this.setState({ ...this.state, ...{ saving: isReset ? false : true, resetting: isReset ? true : false } })
            await this.saveListMeta(list.id, 'draft', saveObj);
            this.setState({ ...this.state, ...{ saving: false, resetting: false, message: 'updated', fieldsNotSelectedWarning: false } })
            updatedEditedStyle(editedStyle);
        } catch (error) {
            this.setState({ ...this.state, ...{ saving: false, resetting: false, message: 'error please try again' } })
        }
        return;

    }

    deleteUnwantedStates = (obj) => {
        delete obj.color;
        delete obj.showColor;
        delete obj.showBackgroundColor;
        delete obj.showGradColorStart;
        delete obj.showGradColorEnd;
        delete obj.iconShow;
        delete obj.imageUploading;
    }

    showToast = (message) => {
        return (
            <div
                style={{
                    width: '400px',
                }}
            >
                <Toast style={{
                    top: "0",
                    right: 0,
                }} show={this.state?.showToastModal} onClose={() => this.setState({ showToastModal: false, toastMessage: '' })} delay={5000} autohide>
                    {/* <Toast.Header className="bg-light">
                    <strong className="mr-auto text-success">Update</strong>
                </Toast.Header> */}
                    <Toast.Body className="text-warning bg-white">{this.state.toastMessage}</Toast.Body>
                </Toast>
            </div>
        )
    }
    getMoveToLiveButton = () => {
        return <Button variant="primary" onClick={this.moveChangesToLive}>
            Move To Live
        </Button>
    }
    getDialog = () => {
        return <Modal show={this.state?.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Styles Editor</Modal.Title>
                {this.state?.showToastModal ?
                    this.showToast()
                    : ''}
            </Modal.Header>
            <Modal.Body>
                <StyleEditor type={this.props.type} sekey={this.props.sekey} ></StyleEditor>
                <span hidden={!this.state.fieldsNotSelectedWarning} 
                              style={{ color: '#ff9800' }}>Please select all the above three fields.
                </span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Close
                </Button>
                {this.state?.resetting ? <Button>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            updating...
                                        </Button> :
                    <Button variant="primary" onClick={this.resetChanges}>
                        Reset
                    </Button>
                }
                {this.state?.saving ? <Button>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            updating...
                                        </Button> :
                    <Button variant="primary" onClick={this.saveChanges}>
                        Save Changes
                    </Button>
                }


                {this.state?.moveToLiveProgress ? <Button>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            updating...
                                        </Button> :
                    this.props?.editedStyle?.datatype ? '' : this.getMoveToLiveButton()
                }
            </Modal.Footer>
        </Modal>
    }


    render() {
        return (
            <>
                {
                    this.props.applicationMode === 'admin' ?
                        <>
                            {this.getDialog()}
                            <span onClick={this.onSEClick} style={{ position: 'absolute', cursor: 'pointer' }}>
                                <span className="text-light">*</span><span>*</span>
                            </span>
                        </>
                        :
                        ''
                }

            </>
        );
    }
}

export default connect(mapStateToProps)(SEButton);