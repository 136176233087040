import React from 'react';
import { withRouter } from 'react-router';
import * as Layouts from './layouts';
import { connect } from 'react-redux';
import { setFromListHome, setSubNavActiveLink } from "./redux/templ";
import { selectLoadingConsumer } from "./redux/list-config.selector";
import { BounceLoader } from 'react-spinners';

const mapStateToProps = (state) => {
    return {
        activeHomeLayout: state.application?.listingType?.activeHomeLayout,
        list: state.application.list,
        lsitingTypes: state.application.listingTypes,
        lsitingType: state.application.listingType,
        fromListhome: state.tempData?.fromListHome,
        loading: selectLoadingConsumer(state),
    }
}

const loaderCss = `
    display: block;
    margin: 0 auto;
    border-color: red;
`
class PageOne extends React.PureComponent {

    renderDynamicComponent = () => {
        let layout = this.props?.activeHomeLayout;
        console.log("PAGEONE: layout:", layout)
        let Layout;
        if (layout) {
            Layout = Layouts[layout];
        } else {
            Layout = Layouts['NoLayoutFound']
        }
        setSubNavActiveLink('home')
        return <Layout></Layout>
    }
    componentDidMount = async () => {
        const { params } = this.props.match;
        const { uuid, year } = params;
        const isBizLeaders = uuid === "businessLeaders" && year === "2020"
        const isGovtLeaders = uuid === "governmentLeaders" && year === "2021"
        const isPhilanthropyLeaders = uuid === "philanthropyLeaders" && year === "2021"
        const isSocialEntrepreneur = uuid === "socialEntrepreneurs" && year === "2022"
        if(!isBizLeaders && !isGovtLeaders && !isPhilanthropyLeaders && !isSocialEntrepreneur){
            this.props.history.replace("/");
        }

        if(this.props.fromListhome){
            window.scrollTo(0, 0)
            setFromListHome(false);
        }
    }
    render() {
        return (
            <div>
                {!this.props.loading && this.props?.activeHomeLayout && <this.renderDynamicComponent/>}
                {this.props.loading && <div className="loader-container">
                    <BounceLoader
                        css={loaderCss}
                        size={150}
                        color={"#f8b13e"}
                        loading={this.props.loading}
                    />
                </div>}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(PageOne)) 