import React from 'react';
import { getListingTypes } from "./listdetails/listdetails-fs";
import * as Layouts from './layouts';
import { connect } from 'react-redux';
import { resetTemp } from './redux/templ';
import { resetApp } from './redux/application'
const mapStateToProps = (state) => {
    return {
        listingTypes : state.application.listingTypes
    }
}
class PageZero extends React.PureComponent {

    renderDynamicComponent = () => {
        // we should get this from the subdomain layout later on
        let layout = "SubdomainHomeLayOne";
        let Layout;
        if (layout) {
            Layout = Layouts[layout];
        } else {
            Layout = Layouts['NoLayoutFound']
        }
        return <Layout></Layout>
    }
    componentDidMount = async() => {
        resetTemp();
        resetApp({
            mode: 'consumer',
        });
        const params = this.props.match.params;
        await getListingTypes();
    }
    render() {
        return (
            <div>
                {this.props?.listingTypes && <this.renderDynamicComponent/>}
            </div>
        );
    }
}

export default connect(mapStateToProps)(PageZero) 