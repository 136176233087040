import { store } from '../store';


//enums
const LOAD_QUOTES = 'LOAD_QUOTES';
const LOAD_MORE_QUOTES = 'LOAD_MORE_QUOTES';
//actions creators
let loadQuotesAc = (payload) => {
    return {
        type: LOAD_QUOTES,
        payload
    }
}
let loadMoreQuotesAc = (payload) => {
    return {
        type: LOAD_MORE_QUOTES,
        payload
    }
}


//actions
export const loadQuotes = (payload, loadMoreData) => {
    let state = store.getState();
    let quotesData = [];
    if(state.quotes?.quotesData?.length > 0)
        quotesData = state.quotes?.quotesData;
    if(state.application.mode === "admin") {
        quotesData = {quotesData: [...payload]};
    } else {
        quotesData = {quotesData: [...quotesData, ...payload]};
    }
    store.dispatch(loadQuotesAc(quotesData))
    store.dispatch(loadMoreQuotesAc(loadMoreData))

}



let initialState = []
export const quotesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REST_TEMP': return { ...initialState }
        case LOAD_QUOTES: return action.payload
        case LOAD_MORE_QUOTES: return {...state, ...{loadMoreQuotes: action.payload}}
        default: return (() => {
            return state
        })()
    }
}
    