import React from 'react';
import Navbar from '../first-layout/components/navbar';
import SubNavbar from '../first-layout/components/sub-navbar';
import Footer from '../first-layout/components/footer';
import Contact from '../first-layout/components/contact/contact';

class ContactLayout extends React.Component {
    render() {
        return (
            <div className="mainContainer">
                <Navbar />
                <SubNavbar></SubNavbar>
                <Contact></Contact>
                <Footer />
            </div>
        );
    }
}


export default ContactLayout;