import firebase from './firebase';
import { exportExcelCount } from "./redux/application";

let storage = firebase.storage();

let uploadSingleImage = (image, imageType, cb)=>{
    let target;
    if(imageType === 'image') {
        target = 'images';
    } else {
        target = 'profile-images';
    }
    let uploadTask = storage.ref('/' + target + '/' + image.name).put(image);
    uploadTask.on('state_changed',(snapShot) => {
            let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
            per = parseInt(per);
            console.log(per);
        }, (err) => {
            cb(err);
        }, () => {
            storage.ref(target).child(image.name).getDownloadURL()
                .then(async (fireBaseUrl) => {
                    let name = image.name.split('.')
                    cb(null,{bioLink:name[0],fireBaseUrl});
                })
        })
}

let looper = (count, images, sucessArray, imageType, cb)=>{
    if(count<images.length){
        uploadSingleImage(images[count], imageType, (err,res)=>{
            if(!err){
                sucessArray.push(res);
                exportExcelCount(count + ' of ' + images.length)
                looper(count+1, images, sucessArray, imageType, cb);
            }else{
                cb(err);
            }
        })
    }else{
        cb(null, sucessArray);
        console.log('all images are processed');
    }
}




export const ImagesUploadHelper = (images, imageType, cb) =>{
    exportExcelCount('Processing for upload');
    looper(0, images, [], imageType, cb);
}