import { store } from '../store';


//enums
const LOAD_NEWS = 'LOAD_NEWS'
const LOAD_MORE_NEWS = 'LOAD_MORE_NEWS'

//actions creators
let loadNewsAc = (payload) => {
    return {
        type: LOAD_NEWS,
        payload
    }
}

let loadMoreNewsAc = (payload) => {
    return {
        type: LOAD_MORE_NEWS,
        payload
    }
}


//actions
export const loadNews = (payload, loadMoreData) => {
    let state = store.getState();
    let newsData = [];
    if(state.news?.newsData?.length > 0)
        newsData = state.news?.newsData;
    if(state.application.mode === "admin") {
        newsData = {newsData: [...payload]};
    } else {
        newsData = {newsData: [...newsData, ...payload]};
    }
    store.dispatch(loadNewsAc(newsData))
    store.dispatch(loadMoreNewsAc(loadMoreData))
}



let initialState = []
export const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REST_TEMP': return { ...initialState }
        case LOAD_NEWS: return action.payload
        case LOAD_MORE_NEWS: return {...state, loadMoreNews: action.payload}
        default: return (() => {
            return state
        })()
    }
}

    