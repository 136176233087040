import { Form, Button, Spinner, Image } from 'react-bootstrap';
import React from 'react';
import { connect } from 'react-redux';
import firebase from "../../firebase";
import { updatedEditedStyle } from '../../redux/application'
import { setOldImage } from "../../redux/templ";

const mapStateToProps = (state) => {
    return {
        listingType: state.application.listingType,
        listMetaData: state.application.listMetaData,
        editedStyle: state.application.editedStyle,
        imageUploading: state.tempData?.imageUploading
    }
}
class StyleEditor extends React.Component {
    
    fontSizes = [
        { key: "Select", value: null }
    ]
    fontWeights = [
        { key: "Select", value: null }
    ]
    constructor(props) {
        console.log("StyleEditor", props)
        super(props);
        this.state = {}
        this.setFontSizes()
        this.setFontWeights()
    }

    setFontSizes = () => {
        for (let index = 8; index < 73; index++) {
            const px = index + 'px';
            const fntObj = { key: px, value: px }
            this.fontSizes.push(fntObj);
        }
    }
    setFontWeights = () => {
        let lastIndex;
        for (let index = 100; index <= 900; index += 100) {
            const weight = index;
            const fntObj = { key: weight, value: weight }
            this.fontWeights.push(fntObj);
            lastIndex = index;
        }
        const lastWeight = lastIndex + 50;
        const lastFntObj = { key: lastWeight, value: lastWeight }
        this.fontWeights.push(lastFntObj);
    }
    
    onInputChange = (e) => {
        let obj = {};
        const targetId = e.target.id;
        if (targetId === 'value') {
            obj[targetId] = e.target.value;
            this.setState({ ...this.state, ...obj }, () => {
                let dataToPass = { ...this.state };
                updatedEditedStyle(dataToPass);
            })
        } else {
            obj[targetId] = e.target.value;
            if (targetId == "backgroundColor")
                obj.backgroundImage = "none";
            this.setState({ ...this.state, ...{ style: { ...this.state.style, ...obj } } }, () => {
                let dataToPass = { ...this.state };
                updatedEditedStyle(dataToPass);
            })
        }
    }
    onGradChange = (e) => {
        let obj = {}
        obj[e.target.id] = e.target.value;
        let newGrad = { ...this.state.grad, ...obj };
        this.setState({ ...this.state, ...{ grad: newGrad } }, () => {
            this.applyGrad();
        });
    }
    applyGrad = () => {
        let grad = { ...this.state.grad }
        let newStyle;
        if(grad.gradDirection || grad.gradColorStart || grad.gradColorEnd) {
            grad = 'linear-gradient(' + grad.gradDirection + ',' + grad.gradColorStart + ',' + grad.gradColorEnd + ')';
            newStyle = { ...this.state.style, ...{ backgroundImage: grad } };
        } else {
            newStyle = { ...this.state.style};
        }
        this.setState({ ...this.state, ...{ style: newStyle } }, () => {
            let dataToPass = { ...this.state };
            updatedEditedStyle(dataToPass);
        })
    }

    onFormSubmit = (event) => {
        event.preventDefault();
        console.log(this.state);
    }
    textAreaJsx = () => {
        return <> <Form.Group controlId="value">
            <Form.Label>Value</Form.Label>
            <Form.Control as="textarea" rows="8" placeholder="Enter Text"
                onChange={this.onInputChange} defaultValue={this.state?.value} />
        </Form.Group></>
    }
    getTextAreaJsx = () => {
        if (this.props.type === 'text' && !this.props.subEdit) {
            return this.textAreaJsx();
        }
    }
    fontSizesJsx = () => {
        return <Form.Group controlId="fontSize" onChange={this.onInputChange} >
            <Form.Label>Font Size&nbsp;{!this.state?.style?.fontSize ? " - Value not overridden" : ' - Value overridden'}</Form.Label>
            <Form.Control as="select" custom value={this.state?.style?.fontSize} onChange={this.onInputChange}>
                {this.fontSizes.map(fntObj => {
                    return <option value={fntObj.value} key={fntObj.key}>{fntObj.key}</option>
                })}
            </Form.Control>
        </Form.Group>
    }
    getFontSizeJsx = () => {
        if (this.props.type === 'text' || this.props.type === 'icon' || this.props.type === 'nonValuetext') {
            return this.fontSizesJsx();
        }
    }

    fontWeightJsx = () => {
        return <Form.Group controlId="fontWeight" onChange={this.onInputChange} >
            <Form.Label>Font Weight&nbsp;{!this.state?.style?.fontWeight ? " - Value not overridden" : ' - Value overridden'}</Form.Label>
            <Form.Control as="select" custom value={this.state?.style?.fontWeight} onChange={this.onInputChange}>
                {this.fontWeights.map(fntObj => {
                    return <option value={fntObj.value} key={fntObj.key}>{fntObj.key}</option>
                })}
            </Form.Control>
        </Form.Group>
    }
    getFontWeightJsx = () => {
        if (this.props.type === 'text' || this.props.type === 'nonValuetext') {
            return this.fontWeightJsx();
        }
    }
    getImgUploadJsx = () => {
        if (this.props.type === 'img') {
            return this.imgUploadJsx();
        }
    }

    imgUploadJsx = () => {
        return (
            <Form className="text-center">
                <p>or</p>
                <Form.Group controlId="image" >
                    <Image src={this.state?.image?.url ? this.state?.image?.url : this.props?.editedStyle?.image?.url} 
                           hidden={!this.props?.editedStyle?.image?.url} rounded thumbnail className={'col-6'}/>
                    <div className="row align-middle pl-3 mt-2">
                        <Form.File type="file" accept="image/*" className="col-9 border pl-0 pr-2"
                            onChange={this.handleImageUpload}/>
                        <Button variant="info" className="col-2 ml-3" size="sm" onClick={this.hideImage}
                            hidden={!this.props?.editedStyle?.image?.url} >Delete</Button>
                    </div>
                    <p hidden={!this.state.imageUploading}><Spinner animation="grow" size="sm"/> uploading...</p>
                </Form.Group>
            </Form>
        )
    }

    hideImage = async() => {
        setOldImage(this.props?.editedStyle?.image);
        let obj = {};
        obj = { ...this.state, ...{image: {} }};
        this.setState({ ...this.state, ...obj }, () => {
            let dataToPass = { ...this.state };
            delete dataToPass.image;
            updatedEditedStyle(dataToPass);
        })
    }

    updateState = (changeState) => {
        let obj = { ...this.state, ...changeState }
        this.setState({ ...this.state, ...obj }, () => {
            let dataToPass = { ...this.state };
            updatedEditedStyle(dataToPass);
        });
    }

    handleImageUpload = (e,) => {
        this.setState({imageUploading: true})
        const image = e.target.files[0];
        if (image && image.name) {
            let storage = firebase.storage();
            let imgNewName = image.name;

            const [pluginName, fileExtension] = imgNewName.split(/\.(?=[^\.]+$)/);
            imgNewName = `${this.props.sekey}.${fileExtension}`;

            let uploadTask = storage.ref('/' + 'metadata' + '/' + imgNewName).put(image);
            uploadTask.on('state_changed',
            (snapShot) => {
                // let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
                // per = parseInt(per);
                    // this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: true } })
                }, (err) => {
                    // this.setState({ updatingImage: false })
                    this.setState({imageUploading: false});
                }, () => {
                    storage.ref('metadata').child(imgNewName).getDownloadURL()
                        .then(async (fireBaseUrl) => {
                            let obj = {};
                            obj = { ...this.state, ...{image: { ...{url : fireBaseUrl, name: imgNewName} } } }
                            this.setState({ ...this.state, ...obj }, () => {
                                let dataToPass = { ...this.state };
                                updatedEditedStyle(dataToPass);
                            })
                            setTimeout(() => {
                                this.setState({imageUploading: false})
                            }, 1000)
                        })
                })
        } else {
            // console.log('pass image name')
        }
    }


    colorPickerJsx = () => {
        return this.state?.style ? <Form.Group controlId={'color'}>
            <div className="row ml-3 pr-3">
                <Form.Label>Color&nbsp;
                    {!this.state?.showColor ? " - Value not overridden" : ' - Value overridden'}
                </Form.Label>

                <Button className="col-12" size="sm" block={true} hidden={this.state?.showColor} variant="info"
                    onClick={() => this.openColorPicker('showColor', 'color')}>Select color</Button>

                <Form.Control type="color" className="col-10" value={this.state?.style?.color}
                    hidden={!this.state?.showColor} onChange={this.onInputChange} />

                <Button variant="info" className="col-2" className="m-2" size="sm" 
                    hidden={!this.state?.showColor}
                    onClick={() => this.deleteStyle('color', 'showColor')}>Delete</Button>
            </div>
        </Form.Group> : ''
    }

    backgroundColorPickerJsx = () => {
        return this.state?.style ? <Form.Group controlId={'backgroundColor'}>
            <div className="row ml-3 pr-3">
                <Form.Label>Background Color&nbsp;
                    {!this.state?.showBackgroundColor ? " - Value not overridden" : ' - Value overridden'}
                </Form.Label>

                <Button className="col-12" size="sm" block={true} hidden={this.state?.showBackgroundColor} variant="info"
                    onClick={() => this.openColorPicker('showBackgroundColor', 'backgroundColor')}>Select color</Button>

                <Form.Control type="color" className="col-10" value={this.state?.style?.backgroundColor}
                    hidden={!this.state?.showBackgroundColor} onChange={this.onInputChange} />

                <Button variant="info" className="col-2" className="m-2" size="sm" 
                    hidden={!this.state?.showBackgroundColor}
                    onClick={() => this.deleteStyle('backgroundColor', 'showBackgroundColor')}>Delete</Button>
            </div>
        </Form.Group> : ''
    }
    getColorPickerJsx = (controlId) => {
        if(this.props.type === 'div' || this.props.type === 'img') {
            return;
        }
        if (
            (this.props.type === 'text') || 
            (this.props.type === 'nonValuetext') ||
            (this.props.type === 'textCB') ||
            (controlId === "color") 
            // (this.props.type === 'div')
        ) {
            return this.colorPickerJsx();
        }
    }
    getBackgroundColorPickerJsx = (controlId) => {
        if (
            (this.props.type === 'text') ||
            (this.props.type === 'img') ||
            (this.props.type === 'textCB') ||
            (this.props.type === 'div' && controlId === "backgroundColor")
        ) {
            return this.backgroundColorPickerJsx();
        }
    }
    

    deleteGrad = (colorId, hideColorSection) => {
        this.setState({ [hideColorSection]: false }, () => {
            if(this.state?.grad?.[colorId]) {
                let newGrad = this.state?.grad;
                delete newGrad?.[colorId];
                this.setState({ ...this.state, ...{ grad: newGrad }}, () => {
                    this.applyGrad();
                })
            }
        })
    }

    deleteStyle = (colorId, hideColorSection,) => {
        this.setState({ [hideColorSection]: false }, () => {
            if(this.state?.style?.[colorId]) {
                let newStyle = {...this.state?.style};
                delete newStyle?.[colorId];
                this.setState({ ...this.state, ...{ style: newStyle }}, () => {
                    let dataToPass = { ...this.state };
                    updatedEditedStyle(dataToPass);
                })
            }
        })
    }

    openColorPicker = (showColorState, colorControlId) => {
        this.setState({[showColorState]: true}, () => {
            document.getElementById(colorControlId).click();
        })
    }

    getGradientJsx = () => {
        if (this.props.type === 'text' || this.props.type === 'img' || 
            this.props.type === 'textCB' ||
            this.props.type === 'icon' || this.props.type === 'nonValuetext') {
            return;
        }
        return <div className="col-12">
        <Form.Group controlId="gradColorStart" >
            <div className="row pl-3">
                <Form.Label >Grad Color Start</Form.Label>
                <Button className="pl-2 pr-2" size="sm" block={true} hidden={this.state?.showGradColorStart} variant="info"
                    onClick={() => this.openColorPicker('showGradColorStart', 'gradColorStart')}>Select color</Button>
                <Form.Control id="gradColorStart" className="col-10" type="color" 
                    value={this.state?.grad?.gradColorStart}
                    hidden={!this.state?.showGradColorStart} onChange={this.onGradChange} />
                <Button variant="info" className="col-2" className="m-2" size="sm" 
                    hidden={!this.state?.showGradColorStart}
                    onClick={() => this.deleteGrad('gradColorStart', 'showGradColorStart')}>Delete</Button>
            </div>
        </Form.Group>
        <Form.Group controlId="gradColorEnd">
            <div className="row pl-3">
                <Form.Label>Grad Color End</Form.Label>
                <Button className="pl-2 pr-2" size="sm" block={true} hidden={this.state?.showGradColorEnd} variant="info"
                    onClick={() => this.openColorPicker('showGradColorEnd', 'gradColorEnd')}>Select color</Button>
                <Form.Control id="gradColorEnd" className="col-10" type="color" 
                    value={this.state?.grad?.gradColorEnd}
                    hidden={!this.state?.showGradColorEnd} onChange={this.onGradChange} />
                <Button variant="info" className="col-2" className="m-2" size="sm" 
                    hidden={!this.state?.showGradColorEnd}
                    onClick={() => this.deleteGrad('gradColorEnd', 'showGradColorEnd')}>Delete</Button>
            </div>
        </Form.Group>
            <Form.Group controlId="gradDirection" value={this.state?.grad?.gradDirection}  >
                <Form.Label>Direction</Form.Label>
                <Form.Control as="select" value={this.state?.grad?.gradDirection} onChange={this.onGradChange} custom>
                    <option>Select</option>
                    <option value="to right">to right</option>
                    <option value="to left">to left</option>
                    <option value="to bottom right">to bottom right</option>
                </Form.Control>
            </Form.Group>
            {/* <Button onClick={this.applyGrad}>Apply Gradient</Button> */}
        </div>
    }

    componentDidMount = () => {
        let listMetaData = this.props.listMetaData;
        let defaultMetaData = listMetaData[0];
        let draftMetaData = listMetaData[1];
        let obj = draftMetaData[this.props.sekey];
        if (!obj) {
            obj = { style: {} };
        } else if (!obj.style) {
            obj.style = {}
        }
        if (!obj.value && defaultMetaData[this.props?.sekey]?.value) {
            obj.value = defaultMetaData[this.props.sekey].value;
        }
        let newState = { ...this.state, ...obj };
        this.setState(newState);
        updatedEditedStyle(newState);
        this.updateCompStates(newState);
    }

    updateCompStates = (newState) => {
        if(newState?.style?.backgroundColor)
            this.setState({showBackgroundColor: true});
        if(newState?.grad?.gradColorEnd)
            this.setState({showGradColorEnd: true});
        if(newState?.grad?.gradColorStart)
            this.setState({showGradColorStart: true});
        if(newState?.style?.color)
            this.setState({showColor: true});
    }

    render() {
        return (
            <>
                {/* <span style={{ fontSize: '20px', color: 'green' }}>SANDIP</span> */}
                <Form onSubmit={this.onFormSubmit} noValidate validated={this.state?.validated}>
                    <div className="row justify-content-center align-items-center  p-2">
                        <div className="col-12">
                            {this.getTextAreaJsx()}
                        </div>
                    </div>
                    <div className="col-12">
                        {this.getFontSizeJsx()}
                    </div>
                    <div className="col-12">
                        {this.getFontWeightJsx()}
                    </div>
                    <div className="col-12">
                        {this.getColorPickerJsx('color')}
                    </div>
                    <div className="col-12">
                        {this.getBackgroundColorPickerJsx('backgroundColor')}
                    </div>
                    <div className="col-12">
                        {this.getGradientJsx()}
                    </div>

                    <div className="col-12">
                        {this.getImgUploadJsx()}
                    </div>
                </Form>
                {/* <button onClick={this.onFormSubmit}>onSubmit</button> */}
            </>
        );
    }
}
export default connect(mapStateToProps)(StyleEditor);