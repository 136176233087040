import firebase from '../firebase';
import { setListingTypesByDomain } from './linstingtypes-rx'

export const getListingTypesBySubdomain = async (subdomain) => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection('listingTypes').where("subdomain", "==", subdomain).get();
        let data = [];
        snap.forEach((inQryDoc) => {
            let dwithId = inQryDoc.data();
            dwithId.id = inQryDoc.id;
            data.push(dwithId)
        })
        setListingTypesByDomain(data)
    } catch (error) {
        return error
    }
}




