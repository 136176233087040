import React from 'react';
import { createSelector } from 'reselect'
import { BsChevronUp, BsChevronDown } from "react-icons/bs";
import { connect } from 'react-redux';
import { loadActiveBio } from '../../../../listdetails/listdetails-rx';
import { withRouter } from 'react-router';
import { setFromSlider } from "../../../../redux/templ";
import { selectUUIDAndYear } from "../../../../redux/list-config.selector";
import { removeBioQuote } from '../../../../listdetails/listdetails-fs';

const selectListData = state => state.application?.listData;

const selectSortedBios = createSelector(
    selectListData,
    (listData) => {
        let tempBios = listData?.slice();
        tempBios = tempBios?.sort((a, b) => {
            if(a.lastname || a.firstname)
                return (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
            if(a.name)
                return (a.name).localeCompare(b.name)
        });
        return tempBios;
    }
)

const mapStateToProps = (state) => {
    let bios = selectSortedBios(state);
    return {
        activeBio: state?.application?.activeBio,
        uuidAndYear: selectUUIDAndYear(state),
        bios: bios
    }
}
class Slider extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            bios: [],
            // currentPointer: 0
        }
    }
    onImageClick = (profile, index) => {
        const {uuid, year} = this.props.uuidAndYear;
        loadActiveBio(profile);
        removeBioQuote();
        setFromSlider(true);
        let url = `/${uuid}/${year}/bio/${profile.bioLink}`;
        this.props.history.replace(url);
        setTimeout(() => {
            this.scrollBy(index)
        }, 300)
    }
    scrollUp() {
        let nextPointer = this.state.currentPointer - 1;
        let elmnt = document.getElementById(nextPointer);
        if (elmnt) {
            elmnt.scrollIntoView({ behavior: "smooth" });
            this.setState({ ...this.state, ...{ currentPointer: nextPointer, isActiveStyle: false } });
        }
    }
    scrolldown() {
        let nextPointer = this.state.currentPointer + 1;
        let elmnt = document.getElementById(nextPointer);
        if (elmnt) {
            elmnt.scrollIntoView({ behavior: "smooth" });
            this.setState({ ...this.state, ...{ currentPointer: nextPointer, isActiveStyle: false } });
        }
    }
    scrollBy(pointer) {
        let elmnt = document.getElementById(pointer);
        if (elmnt) {
            elmnt.scrollIntoView({ behavior: "smooth" });
            this.setState({ ...this.state, ...{ currentPointer: pointer, isActiveStyle: true } });
        }
    }
    setScrollOnRender = () => {
        if(this.props.bios){
            let index = this.props.bios.findIndex(x => x?.id === this.props?.activeBio?.id);
            setTimeout(() => {
                this.scrollBy(index)
            }, 1500)
        }
    }
    componentDidMount(){
        this.setScrollOnRender();
    }

    getSrc = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        if(imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let src = imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_100x100`);
        return src;
    }

    render() {
        return (
            <>
            {this.props.activeBio && <div className="slider-container">
                <div className="border rounded relative d-none d-sm-none d-md-none d-lg-block d-xl-block bg-light">
                    <h4 className="slider-up" onClick={() => { this.scrollUp() }}>
                        <BsChevronUp />
                    </h4>

                    <div className="slider-desktop-container">
                        <div className="slider-desktop-container-child p-1">
                            {
                                this.props?.bios?.map((bio, index) => {
                                    return <div className="card p-1 m-1" style={{ background: index === this.state.currentPointer && this.state.isActiveStyle ? 'red' : 'none' }} id={index} key={index} onClick={() => this.onImageClick(bio, index)}>
                                        <img src={this.getSrc(bio?.imageUrl, bio?.bioLink)} className="card-img-top hand-cursor" alt="..." />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <h4 className="slider-down" onClick={() => { this.scrolldown() }}>
                        <BsChevronDown />
                    </h4>
                </div>

                {/* mobile */}
                <div className="row align-content-center align-items-center no-gutters d-flex d-lg-none d-md-flex d-sm-flex d-xl-none mb-2">
                    {/* <div className="col-1 text-left">
                        <h4 className="text-primary font-weight-bolder">
                            <BsChevronLeft onClick={() => { this.scrollUp() }} />
                        </h4>
                    </div> */}
                    <div className="col-12 text-center">
                        <div className="slider-mobile-container">
                            <div className="slider-mobile-container-child">
                                {
                                    this.props?.bios?.map((bio, index) => {
                                        return <div className="card slider-mobile-card" style={{ background: index === this.state.currentPointer && this.state.isActiveStyle ? 'red' : 'none' }} id={index} key={index} onClick={() => this.onImageClick(bio, index)}>
                                            <img src={bio?.imageUrl?.replace(`/images%2F${bio.bioLink}`, `/images%2Fthumbnails%2F${bio.bioLink}_100x100`)} className="card-img-top" alt={bio.bioLink} />
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-1 text-right">
                        <h4 className="text-primary font-weight-bolder">
                            <BsChevronRight onClick={() => { this.scrolldown() }} />
                        </h4>
                    </div> */}
                </div>
            </div>
            }
            </>
        );
    }
}

export default connect(mapStateToProps)(withRouter(Slider));
