import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import firebase from '../../firebase';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { exelImport, updateListData } from '../../listdetails/listdetails-fs';
import { ImagesUploadHelper } from '../../images-upload-helper'
import ReactDataGrid from "react-data-grid";
import 'react-data-grid/dist/react-data-grid.css';
import VerticalModal from '../../common/modal';
import { profileAddEditModal } from '../../redux/templ';
import AddEditProfile from '../../profile/add-edit-profile';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

class GridData extends React.Component {

    exportClmns = () => {
        let columnsForExel = [];
        this.props.listingType.columns.forEach(cl => {
            columnsForExel.push(cl.key);
        });
        const ws = XLSX.utils.json_to_sheet([columnsForExel]);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, this.props.list.name + ' Clmsn' + fileExtension);
    }

    exportTable = () => {
        let exelData = []
        let columnsForExel = [];
        this.props.listingType.columns.forEach(cl => {
            columnsForExel.push(cl.key);
        });
        exelData.push(columnsForExel);
        this.props.listData.forEach(ld => {
            let row = []
            this.props.listingType.columns.forEach((cl, index) => {
                row.push(ld[cl.key])
            });
            exelData.push(row)
        });
        console.log(exelData);
        const ws = XLSX.utils.json_to_sheet(exelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, this.props.list.name + ' Table' + fileExtension);
    }
    onChangeFile(file) {
        let getLastPart = (str) => {
            return str ? str.substring(str.lastIndexOf("/") + 1) : '';
        };
        let filterForNA = (obj) => {
            for (var key in obj) {
                if (obj[key] === 'N/A') {
                    obj[key] = '';
                }
            }
            return obj;
        }
        const { files } = file.target;
        const fileReader = new FileReader();
        fileReader.onload = async event => {
            try {
                const { result } = event.target;
                const workbook = XLSX.read(result, { type: 'binary' });
                let data = [];
                for (const sheet in workbook.Sheets) {
                    if (workbook.Sheets.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                    }
                }
                let getClmnKey = (index) => {
                    if(isNaN(index))
                        return;
                    return this.props.listingType.columns[index].key
                }
                let newData = [];
                data.forEach((ld, index) => {
                    if (index > 0) {
                        let newRow = {}
                        for (var key in ld) {
                            newRow[getClmnKey(key)] = ld[key];
                        }
                        newRow.bioLink = getLastPart(newRow.bioLink);
                        newRow = filterForNA(newRow);
                        if((newRow.firstname && newRow.lastname) || newRow.name)
                            newData.push(newRow)
                    }
                });
                this.setState({ ...this.state, ...{ importingExel: true } })
                await exelImport(this.props.listId, newData, this.props.isChild);
                this.setState({ ...this.state, ...{ importingExel: false } })
            } catch (e) {
                console.log(e);
                console.log('file type is incorrect');
                return;
            }
        };
        if(files[0])
            fileReader.readAsBinaryString(files[0]);
        console.log('inside file change')
    }
    openProfileModal = () => {
        profileAddEditModal({ show: true, type: 'add' })
        this.setState({ ...this.state, ...{ addNewRow: true } })
    }
    handleImageUpload = (e, imageType) => {
        let target;
        if(imageType === 'image') {
            target = 'images';
        } else {
            target = 'profile-images';
        }
    
        this.setState({ ...this.state, ...{ updatingTableRow: true } })
        const image = e.target.files[0];
        if (image && image.name) {
            let storage = firebase.storage();
            let uploadTask = storage.ref('/' + target + '/' + image.name).put(image);
            uploadTask.on('state_changed',
                (snapShot) => {
                    // let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
                    // per = parseInt(per);
                    this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: true } })
                }, (err) => {
                    this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: false, activeRowForImageUpload: [] } })
                }, () => {
                    storage.ref(target).child(image.name).getDownloadURL()
                        .then(async (fireBaseUrl) => {
                            let row = { ...this.state.activeRowForImageUpload }
                            if(imageType === 'image') {
                                row.imageUrl = fireBaseUrl;
                            } else {
                                row.bioImageUrl = fireBaseUrl;
                            }
                            await updateListData(this.props.listId, row.id, row, this.props.isChild);
                            this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: false, activeRowForImageUpload: [] } })
                            setTimeout(() => {
                                this.setState({ ...this.state, ...{ uploadStatus: false } })
                            }, 3000)
                        })
                })
        } else {
            console.log('pass image name')
        }
    }
    handleMultipleImageUpload = (e, imageType) => {
        const images = e.target.files;
        if(!images.length) return;
        let state = imageType === 'image' ? 'updatingMultipleImages' : 'updatingMultipleBioImages';
        this.setState({ [state]: true }, () => {
            ImagesUploadHelper(images, imageType, (err, res) => {
                if (res && res.length) {
                    let datas = this.props.listData.slice();
                    let newDatasToUpdate = []
                    datas.forEach(row => {
                        res.forEach((uploadedImg, index) => {
                            if (row.bioLink === uploadedImg.bioLink) {
                                if(imageType === 'image') {
                                    row.imageUrl = uploadedImg.fireBaseUrl;
                                } else {
                                    row.bioImageUrl = uploadedImg.fireBaseUrl;
                                }
                                newDatasToUpdate.push(row);
                            }
                        })
                    });
                    this.bulkUploadDatasDocs(0, newDatasToUpdate);
                }
            });
        })
    }
    onCellcheckifEditable = (e) => {
        if (e.column.key === 'imageUrl') {
            this.setState({ ...this.state, ...{ activeRowForImageUpload: e.row } })
            this.imageUpload.click(); 
        } else if (e.column.key === 'bioImageUrl') {
            this.setState({ ...this.state, ...{ activeRowForImageUpload: e.row } })
            this.bioImageUpload.click();
        } else {
            return true;
        }
    }
    handleGridSort = async (a ,b ,c) => {
        console.log("GRIDATA:sorting",a, b, c)
    }

    handleGridRowsUpdated = async ({ fromRow, toRow, updated }) => {
        const rows = this.props.listData.slice();
        let row = { ...rows[fromRow], ...updated };
        this.setState({ ...this.state, ...{ updatingTableRow: true } })
        await updateListData(this.props.listId, row.id, row, this.props.isChild);
        this.setState({ ...this.state, ...{ updatingTableRow: false }, exportExcelModal: true })
    };
    bulkUploadDatasDocs = async (count, datas) => {
        // console.log("bulkUploadDatasDocs", datas);
        if (count < datas.length) {
            let row = datas[count];
            await updateListData(this.props.listId, row.id, row);
            this.bulkUploadDatasDocs(count + 1, datas);
        } else {
            this.setState({ updatingMultipleImages: false, updatingMultipleBioImages: false });
            // this.setState({ updatingMultipleBioImages: false });       
        }
    }
    getDerivedStateFromProps = (nextProps, prevState) => {
        console.log("GridDAta: getDerivedStateFromProps", nextProps, prevState)
    }
    render() {
        // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
        // console.log("Griddata state:", this.props)
        return (
        <div>
            <VerticalModal show={this.state?.importingExel || this.state?.updatingMultipleImages || this.state?.updatingMultipleBioImages || this.state?.uploadStatus}
                onHide={this.hideModal} 
                bodyText= {this.state?.importingExel ? 'Importing Data' : 'Uploading Image'}
                bodyText2={!this.state?.uploadStatus ? this.props?.exportExcelCount : ''}
            />
            {
                this.state?.addNewRow ? 
                    <AddEditProfile columns={this.props.dataClmnsWithWidth} listId={this.props.listId} isChild={this.props.isChild} key={`${this.state?.addNewRow}`} /> 
                : ''
            }
            <div className="card mb-5">
                <div className="card-body">
                    <div>
                        <div>
                            <nav className="navbar navbar-light bg-light">
                                <span className="navbar-brand">
                                    {this.props.name}
                                </span>
                                <ul className="nav navbar-nav navbar-right">
                                    <li>
                                        {/* <Button onClick={this.navigateToEditTable}>Edit Table</Button> &nbsp; */}
                                        <Button onClick={this.openProfileModal}>Add Row</Button> &nbsp;
                                        <Button onClick={this.exportClmns}>Export Columns</Button> &nbsp;
                                        <Button onClick={this.exportTable}>Export Table</Button>&nbsp;

                                        <input id="myInput"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            type="file"
                                            ref={(ref) => this.upload = ref}
                                            style={{ display: 'none' }}
                                            onChange={(e) => this.onChangeFile(e)}
                                        />
                                        <>
                                            <input id="imageUpload"
                                                accept="image/*"
                                                type="file"
                                                ref={(ref) => this.imageUpload = ref}
                                                style={{ display: 'none' }}
                                                onChange={(e) => this.handleImageUpload(e, 'image')}
                                            />
                                            <input id="bioImageUpload"
                                                accept="image/*"
                                                type="file"
                                                ref={(ref) => this.bioImageUpload = ref}
                                                style={{ display: 'none' }}
                                                onChange={(e) => this.handleImageUpload(e, 'bioImage')}
                                            />
                                        </>
                                        <>
                                            <input id="multipleImageUpload"
                                                accept="image/*"
                                                type="file"
                                                ref={(ref) => this.multipleImageUpload = ref}
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={(e) => this.handleMultipleImageUpload(e, 'image')}
                                            />
                                            <input id="multipleBioImageUpload"
                                                accept="image/*"
                                                type="file"
                                                ref={(ref) => this.multipleBioImageUpload = ref}
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={(e) => this.handleMultipleImageUpload(e, 'bioImage')}
                                            />                                        </>
                                        {this.state?.importingExel ? <Button>
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            uploading...
                                        </Button> :
                                            <Button onClick={() => { this.upload.click() }}>
                                                Import Data
                                        </Button>
                                        }
                                        &nbsp;
                                        {this.state?.updatingMultipleImages ? <Button>
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            uploading...
                                        </Button> :
                                            <Button onClick={() => { this.multipleImageUpload.click() }}>
                                                Upload Images
                                        </Button>
                                        }
                                        {this.state?.updatingMultipleBioImages ? <Button className="ml-1">
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            uploading...
                                        </Button> :
                                            <Button className="ml-1" onClick={() => { this.multipleBioImageUpload.click() }}>
                                                Upload Bio Images
                                        </Button>
                                        }
                                    </li>

                                </ul>
                            </nav>
                        </div>
                        <div>
                            {this.props?.dataClmnsWithWidth?.length ?
                                <ReactDataGrid
                                    columns={this.props?.dataClmnsWithWidth}
                                    rows={this.props?.listData}
                                    onCheckCellIsEditable={this.onCellcheckifEditable}
                                    onRowsUpdate={this.handleGridRowsUpdated}
                                    onSort={this.handleGridSort}
                                /> : ''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
        );
    }
}

export default GridData;