
import firebase from '../firebase';
import { loadProfile, logout as RxLogout } from './login-rx'
const db = firebase.firestore();

export const login = (username, password) => {
    return new Promise(async (res, reject) => {
        try {
            let data = await firebase.auth().signInWithEmailAndPassword(username, password);
            if (data.user.uid) {
                let snap = await db.collection('users').doc(data.user.uid).get();
                let user = snap.data();
                user.id = snap.id;
                loadProfile(user);
                localStorage.setItem('liu', JSON.stringify(user));
                res({ info: 'logged in success', data: user })
            } else {
                reject({ error: true, info: 'no profile found' })
            }
        } catch (error) {
            console.log(error);
            reject({ error: true, info: error })
        }

    })

}
export const logout = () => {
    localStorage.removeItem('liu');
    firebase.auth().signOut();
    RxLogout();
}