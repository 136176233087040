import React from 'react';
import HomeComponent from './home'


class SubdomainHomeLayOne extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount = () => {
        console.log("$$$$$$$$$$$$$$ SubdomainHomeLayOne $$$$$$$$$$$$$")
        console.log(this.props.data);
    }
    render() {
        return (
            <div>
                <HomeComponent data={this.props.data} ></HomeComponent>
            </div>
        );
    }
}


export default SubdomainHomeLayOne;