
const phil2021Testimonials = [
    {
        "author": "Sumir Chadha (United States)",
        "quote": "The pandemic is a perfect example of how the Indian community has shown up and will continue to show up to help those back at home in the most difficult of circumstances."
    },
    {
        "author": "Rohini Nilekani (India)",
        "quote": "I hope the diaspora will continue to give forward, and even more boldly. It is both inspiring and critical to resolve many old and emerging societal challenges in India."
    },
    {
        "author": "Vijay and Marie Goradia (United States)",
        "quote": "The Indian diaspora has a lot to contribute in the form of 'tan, mann and dhan,' or volunteering time, giving ideas and contributing money."
    },
    {
        "author": "Manu and Rika Shah (United States)",
        "quote": "Playing the role of a catalyst in nurturing leapfrogging ideas, and empowering passionate change-makers. We aim to impact millions of lives, one at a time."
    },
    {
        "author": "Nalini Saligram (United States)",
        "quote": "Leaving the world a better place through giving is, quite simply, our responsibility.  I hope Indiaspora’s Philanthropy Leaders list inspires greater giving from our community."
    },
    {
        "author": "Anand Rajaraman and Kaushie Adiseshan (United States)",
        "quote": "We are delighted to be included on Indiaspora’s Philanthropy Leaders List and we hope our efforts inspire others to contribute to philanthropic causes as well."
    },
    {
        "author": "Kris Gopalakrishnan (India)",
        "quote": "Indiaspora’s Philanthropy Leaders List is a great example of the capacity, reach, and potential of the Indian Diaspora and will inspire more of the diaspora to give back to society wherever they are."
    },
    {
        "author": "Prem Watsa (Canada)",
        "quote": "COVID-19 taught us that businesses, nonprofits, government, and philanthropy all play a critical role in lifting communities during times of crisis. This List serves as a reminder to the diaspora to continue the important work of giving even once the crisis abates."
    },
    {
        "author": "Zarina and Ronnie Screwvala (India)",
        "quote": "The COVID-19 pandemic exacerbated long standing social problems, making it imperative for us to act. While so many stepped forward to offer their time, money, and skills, what is also needed are well thought through interventions that are sustained over time."
    },
]

export default phil2021Testimonials;