import React from "react";

class ImageConatainer extends React.Component {
    
    render() {
        return (
            <div className="container mt-4">
                <div className="row  justify-content-around">
                    <div className="col-9">
                        <figure class="figure">
                            <img src={this.props.imageId} class="figure-img img-fluid " alt="Social Entrepreneur List" />
                        </figure>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageConatainer;