import { store } from '../store';


//enums
const LOAD_MEDIA = 'LOAD_MEDIA'
const LOAD_MORE_MEDIA = 'LOAD_MORE_MEDIA'

//actions creators
let loadMediaAc = (payload) => {
    return {
        type: LOAD_MEDIA,
        payload
    }
}

let loadMoreMediaAc = (payload) => {
    return {
        type: LOAD_MORE_MEDIA,
        payload
    }
}


//actions
export const loadMedia = (payload, loadMoreData) => {
    let state = store.getState();
    let mediaData = [];
    if(state.media?.mediaData?.length > 0)
        mediaData = state.media?.mediaData;
    if(state.application.mode === "admin") {
        mediaData = {mediaData: [...payload]};
    } else {
        mediaData = {mediaData: [...mediaData, ...payload]};
    }
    store.dispatch(loadMediaAc(mediaData))
    store.dispatch(loadMoreMediaAc(loadMoreData))
}



let initialState = []
export const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'REST_TEMP': return { ...initialState }
        case LOAD_MEDIA: return action.payload
        case LOAD_MORE_MEDIA: return {...state, loadMoreMedia: action.payload}
        default: return (() => {
            return state
        })()
    }
}

    