
import firebase from './firebase';
const db = firebase.firestore();

export const updateLSAc = (payload) => {
    return {
        type: 'UPDATE_LS',
        payload: payload
    }
}

export const loadListDetailsDataAc = (payload) => {
    return {
        type: 'LOAD_LIST_DETAILS_DATA',
        payload: payload
    }
}
export const replaceListDetailsData = (payload) => {
    return {
        type: 'REPLACE_LIST_DETAILS_DATA',
        payload: payload
    }
}
export const setLayoutStylesEditable = (payload) => {
    return {
        type: 'SET_EDITABLE',
        payload: payload
    }
}
export const loadAGTableDataAc = () => {
    return {
        type: 'LOAD_AG_TABLE_DATA'
    }
}
export const loadActiveBioAc = (payload) => {
    return {
        type: 'LOAD_ACTIVE_BIO',
        payload: payload
    }
}
export const loadLsToRxAc = () => {
    return {
        type: 'LOAD_LS_TO_RX'
    }
}





const getListData = async (listId, dispatch) => {
    let snap = await db.collection('lists').doc(listId).collection('data').get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    dispatch(loadListDetailsDataAc({ data: data }));

}
const getListDetails = async (listId, dispatch) => {
    let snap = await db.collection('lists').doc(listId).get();
    let obj = snap.data();
    obj.id = snap.id;
    dispatch(loadListDetailsDataAc(obj));
    getListingType(obj, dispatch)
}


const getListingType = async (list, dispatch) => {
    let snap = await db.collection('listingTypes').doc(list.listingTypeId).get();
    let data = snap.data();
    data.id = snap.id;
    dispatch(loadListDetailsDataAc(data));
}


export const getRequiredData = async (listId) => {
    return async function (dispatch) {
        await getListDetails(listId, dispatch);
        await getListData(listId, dispatch);
        dispatch(loadAGTableDataAc());
        dispatch(updateLSAc())
        return 'done';
    }
}












let getProtoFunctions = () => {
    let getStyle = (key, json) => {
        return json && json.homeMeta && json.homeMeta[key] && json.homeMeta[key].style;
    }
    let getValue = (key, json) => {
        return json && json.homeMeta && json.homeMeta[key] && json.homeMeta[key].value;
    }
    return { getStyle, getValue }
}



const initListDetails = {}
export const listDetailsReducer = (state = initListDetails, action) => {
    switch (action.type) {
        case 'LOAD_LIST_DETAILS_DATA': return (() => {
            let newState = { ...state, ...action.payload };
            localStorage.setItem('listDetails', JSON.stringify(newState));
            return newState;
        })()
        case 'REPLACE_LIST_DETAILS_DATA': return action.payload
        case 'SET_EDITABLE': return { ...state, ...action.payload }
        case 'LOAD_ACTIVE_BIO': return (() => {
            let newState = { ...state, ...{ activeBio: action.payload } }
            localStorage.setItem('listDetails', JSON.stringify(newState));
            return newState;
        })();
        case 'LOAD_LS_TO_RX': return (() => {
            let lsSTr = localStorage.getItem('listDetails');
            if (lsSTr && lsSTr.length > 5) {
                let prsLst = JSON.parse(lsSTr)
                return { ...prsLst, ...getProtoFunctions() };
            }
            return state;
        })();
        case 'UPDATE_LS': return (() => {
            localStorage.setItem('listDetails', JSON.stringify(state));
            return state;
        })();
        case 'LOAD_AG_TABLE_DATA': return (() => {
            let columnDefs = []
            state.columns.forEach((cl) => {
                let obj = { headerName: cl.label, field: cl.key };
                if (cl.tableConfig && cl.tableConfig.pinned) { obj.pinned = cl.tableConfig.pinned }
                if (cl.tableConfig && cl.tableConfig.width) { obj.width = cl.tableConfig.width }
                if (cl.tableConfig && cl.tableConfig.sortable) { obj.sortable = cl.tableConfig.sortable }
                if (cl.tableConfig && cl.tableConfig.invisible) {
                    console.log('invisible clmn')
                } else {
                    columnDefs.push(obj);
                }
            })
            return { ...state, ...{ agTableData: { columnDefs: columnDefs, rows: state.data } } }
        })()
        default: return (() => {

            return {
                ...state, ...getProtoFunctions()
            }
        })()
    }
}