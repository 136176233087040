import React from 'react';
import DetailConatiner from "./components/detail/detail-container";


class ProfileLay extends React.PureComponent {
    render() {
        return (
            <div className="mainContainer">
                <DetailConatiner />
            </div>
        );
    }
}


export default ProfileLay;