import React from "react";

class Spacer extends React.PureComponent {
    render() {
        return (
            <div className={`mb-${this.props.val} zero-line-height`}>&nbsp;</div>
        );
    }
}
export default Spacer;
