import React from 'react';
import JurorContainer from './juror/juror-container';
import social2022Jurors from './juror/social-jurors-2022.data';

class SelectionSocialContent extends React.Component {

    componentDidMount = () => {
    }

    render() {
        return (
            <div className="container mt-4 mb-5 color-dark">
                <p>The objective of <a className="text-info" href="https://www.indiaspora.org/">Indiaspora’s</a> DiasporaNEXT Social Entrepreneurship List is to identify and recognise top young social entrepreneurs of Indian origin from all over the world who are approximately between ages 21-40.</p>
                <p>We recognize that this is not an exhaustive list of impactful social entrepreneurs. This list is not intended to be a ranking of these entrepreneurs’ success, but rather a celebration of their work and the impact it has.</p>
                <p>In order to cover all geographies, Indiaspora recruited a jury composed of eminent leaders in the social entrepreneurship ecosystem from all over the world. While we strive to make this list as comprehensive and accurate as possible, there are several challenges around access to information, defining what social entrepreneurship should (and should not) include, etc. that makes this process subjective. <strong>We have included in this list “Individuals who are leading for-profit companies with missions to promote equity and address social issues through their products, services, and business practices.”</strong></p>
                <p>The role of the jury is to provide overall guidance and perspective on Indiaspora’s methodology and approach and recommend names of leading social entrepreneurs from their geographies that should be included on the list. The jury is more of an advisory committee and sounding board and is not ultimately responsible for the accuracy and completeness of the DiasporaNEXT Social Entrepreneurship List.</p>
                <p><strong>We want to thank our jurors, who put effort into helping us make this list as complete and comprehensive as possible:</strong></p>
                <div className="row">
                    <div className="col-12">
                        {social2022Jurors.map(jurors => <JurorContainer jurors={jurors} folder="social2022"/>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default SelectionSocialContent;