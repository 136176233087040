import React from 'react';
import { withRouter } from "react-router-dom";
import SelectionBizContent from './selection-biz-content';
import SelectionGovtContent from './selection-govt-content';
import SelectionPhilContent from './selection-phil-content';
import SelectionSocialContent from './selection-social-content';

class SelectionProcessContent extends React.Component {

    componentDidMount = () => {
        const { params } = this.props.match;
        const { uuid, year } = params;
        const isBizLeaders = uuid === "businessLeaders" && year === "2020"
        const isGovtLeaders = uuid === "governmentLeaders" && year === "2021"
        const isPhilanthropyLeaders = uuid === "philanthropyLeaders" && year === "2021"
        const isSocialEntrepreneur = uuid === "socialEntrepreneurs" && year === "2022"
        if(!isBizLeaders && !isGovtLeaders && !isPhilanthropyLeaders && !isSocialEntrepreneur){
            this.props.history.replace("/");
        }
        this.setState({ ...{ isBizLeaders, isGovtLeaders, isPhilanthropyLeaders, isSocialEntrepreneur } });
    }

    render() {
        return (
            <>
            {
                this.state?.isBizLeaders && <SelectionBizContent />
            }
            {
                this.state?.isGovtLeaders && <SelectionGovtContent />
            }
            {
                this.state?.isPhilanthropyLeaders && <SelectionPhilContent />
            }
            {
                this.state?.isSocialEntrepreneur && <SelectionSocialContent />
            }
            </>

        );
    }

}

export default withRouter(SelectionProcessContent);