import React from 'react';
import { getConsumerHomeData } from "./listdetails/listdetails-fs";
import * as Layouts from './layouts';
import { connect } from 'react-redux';
import { loadActiveCountry } from './listdetails/listdetails-rx';


const mapStateToProps = (state) => {
    return {
        listId: state.application.list?.id,
        activeCountryLayout: state.application.listingType?.activeCountryLayout,
    }
}
class CountryPage extends React.PureComponent {

    renderDynamicComponent = (layout) => {
        let Layout;
        if (layout) {
            Layout = Layouts[layout];
        } else {
            Layout = Layouts['NoLayoutFound']
        }
        return <Layout></Layout>
    }
    componentDidMount = async() => {
        this.uuid = this.props.match.params.uuid;
        this.name = this.props.match.params.name;
        const params = this.props.match.params;
        if(!this.props.listId) {
            await getConsumerHomeData(params.uuid, params.year);
        }
        console.log("Inside country page: ", this.props, this.name)
        if(!this.props.activeCountry || this.props.activeCountry != params.name) {
            // const country = findWhere(this.props.listData, {bioLink: params.name})
            // if(!profile)
            //     this.props.history.push('/');
            // else
            loadActiveCountry(params.name);
        }
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <div>
                {this.renderDynamicComponent(this.props?.activeCountryLayout)}
            </div >
        );
    }
}

export default connect(mapStateToProps)(CountryPage) 