import React from 'react';
import DetailConatiner from "../first-layout/components/countryPage/detail-container"
class CountryLay extends React.PureComponent {
    render() {
        return (
            <div className="mainContainer">
                <DetailConatiner />
            </div>
        );
    }
}

export default CountryLay;