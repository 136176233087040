import React from 'react';



class NoLayoutFound extends React.Component{
    render() {
        return (
             <h1>...</h1>
        );
    }
}
export default NoLayoutFound;