import React from "react";
import { connect } from "react-redux";
import { createSelector } from 'reselect'
import { loadActiveBio } from '../../../../listdetails/listdetails-rx'
import { withRouter } from 'react-router';
import { setFromWhere } from "../../../../redux/templ";
import { selectUUIDAndYear } from "../../../../redux/list-config.selector";

const selectSearchVal = state => state?.tempData?.searchVal;
const selectListData = state => state.application?.listData;

const selectSortedBios = createSelector(
    [selectSearchVal, selectListData],
    (searchVal, listData) => {
        let gridData = searchVal ? searchVal : listData;
        gridData = gridData?.sort((a, b) => {
            if(a.lastname || a.firstname)
                return (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
            if(a.name)
                return (a.name).localeCompare(b.name)
        })
        return gridData;
    }
)

const mapStateToProps = (state) => {
    return {
        gridData: selectSortedBios(state),
        uuidAndYear: selectUUIDAndYear(state),
    }
}
class GridView extends React.Component {
    onCardClick = (profile) => {
        const {uuid, year} = this.props.uuidAndYear;
        loadActiveBio(profile);
        setFromWhere('local');
        let url = `/${uuid}/${year}/bio/${profile.bioLink}`;
        this.props.history.push(url);
    }
    getImgJxs = () => {
        return <img src={this.props?.activeBio?.imageUrl} className="p-2" className="card-img-top" alt="..." />
    }

    getSrcSet = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        if(imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let srcSet = `${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_100x100`)} 100w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 150w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 225w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 768w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`)} 1440w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`)} 2000w`;
        return srcSet;
    }

    getSrc = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        if(imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let src = imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`);
        return src;
    }
    
    render() {
        return (
            <div className="container mb-4 mt-4">
                <div className="row justify-content-center">
                    {this.props.gridData?.map((row, index) => {
                        return <div key={row?.id} className="col-12 col-sm-6 col-lg-3 col-xl-3 card-deck"> <div className="card hand-cursor text-center border-0 mb-3" onClick={() => this.onCardClick(row)}>
                            <div className="img-container display-bio-img-container relative justify-content-center align-items-center">
                                <img src={this.getSrc(row?.imageUrl, row?.bioLink)} srcSet={this.getSrcSet(row?.imageUrl, row?.bioLink)}
                                className="p-2" className="card-img-top" alt="..." />
                                <div className="vew-bio">View Bio</div>
                            </div>
                            <div className="card-body">
                                <h5 className="card-title mb-0">{row.firstname} {row.lastname}</h5>
                                <p className="card-text text-secondary mb-0">{row.title}</p>
                                <h5 className="card-text text-secondary mb-0">{row.country}</h5>
                                <h5 className="card-text"><small className="grid-company">{row.company}</small></h5>
                            </div>
                        </div></div>
                    })}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(GridView));