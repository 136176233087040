import React from 'react';

import Navbar from '../first-layout/components/navbar';
import SubNavbar from '../first-layout/components/sub-navbar';
import Footer from '../first-layout/components/footer'
import SelectionProcessContent from './selection-process-content'

class SelectionProcessLayout extends React.Component {
    render() {
        return (
            <div className="mainContainer">
                <Navbar />
                <SubNavbar></SubNavbar>
                <SelectionProcessContent />
                <Footer />
            </div>
        );
    }
}

export default SelectionProcessLayout;