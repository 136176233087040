import React from 'react';
import HomeComponent from '../first-layout/home'


class HomeLayOne extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount = () => {
        console.log(this.props.data);
    }
    render() {
        return (
            <div>
                <HomeComponent data={this.props.data} ></HomeComponent>
            </div>
        );
    }
}


export default HomeLayOne;