import firebase from "../../firebase";

export const deleteImage = async(imageName, imageLocation) => {
        let storage = firebase.storage();
        var removeRef = storage.ref('/' + imageLocation + '/').child(imageName);
        return removeRef.delete().then(() => {
            return 'done';
        }).catch((error) => {
            console.log('// File Not deleted. Any Problem.');
            return error;
        });
}
