import firebase from '../firebase';
import { loadMedia } from './media-rx';
import { store } from "../store";

const state = store.getState();
const db = firebase.firestore();
const PAGE_SIZE = 50;

export const getMedia = async (listId, moreMedia, applicationMode, mediaCount) => {
    if (!moreMedia) {   // For first time media fetch
        try {
            let snap;
            let data = [];
            if(applicationMode === 'admin') {
                snap = await db.collection('lists').doc(listId).collection('media').orderBy('timestamp', 'desc').get();
            } else {
                snap = await db.collection('lists').doc(listId).collection('media').where("active", "==", "true").orderBy('timestamp', 'desc').limit(PAGE_SIZE).get();
            }
            snap.forEach((inQryDoc) => {
                let dwithId = inQryDoc.data();
                dwithId.id = inQryDoc.id;
                data.push(dwithId);
            })
            if(applicationMode === 'admin') {
                loadMedia(data, false);
            } else {
                loadMedia(data, true);
            }                                   
        } catch (error) {
            console.log(error);
            return error
        }
    } else {    // For fetching more media.
        try {
            let first = db.collection('lists').doc(listId)
                .collection('media')
                .where("active", "==", "true")
                .orderBy('timestamp', 'desc')
                .limit(mediaCount);
            
            first.get()
                .then(async (snapshot) => {
                    // Get the last document
                    let last = snapshot.docs[snapshot.docs.length - 1];
                    // Construct a new query starting at this document.
                    let next = await db.collection('lists').doc(listId)
                        .collection('media')
                        .orderBy('timestamp', 'desc')
                        .startAfter(last)
                        .where("active", "==", "true")
                        .limit(PAGE_SIZE)
                        .get();
                    // Use the query for pagination
                    let data = [];
                    next.forEach((inQryDoc) => {
                        let dwithId = inQryDoc.data();
                        dwithId.id = inQryDoc.id;
                        data.push(dwithId);
                    })
                    if(data?.length > 0) {
                        loadMedia(data, true)
                    } else {
                        loadMedia(data, false)
                    }
                });
        } catch (error) {
            console.log(error);
            return error
        }

    }
}
export const addMedia = async (listId, payload) => {
    let res = await db.collection('lists').doc(listId).collection('media').add(payload);
    getMedia(listId, false, 'admin');
    return res;
}
export const updateMedia = async (listId, mediaId, data) => {
    let snap = await db.collection('lists').doc(listId).collection('media').doc(mediaId).update(data)
    getMedia(listId, false, 'admin');
    return snap;
}
export const deleteMedia = async (listId, mediaId) => {
    let snap = await db.collection('lists').doc(listId).collection('media').doc(mediaId).delete()
    getMedia(listId, false, 'admin');
    return snap;
}

