import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { loadActiveBio } from '../../../../listdetails/listdetails-rx';
import { sortedAgTableData } from "../../../../redux/application";
import { setFromWhere } from "../../../../redux/templ";
import { selectUUIDAndYear, selectCache } from "../../../../redux/list-config.selector";
import { isMobile } from "react-device-detect";

const mapStateToProps = (state) => {
  return {
    uuidAndYear: selectUUIDAndYear(state),
    listData: state.application.listData,
    cache: selectCache(state),
    agTableData: state.application.listingType?.agTableData,
    searchVal: state.tempData.searchVal,
    searchItem: state.tempData.searchItem,
    searchCategoryItem: state.tempData.searchCategoryItem
  }
}
class NewTable extends Component {
  onRowClicked = (e) => {
    const {uuid, year} = this.props.uuidAndYear;
    loadActiveBio(e.data);
    setFromWhere('local');
    let url = `/${uuid}/${year}/bio/${e.data.bioLink}`;
    this.props.history.push(url);
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    if (this.props.searchVal) {
      this.gridApi.setQuickFilter(this.props?.searchItem);
      this.externalFilterChanged();
    }
  }
  gridChanged = (params) => {
    if(isMobile || this.props?.match?.params?.uuid === "businessLeaders")
      return;
    var gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    console.log(gridWidth);
    var columnsToShow = [];
    var columnsToHide = [];
    var totalColsWidth = 0;
    var allColumns = params.columnApi.getAllColumns();
    for (var i = 0; i < allColumns.length; i++) {
      var column = allColumns[i];
      // totalColsWidth += column.actualWidth;
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.searchVal !== this.props.searchVal) {
      if (this.gridApi) {
        this.gridApi.setQuickFilter(this.props?.searchItem);
        this.externalFilterChanged();
      }
    }
  }
  externalFilterChanged = () => {
    this.gridApi.onFilterChanged();
  };

  sortChanged = (e) => {
    let sortModel = e.api.getSortModel();
    let sortBy = sortModel[0];
    sortedAgTableData(sortBy);
  }

  isExternalFilterPresent = () => {
    if (this.props.searchCategoryItem) {
      return true;
    }
  }

  doesExternalFilterPass = (node) => {
    const column = this.props?.cache?.categoryColumn;
    if (node.data[column] === this.props.searchCategoryItem) {
      return true;
    } else {
      return false;
    }
  }
  getStyle = () => {
    const isGovt = this.props?.match?.params?.uuid === "governmentLeaders";
    let width = "85%";
    const windowWidth = window.innerWidth;
    if(isGovt ){
      if (windowWidth > 2100 )
        width = "55%";
      else if (windowWidth > 1800 )
        width = "65%";
      else if (windowWidth >  1400)
        width = "75%";
    }
    if(isMobile){
      width = "90%";
    }
    return {
      minHeight: '99vh',
      width: width
    }
  }

  render() {
    return (
      <div className="row justify-content-center">
        <div
          id="grid-wrapper"
          className="ag-theme-alpine"
          style={this.getStyle()}
        >
          <AgGridReact
            columnDefs={this.props?.agTableData?.columnDefs}
            rowData={this.props?.listData}
            onRowClicked={this.onRowClicked}
            onGridReady={this.onGridReady}
            animateRows={true}
            onGridSizeChanged={this.gridChanged}
            onSortChanged={this.sortChanged}
            isExternalFilterPresent={this.isExternalFilterPresent}
            doesExternalFilterPass={this.doesExternalFilterPass}
          />
        </div>
        {this.props?.match?.params?.uuid === "businessLeaders" && <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <p className="small mb-0 pl-1">Note: Raj Gupta appears twice on this list as the Chairman of two different companies.</p>
          <p className="small mb-0 pl-1">* This is actually the revenue for Hyster-Yale Materials Handling, Inc. Hyster-Yale Group is HYMH’s operating company.</p>
        </div>}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(NewTable));