import React from 'react';
import * as Layouts from './layouts';

import { setLayoutStylesEditable } from './list-details-page-op';
import { store } from './store';
import { loadAppBar } from './redux/application';
import { getData } from './listdetails/listdetails-fs';


class LayoutStylesEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    renderDynamicComponent = (layoutName) => {
        let Layout = Layouts['NoLayoutFound']
        if (layoutName) {
            Layout = Layouts[layoutName];
        }
        return <Layout></Layout>
    }
    componentDidMount = async () => {
        // if (this.props?.location?.state?.data?.layout?.name) {
        //     this.layoutName = this.props.location.state.data.layout.name;
        //     loadAppBar('invisible');
        //     this.setState({ ...this.state, ...{ layoutName: this.layoutName } })
        //     window.scrollTo(0, 0);
        // } else {
        //     this.listId = this.props.match.params.listId;
        //     this.listingTypeId = this.props.match.params.listingTypeId;
        //     this.subdomain = this.props.match.params.subdomain;
        //     this.layoutName = this.props.match.params.layoutName;
        //     await getData(this.listId, this.listingTypeId);
        //     loadAppBar('invisible');
        //     window.scrollTo(0, 0);
        //     this.setState({ ...this.state, ...{ layoutName: this.layoutName } })
        // }
        loadAppBar('invisible');
        this.listId = this.props.match.params.listId;
        this.listingTypeId = this.props.match.params.listingTypeId;
        this.subdomain = this.props.match.params.subdomain;
        this.layoutName = this.props.match.params.layoutName;
        await getData(this.listId, this.listingTypeId);
        window.scrollTo(0, 0);
        this.setState({ ...this.state, ...{ layoutName: this.layoutName } })
        store.dispatch(setLayoutStylesEditable({ editable: true }));
    }

    componentWillUnmount = () => {
        store.dispatch(setLayoutStylesEditable({ editable: false }))
        loadAppBar('visible');
    }
    render() {
        return (
            <div>
                {this.state?.layoutName ? this.renderDynamicComponent(this.state.layoutName) : ''}
            </div>
        );
    }
}


export default LayoutStylesEditor;
