import React from 'react';
import './scss/login.scss';
import './scss/App.scss';
import './scss/actorDetails.scss';
import './scss/card.scss';
import './scss/common.scss';
import './scss/social-icons.scss';
import { loadLsToRx, loadApplicationMode, setAppCache, resetApp } from './redux/application'
import firebase from './firebase';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import PageZero from './page-zero';
import PageOne from './page-one';
import PageTwo from './page-two';
import PageThree from './page-three';
import PageFour from './page-four';
import PageFive from './page-five';
import PageNews from './page-news';
import CountryPage from './country-page';
import { store } from './store';
import { getConsumerHomeData } from './listdetails/listdetails-fs';
import { resetTemp } from './redux/templ';
import { isMobile } from "react-device-detect";

class App extends React.PureComponent {
    
    componentDidMount = () => {
        loadLsToRx();
        loadApplicationMode('consumer')
    }
    render() {
        return (
            <Router>
                <Route path="/" render={({location}) => {
                    const analytics = firebase.analytics();
                    analytics.logEvent('screen_view', {screen_name: location.pathname});
                    return null;
                }} />
                <Route path="/:uuid/:year" render={({match}) => {
                    const state = store.getState();
                    const {params} = match;
                    let needToFetchConsumerData = true;
                    let needToSetAppCache = true;
                    if(state?.application?.cache){
                        const {uuid, year} = state.application.cache;
                        const urlUUID = params.uuid;
                        const urlYear = params.year;
                        if(uuid === urlUUID && year === urlYear){
                            needToFetchConsumerData = false;
                            needToSetAppCache = false;
                        }
                    }
                    if(needToSetAppCache) {
                        resetTemp();
                        resetApp({
                            listingTypes: state.application?.listingTypes,
                            mode: 'consumer',
                        });
                        const {uuid, year} = params;
                        setAppCache({uuid, year, isMobile})
                    }
                    if(needToFetchConsumerData) {
                        getConsumerHomeData(params.uuid, params.year);
                    }
                    return null;
                }} />
                <Switch>
                    <Route exact path="/:uuid/:year/news/" component={PageNews}></Route>
                    <Route exact path="/:uuid/:year/news/media" component={PageNews}></Route>
                    <Route exact path="/:uuid/:year/news/quotes" component={PageNews}></Route>
                    <Route exact path="/:uuid/:year/news/quotes/:quoteId" component={PageNews}></Route>
                    <Route exact path="/:uuid/:year/bio/:name" component={PageTwo}></Route>
                    <Route exact path="/:uuid/:year/country/:name" component={CountryPage}></Route>
                    <Route exact path="/:uuid/:year/about" component={PageThree}></Route>
                    <Route exact path="/:uuid/:year/selectionProcess" component={PageFour}></Route>
                    <Route exact path="/:uuid/:year/contact" component={PageFive}></Route>
                    <Route exact path="/:uuid/:year" component={PageOne}></Route>
                    <Route path="/" component={PageZero}></Route>
                </Switch>
            </Router>
        );
    }
}

export default App;
