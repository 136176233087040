import React from 'react';
import SocialIcon from "./social-icons";
import { Modal } from 'react-bootstrap'
import SEButton from '../../../common/styles-editor/SE-button'
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
    return {
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: state.application.mode,
        listMetaData: state.application.listMetaData
    }
}
const HTML_SIGN_UP = `

<!-- Begin Mailchimp Signup Form -->
<link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css">
<style type="text/css">
#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
  We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://indiaspora.us7.list-manage.com/subscribe/post?u=29f15869f196acebd8006a7aa&amp;id=45efd826ac" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
    <div id="mc_embed_signup_scroll">
    <!-- <h2>Subscribe</h2>-->
<div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
<div class="mc-field-group">
<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span>
</label>
<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL">
</div>
<div class="mc-field-group">
<label for="mce-FNAME">First Name </label>
<input type="text" value="" name="FNAME" class="" id="mce-FNAME">
</div>
<div class="mc-field-group">
<label for="mce-LNAME">Last Name </label>
<input type="text" value="" name="LNAME" class="" id="mce-LNAME">
</div>
<div id="mce-responses" class="clear">
<div class="response" id="mce-error-response" style="display:none"></div>
<div class="response" id="mce-success-response" style="display:none"></div>
</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_29f15869f196acebd8006a7aa_45efd826ac" tabindex="-1" value=""></div>
    <div class="clear text-center"><input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="btn btn-primary"></div>
    </div>
</form>
</div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
<!--End mc_embed_signup-->


`
const SOCIAL_DATA = {
    twitter: 'https://twitter.com/IndiasporaForum',
    facebook: 'https://www.facebook.com/IndiasporaForum',
    instagram: 'https://www.instagram.com/indiaspora/',
    linkedIn: 'https://www.linkedin.com/company/indiaspora/',
    youtube: 'https://www.youtube.com/c/indiaspora',
}
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    openDialog = () => {
        this.setState({ ...this.state, ...{ show: true } })
    }
    handleClose = () => {
        this.setState({ ...this.state, ...{ show: false } })
    }
    getModel = () => {
        return <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Subscribe</Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: HTML_SIGN_UP }}>
            </Modal.Body>
        </Modal>
    }

    render() {
        return (
            <>
                {this.getModel()}
                < footer className="justify-content-center mt-auto container-fluid bg-light   pt-5 pb-5" >
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 text-center">
                            <h5 className="text-secondary mb-3 font-weight-bolder">
                                <span style={this.props.homeLayUtils.getStyle('footerHead:css', this.props.listMetaData, this.props.applicationMode)}>
                                    {this.props.homeLayUtils.getValue('footerHead:css', this.props.listMetaData, this.props.applicationMode)}
                                </span>
                                <SEButton sekey={"footerHead:css"} type={"text"}></SEButton>
                            </h5>
                            <button type="button" className="btn btn signUp mb-3" 
                                style={this.props.homeLayUtils.getStyle('footerSignUp:css', this.props.listMetaData, this.props.applicationMode)}
                                onClick={this.openDialog}>Sign up for our newsletter</button>
                            <SEButton sekey={"footerSignUp:css"} type={"textCB"}></SEButton>
                            <SocialIcon data={SOCIAL_DATA} owner={'HomePage'}/>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center bg-transparent">
                        <p className="text-secondary mb-0 mt-3">© 2021 Indiaspora, a 501(c)(3) nonprofit</p>
                    </div>
                </footer >
            </>
        );
    }
}

export default connect(mapStateToProps)(Footer)