import firebase from '../firebase';
import { loadQuotes } from './quotes-rx';
import { store } from "../store";

const state = store.getState();
const appMode = state?.application?.mode;
const db = firebase.firestore();
const PAGE_SIZE = 50;

export const getQuotes = async (listId, moreQuotes, applicationMode, newsCount) => {
    if (!moreQuotes) {   // For first time news fetch.
        try {
            let snap;
            let data = [];
            if(applicationMode === 'consumer') {
                snap = await db.collection('lists').doc(listId).collection('quotes').orderBy('timestamp').limit(PAGE_SIZE).get();
            } else {
                snap = await db.collection('lists').doc(listId).collection('quotes').orderBy('timestamp').get();
            }
            snap.forEach((inQryDoc) => {
                let dwithId = inQryDoc.data();
                dwithId.id = inQryDoc.id;
                data.push(dwithId);
            })
            if(applicationMode === 'consumer') {
                loadQuotes(data, true);
            } else {
                loadQuotes(data, false);
            }

        } catch (error) {
            console.log(error);
            return error
        }
    } else {    // For fetching more news.
        try {
            let first = db.collection('lists').doc(listId)
                .collection('quotes')
                .orderBy('timestamp')
                .limit(newsCount);

            first.get()
                .then(async (snapshot) => {
                    // Get the last document
                    let last = snapshot.docs[snapshot.docs.length - 1];
                    // Construct a new query starting at this document.
                    let next = await db.collection('lists').doc(listId)
                        .collection('quotes')
                        .orderBy('timestamp')
                        .startAfter(last)
                        .limit(PAGE_SIZE)
                        .get();
                    // Use the query for pagination
                    let data = [];
                    next.forEach((inQryDoc) => {
                        let dwithId = inQryDoc.data();
                        dwithId.id = inQryDoc.id;
                        data.push(dwithId);
                    })
                    if (data?.length > 0) {
                        loadQuotes(data, true)
                    } else {
                        loadQuotes(data, false)
                    }
                });
        } catch (error) {
            console.log(error);
            return error
        }
    }
}

export const addQuotes = async (listId, data) => {
    let res = await db.collection('lists').doc(listId).collection('quotes').add(data);
    getQuotes(listId, false);
    return res;
}
export const updateQuote = async (listId, quoteId, data) => {
    let snap = await db.collection('lists').doc(listId).collection('quotes').doc(quoteId).update(data)
    getQuotes(listId, false);
    return snap;
}
export const deleteQuote = async (listId, quoteId) => {
    let snap = await db.collection('lists').doc(listId).collection('quotes').doc(quoteId).delete()
    getQuotes(listId, false);
    return snap;
}
