import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppAdmin from './app/app-admin';
import AppConsumer from './app/app-consumer';
import * as serviceWorker from './serviceWorker';
import { store } from './app/store';
import { Provider } from 'react-redux';
import "core-js/stable";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <AppConsumer /> */}
      <AppConsumer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
