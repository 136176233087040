import React from 'react';
import { connect } from 'react-redux';
import { getNews } from './news/news-fs';
import { MdLaunch } from 'react-icons/md';
import InfiniteScroll from 'react-infinite-scroller';

const mapStateToProps = (state) => {
    return {
        news: state?.news?.newsData,
        loadMoreNews: state?.news?.loadMoreNews,
        list: state?.application?.list,
        application: state?.application?.mode
    }
}
class News extends React.Component {

    componentDidMount = () => {
        if(!this.props?.news?.length) {
            if(this.props.application === 'consumer')  {
                getNews(this.props.list?.id, false, 'consumer');
            } else {
                this.listId = this.props?.match?.params?.listId;
                getNews(this.listId, false, 'admin');
            }
        }
    }

    goToNews = (url) => {
        window.open(url, '_blank');
    }

    getDate = (date) => {
        let d = new Date(date);
        const month = d.toLocaleString('default', { month: 'long' });
        let newDateFormat = `${month}   ${d.getDate()}, ${d.getFullYear()}`;
        return newDateFormat;
    }

    newsCards = () => {
        let cards = this.props?.news?.map((row, index) => {
            return (
                <div className="row justify-content-center" key={index}>
                    <div className="col-12 mb-5" onClick={() => this.goToNews(row.newsUrl)} style={{ cursor: 'pointer' }}> <div className="card border-0 mb-3" key={index}>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="card-body pl-0 pr-0 pt-3">
                                    <p className="card-text text-primary font-weight-bolder mb-1">{row.title}</p>
                                    <h6 className="card-title   font-weight-bolder">{row.subtitle}</h6>
                                    <p className="text-muted">{this.getDate(row?.timestamp)}</p>
                                    <a href="https://www.indiaspora.org/category/press-release/" target="_blank" rel="noopener noreferrer">
                                        <h6 className="text-muted">Read More <MdLaunch size={16} className="news-launch" /></h6>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="display-newsimg-container">
                                    <img src={row.imageUrl} className="p-2" className="card-img-top" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            )
        })
        return cards;
    }

    loadMore = () => {
        getNews(this.props?.list?.id, true, 'consumer', this.props.news?.length);
    }

    render() {
        return (
            <div>
                <div className="container mb-4 mt-4">
                    <div className="row justify-content-center">
                        {this.props?.news?.map((row, index) => {
                            return row.primary === 'true' ? <div onClick={() => this.goToNews(row.newsUrl)} key={row?.id} className="col-12 col-sm-6 col-lg-3 col-xl-3 card-deck" style={{ cursor: 'pointer' }}> 
                            <div className="card border-0 mb-3">
                                <div className="display-newsimg-container">
                                    <img src={row.imageUrl} className="p-2" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body pl-0 pr-0 pt-3">
                                    <p className="card-text text-primary font-weight-bolder mb-1 ln-ht-small">{row.title}</p>
                                    <h6 className="card-title mb-0  font-weight-bolder">{row.subtitle}</h6>
                                </div>
                            </div></div> : ''
                        })}
                    </div>
                </div>
                <div className="container mb-4 mt-4">
                    <h2 className="border-bottom font-weight-bolder pb-3 pt-3 mb-5">Recent News</h2>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadMore}
                        hasMore={this.props?.loadMoreNews}
                        loader={this.props?.loadMoreNews &&
                            <p className="loader text-lowercase" key={Math.random()}>Loading ...</p>
                        }>
                        <div>
                            {this.newsCards()}
                        </div>
                    </InfiniteScroll>
                    <p hidden={this.props?.loadMoreNews}>No more news.</p>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(News);