import React from "react";
import DetailBody from "./detail-body"
import Sidenave from "./sidenave"
import Navbar from "../navbar"

class DetailContainer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            show: true
        }
    }

    render() {
        return (
            <div className="actor-detail-container  mt-3 mt-sm-2 mt-md-2 mt-lg-0 mt-xl-0">
                <Navbar showBack="true" isBio="true"/>
                <Sidenave />
                <DetailBody />
            </div>

        );
    }
}

export default DetailContainer;