import React from 'react';
import * as Layouts from './layouts';
import { connect } from 'react-redux';
import { loadActiveBio } from './listdetails/listdetails-rx';
import { findWhere } from 'underscore';
import { selectLoadingConsumer, selectActiveBioQuote } from './redux/list-config.selector';
import { BounceLoader } from 'react-spinners';
import { getQuoteForBio, removeBioQuote } from './listdetails/listdetails-fs';

const mapStateToProps = (state) => {
    return {
        listData: state.application.listData,
        listId: state.application.list?.id,
        activeListingLayout: state.application.listingType?.activeListingLayout,
        activeBio: state.application.activeBio,
        loading: selectLoadingConsumer(state),
        activeBioQuote: selectActiveBioQuote(state),
    }
}

const loaderCss = `
    display: block;
    margin: 0 auto;
    border-color: red;
`
class PageTwo extends React.PureComponent {

    renderDynamicComponent = (layout) => {
        let Layout;
        if (layout) {
            Layout = Layouts[layout];
        } else {
            Layout = Layouts['NoLayoutFound']
        }
        return <Layout></Layout>
    }
    componentDidMount = async() => {
        this.uuid = this.props.match.params.uuid;
        this.name = this.props.match.params.name;
        const params = this.props.match.params;
        removeBioQuote();
        if(!this.props.activeBio || this.props.activeBio.bioLink != params.name) {
            loadActiveBio(params.name);
        }
        window.scrollTo(0, 0)
    }
    componentDidUpdate = async () => {
        const params = this.props.match.params;
        if(this.props.listData && (!this.props.activeBio || this.props.activeBio.bioLink != params.name)) {
            const profile = findWhere(this.props.listData, {bioLink: params.name})
            if(!profile)
                this.props.history.push('/');
            else{
                loadActiveBio(profile);
                removeBioQuote()
            }
        }
        if(this.props?.listId && !this.props?.activeBioQuote)
            getQuoteForBio(this.props.listId, params.name)
    }
    render() {
        return (
            <div>
                {}
                {!this.props.loading && this.renderDynamicComponent(this.props?.activeListingLayout)}
                {this.props.loading && <div className="loader-container">
                    <BounceLoader
                        css={loaderCss}
                        size={150}
                        color={"#f8b13e"}
                        loading={this.props.loading}
                    />
                </div>}
            </div >
        );
    }
}

export default connect(mapStateToProps)(PageTwo) 