import React from "react";

class VideoConatainer extends React.Component {
    
    render() {
        const youtubeSrc = `https://www.youtube.com/embed/${this.props.videoId}?rel=0`;
        return (
            <div className="container mt-4">
                <div className="row  justify-content-around">
                    <div className="col-9">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" src={youtubeSrc} title={Math.random()}
                                allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VideoConatainer;