import React from 'react';
import { getConsumerHomeData } from "./listdetails/listdetails-fs";
import * as Layouts from './layouts';
import { connect } from 'react-redux';
import { setSubNavActiveLink } from './redux/templ'

const mapStateToProps = (state) => {
    return {
        activeSelectionProcessLayout: state.application.listingType?.activeSelectionProcessLayout,
        listId: state.application.list?.id
    }
}
class PageFour extends React.Component {

    renderDynamicComponent = (layout) => {
        let Layout;
        if (layout) {
            Layout = Layouts[layout];
        } else {
            Layout = Layouts['NoLayoutFound']
        }
        return <Layout></Layout>
    }
    componentDidMount = async () => {
        const params = this.props.match.params;
        if(!this.props.listId) {
            await getConsumerHomeData(params.uuid, params.year);
        }
        setSubNavActiveLink('selectionProcess')
    }
    render() {
        return (
            <div>
                {this.props?.activeSelectionProcessLayout ? this.renderDynamicComponent(this.props?.activeSelectionProcessLayout) : ''}
            </div>
        );
    }
}

export default connect(mapStateToProps)(PageFour) 