import React from "react";
import { connect } from "react-redux";
import { loadActiveBio } from '../../../../listdetails/listdetails-rx'
import { withRouter } from 'react-router';
import { setFromWhere } from "../../../../redux/templ";
import { selectUUIDAndYear } from "../../../../redux/list-config.selector";

const mapStateToProps = (state) => {
    return {
        uuidAndYear: selectUUIDAndYear(state),
    }
}
class CategoryGridView extends React.Component {
    onCardClick = (profile) => {
        const { uuid, year } = this.props.uuidAndYear;
        loadActiveBio(profile);
        setFromWhere('local');
        let url = `/${uuid}/${year}/bio/${profile.bioLink}`;
        this.props.history.push(url);
    }
    getImgJxs = () => {
        return <img src={this.props?.activeBio?.imageUrl} className="p-2" className="card-img-top" alt="..." />
    }

    getSrcSet = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        console.log(indexOfToken);
        if(imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let srcSet = `${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_100x100`)} 100w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 150w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 225w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_150x150`)} 768w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`)} 1440w,
                      ${imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`)} 2000w`;
        return srcSet;
    }

    getSrc = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        console.log(indexOfToken);
        if(imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let src = imageUrl?.replace(`/images%2F${bioLink}`, `/images%2Fthumbnails%2F${bioLink}_225x225`);
        return src;
    }
    getSubCategoryJSX = (subCategoryRow) => {
        return subCategoryRow.data.map((row) => {
            return <div class="col-12 col-sm-6 col-lg-3 col-xl-3 card-deck">
                <div className="card hand-cursor text-center border-0 mb-3" onClick={() => this.onCardClick(row)}>
                    <div className="img-container display-bio-img-container relative justify-content-center align-items-center">
                        <img src={this.getSrc(row?.imageUrl, row?.bioLink)} srcSet={this.getSrcSet(row?.imageUrl, row?.bioLink)}
                            className="p-2" className="card-img-top" alt="..." />
                        <div className="vew-bio">View Bio</div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title mb-0 black-font">{row.firstname} {row.lastname}</h5>
                        <p className="card-text mb-0 text-secondary">{row.title}</p>
                        <h5 className="card-text"><small className="grid-company">{row.country}</small></h5>
                    </div>
                </div>
            </div>
        })
    }
    render() {
        return (
            <div className="container mb-5 mt-5">
                <div className="card  lst-descritption  list-dsc-img-container border-0">
                    <div className="p-3 pl-4 pr-4  pl-sm-4 pr-sm-4 pl-md-4 pr-md-4   pl-lg-5 pr-lg-5  pl-xl-5 pr-xl-5 ">
                        <h2 className="text-center black-font  mb-4 d-none d-sm-none d-lg-block d-xl-block" >
                            {this.props.gridData?.name}
                        </h2>
                        <h4 className="text-center text-secondary mb-4 d-lg-none d-block">
                            {this.props.gridData?.name}
                        </h4>
                        <p className="text-center text-secondary mb-4">
                            {this.props.gridData?.description}
                        </p>
                        {/* <h4 className="text-center text-secondary mb-4 d-none d-sm-none d-lg-block d-xl-block" >
                            {"Subcategory name"}
                        </h4>
                        <h6 className="text-center text-secondary mb-4 d-lg-none d-block">
                            {"Subcategory name"}
                        </h6> */}
                    </div>
                </div>
                {this.props.gridData?.dataWithSubCategories?.map((subCategoryRow) => {
                    return (
                        <div key={subCategoryRow?.subCategory} className="row justify-content-center">
                            <div class="col-12 text-center mb-3">
                                <h4 className="text-center text-secondary mb-4 d-none d-sm-none d-lg-block d-xl-block" >
                                    {subCategoryRow.subCategory}
                                </h4>
                                <h6 className="text-center text-secondary mb-4 d-lg-none d-block">
                                    {subCategoryRow.subCategory}
                                </h6>
                            </div>
                            { this.getSubCategoryJSX(subCategoryRow)}
                        </div>)
                })}
                {/* DONT DELETE THIS - START
                    <div className="row justify-content-center">
                    {this.props.gridData?.data?.map((row, index) => {
                        return <div key={row?.id} className="col-12 col-sm-6 col-lg-3 col-xl-3 card-deck">
                            <div className="card hand-cursor text-center border-0 mb-3" onClick={() => this.onCardClick(row)}>
                                <div className="img-container display-bio-img-container relative justify-content-center align-items-center">
                                    <img src={this.getSrc(row?.imageUrl, row?.bioLink)}
                                        className="p-2" className="card-img-top" alt="..." />
                                    <div className="vew-bio">View Bio</div>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title mb-0">{row.firstname} {row.lastname}</h5>
                                    <p className="card-text mb-0 text-secondary text-capitalize">{row.title}</p>
                                    <h5 className="card-text"><small className="grid-company">{row.country}</small></h5>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                        DONT DELETE THIS - END
                */}
                {/* <div className="card  lst-descritption  list-dsc-img-container border-0">
                    <div className="p-3 pl-4 pr-4  pl-sm-4 pr-sm-4 pl-md-4 pr-md-4   pl-lg-5 pr-lg-5  pl-xl-5 pr-xl-5 ">
                        <h4 className="text-center text-secondary mb-4 d-none d-sm-none d-lg-block d-xl-block" >
                            {"Subcategory name"}
                        </h4>
                        <h6 className="text-center text-secondary mb-4 d-lg-none d-block">
                            {"Subcategory name"}
                        </h6>
                    </div>
                </div> */}
                {/* <div className="row justify-content-center">
                    {this.props.gridData?.data.map((row, index) => {
                        return <div key={row?.id} className="col-12 col-sm-6 col-lg-3 col-xl-3 card-deck">
                            <div className="card hand-cursor text-center border-0 mb-3" onClick={() => this.onCardClick(row)}>
                                <div className="img-container display-bio-img-container relative justify-content-center align-items-center">
                                    <img src={this.getSrc(row?.imageUrl, row?.bioLink)}
                                        className="p-2" className="card-img-top" alt="..." />
                                    <div className="vew-bio">View Bio</div>
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title mb-0">{row.firstname} {row.lastname}</h5>
                                    <p className="card-text mb-0 text-secondary text-capitalize">{row.title}</p>
                                    <h5 className="card-text"><small className="grid-company">{row.country}</small></h5>
                                </div>
                            </div>
                        </div>
                    })}
                </div> */}
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(CategoryGridView));