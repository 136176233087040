import React from "react";
import { FiLinkedin, FiFacebook, FiTwitter, FiYoutube } from "react-icons/fi";
import { RiInstagramLine } from "react-icons/ri"
import { connect } from "react-redux";
import SEButton from '../../../common/styles-editor/SE-button'

const mapStateToProps = (state) => {
    return {
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: state.application.mode,
        listMetaData: state.application.listMetaData
    }
}

class SocialIcon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-center row justify-content-center">
                    {
                        this.props.data?.twitter ?
                            <a href={this.props.data?.twitter} target="_blank" className="social-fo-color"
                                style={this.props.homeLayUtils.getStyle(`iconSocial${this.props.owner}:css`, this.props.listMetaData, this.props.applicationMode)}>
                                <FiTwitter className="social-icon ml-2 mr-2" />
                            </a>
                            : ''
                    }
                    {
                        this.props.data?.facebook ?
                            <a href={this.props.data?.facebook} target="_blank" className="social-fo-color"
                                style={this.props.homeLayUtils.getStyle(`iconSocial${this.props.owner}:css`, this.props.listMetaData, this.props.applicationMode)}>
                                <FiFacebook className="social-icon  ml-2 mr-2" />
                            </a>
                            : ''
                    }
                    {
                        this.props.data?.instagram ?
                            <a href={this.props.data?.instagram} target="_blank" className="social-fo-color"
                                style={this.props.homeLayUtils.getStyle(`iconSocial${this.props.owner}:css`, this.props.listMetaData, this.props.applicationMode)}>
                                <RiInstagramLine className="social-icon  ml-2 mr-2" />
                            </a>
                            : ''
                    }
                    {
                        this.props.data?.linkedIn ?
                            <a href={this.props.data?.linkedIn} target="_blank" className="social-fo-color"
                                style={this.props.homeLayUtils.getStyle(`iconSocial${this.props.owner}:css`, this.props.listMetaData, this.props.applicationMode)}>
                                <FiLinkedin className="social-icon  ml-2 mr-2" />
                            </a>
                            : ''
                    }
                    {
                        this.props.data?.youtube ?
                            <a href={this.props.data?.youtube} target="_blank" className="social-fo-color"
                                style={this.props.homeLayUtils.getStyle(`iconSocial${this.props.owner}:css`, this.props.listMetaData, this.props.applicationMode)}>
                                <FiYoutube className="social-icon  ml-2 mr-2 " />
                            </a>
                            : ''
                    }
                </div>
                <SEButton sekey={`iconSocial${this.props.owner}:css`} type={"icon"}></SEButton>
            </React.Fragment>
        );
    }
}
export default connect(mapStateToProps)(SocialIcon);
