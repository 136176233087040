import React, { useEffect, useState } from "react";
import '../../../scss/testimonial.scss';

const TextCarousel = (props) => {

    const [visiblityClass, setVisiblityClass] = useState("visible");
    const [currentPhrase, setCurrentPhrase] = useState("");
    const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);

    useEffect(() => {
        const { phrases } = props;
        setCurrentPhrase(phrases[currentPhraseIndex])
        const interval = setInterval(() => {
            setVisiblityClass(() => {
                return "hidden";
            });
            setTimeout(function() { //Start the timer
                setCurrentPhraseIndex(currentPhraseIndex => {
                    const index = (currentPhraseIndex + 1) % phrases.length;
                    setCurrentPhrase(phrases[index])
                    return index;
                });
                setVisiblityClass(() => {
                    return "visbile";
                });
            }.bind(this), 1000)
            
        }, 9000);
        return () => clearInterval(interval);
    }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setVisiblityClass(() => {
    //             return "hidden";
    //         });
    //     }, 3000);
    //     return () => clearInterval(interval);
    // }, []);
  
    return (
        <>
            <p className={`text-center testimonial testimonial-color ${visiblityClass}`}>{currentPhrase.quote}</p>
            <div className="row justify-content-end pr-auto pr-sm-5">
                <div className="col-12 align-self-end">
                    <p className={`testimonial-author text-right testimonial-color ${visiblityClass}`}>- {currentPhrase.author}</p>
                </div>
            </div>
        </>
    );
}

export default TextCarousel;