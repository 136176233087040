import React from 'react';

class SelectionGovtContent extends React.Component {

    componentDidMount = () => {
    }

    render() {
        return (
            <div className="container mt-4 mb-5 color-dark">
                <p>The <strong>Indiaspora Government Leaders List</strong> recognizes more than 200 leaders of the Indian diaspora who have reached the pinnacles of public leadership, now serving as prime ministers, agency heads, parliamentarians, legislators, and dozens of other key roles in 15 countries around the world.</p>
                <p>Representing the interests of more than 550 million citizens, this powerful group demonstrates the rise of the diaspora as leaders in the political and government space.</p>
                <p>To compile this first-ever list, Indiaspora researched government websites and other publicly available resources to compile a comprehensive list of diaspora leaders serving in the following positions:</p>
                <p>
                    <ul>
                        <li>Heads of government and deputy heads of government</li>
                        <li>Ministers and agency heads serving as part of a government's cabinet</li>
                        <li>Ambassadors* and consuls general*</li>
                        <li>Parliamentarians and federal legislators*</li>
                        <li>Chief justices</li>
                        <li>Heads of central banks</li>
                        <li>Senior civil servants*</li>
                        <li>Additional U.S. leaders, including federal administration nominees and appointees, and state and local leaders</li>
                    </ul>
                </p>
                <p>*Countries with a significant history of diaspora migration were included, such as Australia, Canada, Singapore, South Africa, United Arab Emirates, United Kingdom, and the United States.</p>
                <p>Officers of Indian citizenship were not included because the list aims to highlight the contributions of the diaspora.</p>
            </div>
        );
    }
}

export default SelectionGovtContent;