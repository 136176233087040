import React from 'react';
import { Form, Button, Spinner, Alert, Modal, Image } from 'react-bootstrap';
import firebase from '../firebase';
import Config from '../../firebase-configs.json';
import { connect } from 'react-redux';
import { addQuotes, updateQuote } from "./quotes-fs";
import { quotesAddEditModal } from '../redux/templ'

const mapStateToProps = (state) => {
    return {
        quotesModal: state?.tempData?.quotesModal,
        list: state?.application?.list,
        listData: state?.application?.listData
    }
}
class AddEditQuotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    handleClose = () => {
        quotesAddEditModal({ show: false, type: null })
    }
    onInputChange = (e) => {
        let obj = {};
        obj[e.target.id] = e.target.value;
        let newForm = { ...this.state, ...obj };
        this.setState({ ...this.state, ...newForm });
    }
    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ ...this.state, ...{ validated: true } })
        if (e.target.checkValidity()) {
            let frm = {};
            frm.url = this.state.url;
            frm.name = this.state.name;
            frm.quoteText = this.state.quoteText;
            frm.bioLink = this.state.bioLink;
            frm.timestamp = new Date(this.state.timestamp).getTime()
            console.log(frm)
            this.setState({ ...this.state, ...{ saving: true } });
            let listId = this.props?.list?.id;
            try {
                if (this.props?.quotesModal?.type === 'add') {
                    await addQuotes(listId, frm);
                    this.setState({})
                    this.handleClose();
                } else {
                    frm.id = this.state.id;
                    let oldImage = this.props.quotesModal.data.name;
                    if (frm.name !== oldImage) {
                        await this.deleteImage(oldImage);
                    }
                    await updateQuote(listId, frm.id, frm);
                    this.setState({})
                    this.handleClose();
                }
            } catch (error) {
                this.setState({ ...this.state, ...{ saving: false, msg: 'danger', msgbody: error.message } })
            }
        }
    }
    componentDidMount() {
        if (this.props?.quotesModal?.type === 'edit') {
            let data = { ...this.props.quotesModal.data };
            data.timestamp = new Date(this.props.quotesModal.data?.timestamp).toISOString().substring(0, 10)
            this.setState({
                ...this.state, ...{
                    ...data
                }
            })
        }
    }

    deleteImage = async (imageName) => {
        var storage = firebase.app().storage(Config.quotesBucket);
        var removeRef = storage.ref('/' + 'images' + '/').child(imageName);
        return removeRef.delete().then(() => {
            return true;
        }).catch((error) => {
            console.log('// File Not deleted. Any Problem.');
        });
    }

    handleImageUpload = (e) => {
        this.setState({ imageUploading: true });
        const image = e.target.files[0];
        if (image && image.name) {
            var storage = firebase.app().storage(Config.quotesBucket);
            let uploadTask = storage.ref('/images/' + image.name).put(image);
            uploadTask.on('state_changed',
                (snapShot) => {
                    // let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
                    // per = parseInt(per);
                    // this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: true } })
                }, (err) => {
                    this.setState({ imageUploading: false });
                }, () => {
                    storage.ref('images').child(image.name).getDownloadURL()
                        .then(async (fireBaseUrl) => {
                            const newState = { ...this.state, image: fireBaseUrl }
                            delete newState.imageUploading;
                            setTimeout(() => {
                                this.setState({ url: fireBaseUrl, name: image.name, imageUploading: false });
                            }, 1000)
                        })
                })
        }
    }

    render() {
        return (
            <>
                <Modal show={this.props?.quotesModal?.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props?.quotesModal?.type === 'add' ? 'Add Quotes' : 'Edit Quotes'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.onSubmit} noValidate validated={this.state.validated}>
                            <Form.Group controlId="image" >
                                <Form.Label>Image *</Form.Label><br/>
                                <Image src={this.state?.url}
                                    hidden={!this.state?.url} rounded thumbnail className={'col-6'} />
                                <div className="row align-middle pl-3 mt-2 pr-3">
                                    <Form.File type="file" accept="image/*" className="col-12 border pl-0"
                                        onChange={this.handleImageUpload}/>
                                </div>
                                <p hidden={!this.state.imageUploading}><Spinner animation="grow" size="sm" /> uploading...</p>
                            </Form.Group>
                            <Form.Group controlId="quoteText" >
                                <Form.Label>Quote in Text *</Form.Label>
                                <Form.Control type="text" className="form-control" required defaultValue={this.state?.quoteText} onChange={this.onInputChange} />
                            </Form.Group>
                            <Form.Group controlId="bioLink" >
                                <Form.Label>Bio Link *</Form.Label>
                                <Form.Control as="select" required value={this.state?.bioLink} onChange={this.onInputChange}>
                                    <option>Select</option>
                                    {this.props?.listData?.map(bio => {
                                        return(<option>{bio?.bioLink}</option>)
                                    })}
                            </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="timestamp">
                                <Form.Label>Date *</Form.Label>
                                <Form.Control type="date" className="form-control" required defaultValue={this.state?.timestamp} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter date.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="row mt-3 justify-content-center">
                                <div className="col-6  text-center">
                                    {this.state?.saving ? <Button>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            saving...
                        </Button> :
                                        <button type="submit" className="btn btn-primary btn-block text-white">SUBMIT</button>
                                    }
                                </div>
                            </div>
                            <br></br>
                            <Alert variant={this.state.msg} style={{ textAlign: 'center' }}>
                                {this.state.msgbody}
                            </Alert>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}



export default connect(mapStateToProps)(AddEditQuotes);