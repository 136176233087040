
import { store } from '../store';
//enums
export const SET_PROFILE = 'SET_PROFILE';
export const LOGOUT = 'LOGOUT'

//action creators
const setProfileAc = (payload) => {
    return {
        type: SET_PROFILE,
        payload
    }
}
const logoutAc = () => {
    return {
        type: 'LOGOUT'
    }
}
let loadLocalProfileAc = () => {
    return {
        type: 'LOAD_LOCAL_PROFILE'
    }
}
let setLiuAc = (payload) => {
    return {
        type: 'SET_LIU',
        payload
    }
}
let liuRemoveAc = () => {
    return {
        type: 'REMOVE_LIU'
    }
}
//actions
export const loadProfile = (user) => {
    store.dispatch(setProfileAc(user))
    store.dispatch(setLiuAc(user))
}
export const logout = () => {
    store.dispatch(logoutAc())
    store.dispatch(liuRemoveAc())
}



export const loadLocalProfile = () => {
    store.dispatch(loadLocalProfileAc())
}



let initState = {};
export const profileReducer = (state = initState, action) => {
    switch (action.type) {
        case 'LOAD_LOCAL_PROFILE': return (() => {
            let profile = localStorage.getItem('liu');
            profile = JSON.parse(profile);
            return profile;
        })()
        case 'SET_LIU': return (() => {
            return { ...state, ...action.payload }
        })()
        case 'REMOVE_LIU': return (() => {
            return {}
        })()
        default: return (() => {
            return state;
        })()
    }
}
