import React from 'react';
import { Navbar, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { store } from '../store';
import { logout } from '../auth/login-fs';
import { Link, withRouter } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        profile: state.application.profile,
        appBar: state.application.appBar,
        liu: state.profile
    }
}

class AppHeader extends React.Component {
    getLoggedInDetails = () => {
        return <div className="align-items-center row">
            <Navbar.Text className="text-white">
                {this.props?.liu?.firstName} {this.props?.liu?.lastName}
            </Navbar.Text>
            <Button onClick={logout}>Logout</Button>
        </div>
    }
    getNotLoggedInDetails = () => {
        return <Link to='/login'>
            <Button>
                Login
            </Button>
        </Link>
    }
    onLogoClick = () => {
        this.props.history.push('/');
    }

    label = () => {
        const crumbs = this.props?.crumbs?.reverse();
        return (
            <React.Fragment >
            {/* Link back to any previous steps of the breadcrumb. */}
            {crumbs.map(({ name, path }, key) =>
              key + 1 === this.props.crumbs.length ? (
                <span key={key} className="bold">
                  {name}
                </span>
              ) : (
                <React.Fragment key={key}>
                    <Link className="underline text-blue-500 text-white" to={path}> {name}</Link>
                    <span> / </span>
                </React.Fragment>
              )
            )}
          </React.Fragment>
        )
    }

    render() {
        return (
            <>
                {this.props?.appBar === 'visible' ?
                    <Navbar bg="primary">
                        <Navbar.Brand className="text-white">
                            <img src={require('../../assets/img/logo.png')} 
                                onClick={this.onLogoClick} 
                                style={{ width: 50, height: 50, backgroundColor: "#e13a14", padding: 3, cursor: 'pointer' }} 
                                alt="Indiaspora Listing"
                            />
                            {this.label()}
                        </Navbar.Brand>
                        <Navbar.Toggle />
                        <Navbar.Collapse className="justify-content-end">
                            {this.props?.liu?.id ? this.getLoggedInDetails() : this.getNotLoggedInDetails()}
                        </Navbar.Collapse>
                    </Navbar>
                    : ''}
            </>
        );
    }
}


export default connect(mapStateToProps)(withRouter(AppHeader));