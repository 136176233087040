import React from 'react';
import CountryLay from './country'


class CountryLayOne extends React.PureComponent {

    render() {
        return (
            <div>
                <CountryLay></CountryLay>
            </div>
        );
    }
}


export default CountryLayOne;