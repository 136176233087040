import React from "react";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import DetailBody from "./detail-body"
import Sidenave from "./sidenave"
import Navbar from "../navbar"

const mapStateToProps = (state) => {
    return {
        activeBio: state.application.activeBio,
    }
}
class DetailConatiner extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            showDetail: false,
        }
    }

    componentDidMount = async() => {
        const params = this.props.match.params;
        if(this.props.activeBio && this.props.activeBio.bioLink === params.name) {
            this.setState({show: true})
        }
    }
    componentDidUpdate = async () => {
        const params = this.props.match.params;
        if(!this.state.show && this.props.activeBio && this.props.activeBio.bioLink === params.name) {
            this.setState({show: true})
        }
    }

    showDetail = () => {
        this.setState({showDetail: true})
    }
    render() {
        return (
            <div className="actor-detail-container  mt-3 mt-sm-2 mt-md-2 mt-lg-0 mt-xl-0">
                <Navbar showBack="true" isBio="true"/>
                {this.state.show && <Sidenave showDetail={this.showDetail}/>}
                {this.state.showDetail && <DetailBody />}
            </div>

        );
    }
}

export default connect(mapStateToProps)(withRouter(DetailConatiner));