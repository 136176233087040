import { store } from '../store'

//actions creator
const resetTempAc = () => {
    return {
        type: 'REST_TEMP'
    }
}
const deletingDataRowAc = () => {
    return {
        type: 'DELETING_DATA_ROW'
    }
}
const doneDeletingDataRowAc = () => {
    return {
        type: 'DONE_DELETING_DATA_ROW'
    }
}
const loadGridApiAc = (payload) => {
    return {
        type: 'LOAD_GRID_API',
        payload
    }
}
const setSearchValueAc = (payload) => {
    return {
        type: 'SET_SEARCH_VALUE',
        payload
    }
}
const setSearchCategoryItemAc = (payload) => {
    return {
        type: 'SET_SEARCH_CATEGORY_ITEM',
        payload
    }
}
const setSearchItemAc = (payload) => {
    return {
        type: 'SET_SEARCH_ITEM',
        payload
    }
}
const newsAddEditModalAc = (payload) => {
    return {
        type: 'NEWS_ADD_EDIT_MODAL',
        payload
    }
}
const mediaAddEditModalAc = (payload) => {
    return {
        type: 'MEDIA_ADD_EDIT_MODAL',
        payload
    }
}
const quotesAddEditModalAc = (payload) => {
    return {
        type: 'QUOTES_ADD_EDIT_MODAL',
        payload
    }
}
const profileAddEditModalAc = (payload) => {
    return {
        type: 'PROFILE_ADD_EDIT_MODAL',
        payload
    }
}

const setSubNavActiveLinkAc = (payload) => {
    return {
        type: 'SET_SUBNAV_ACTIVE_LINK',
        payload
    }
}
const setActiveTableOrGridViewAc = (payload) => {
    return {
        type: 'SET_ACTIVE_TABLE_OR_GRID_VIEW',
        payload
    }
}
const setShowQuotesAc = (payload) => {
    return {
        type: 'SET_SHOW_QUOTES',
        payload
    }
}
const setQuotesLabelAc = (payload) => {
    return {
        type: 'SET_QUOTES_LABEL',
        payload
    }
}
const setShowMediaAc = (payload) => {
    return {
        type: 'SET_SHOW_MEDIA',
        payload
    }
}
const setMediaLabelAc = (payload) => {
    return {
        type: 'SET_MEDIA_LABEL',
        payload
    }
}
const setFromListHomeAc = (payload) => {
    return {
        type: 'SET_FROM_LIST_HOME',
        payload
    }
}
const setFromWhereAc = (payload) => {
    return {
        type: 'SET_FROM_WHERE',
        payload
    }
}
const setFromSliderAc = (payload) => {
    return {
        type: 'SET_SLIDER',
        payload
    }
}
const setOldImageAc = (payload) => {
    return {
        type: 'OLD_IMAGE',
        payload
    }
}


//actions

export const resetTemp = () => {
    store.dispatch(resetTempAc())
}
export const deletingDataRow = () => {
    store.dispatch(deletingDataRowAc())
}
export const doneDeletingDataRow = () => {
    store.dispatch(doneDeletingDataRowAc())
}
export const loadGridAPI = (payload) => {
    store.dispatch(loadGridApiAc(payload))
}

export const getFilterData = (dataForFilter, searchItem) => {
    let searchTerms = searchItem?.toLowerCase().split(' ');
    searchTerms = searchTerms.map(term => `|${term}`)
    const currentState = store.getState();
    let isGovt = currentState?.application?.listingType.uuid == "governmentLeaders";
    let isPhilanthropy = currentState?.application?.listingType.uuid == "philanthropyLeaders";
    let isSocialEntrepreneur = currentState?.application?.listingType.uuid == "socialEntrepreneurs";
    let filtered = dataForFilter?.filter(ele => {
        let searchedEle = searchTerms.filter(searchTerm => {
            let strToBeSearched;
            if(isGovt){
                strToBeSearched = `|${ele.firstname.trim()}|${ele.lastname.trim()}|${ele.title.trim()}|${ele.country.trim()}`;
            } else if(isPhilanthropy){
                strToBeSearched = `|${ele.firstname.trim()}|${ele.lastname.trim()}|${ele.country.trim()}`;
            } else if(isSocialEntrepreneur){
                strToBeSearched = `|${ele.firstname.trim()}|${ele.lastname.trim()}|${ele.country.trim()}|${ele.company.trim()}`;
            } else {
                strToBeSearched = `|${ele.firstname.trim()}|${ele.lastname.trim()}|${ele.title.trim()}|${ele.industry.trim()}|${ele.company.trim()}`;
            }                    
            let strToBeSearchedInc = strToBeSearched.toLowerCase().includes(searchTerm);
            if(strToBeSearchedInc){
                return ele;
            }
        });
        if(searchedEle?.length === searchTerms?.length) {
            return ele;
        }
    })
    return filtered;
}

export const setSearchValue = (payload, category) => {
    const currentState = store.getState();
    let listData = currentState?.application?.listData;
    let listingType = currentState?.application?.listingType;
    let filtered = [];
    if(category) {
        if(payload === ' ') {
            filtered = listData;
            store.dispatch(setSearchCategoryItemAc(null));
        } else {
            filtered = listData?.filter((ele) => {
                const column = listingType?.categoryColumn ? listingType?.categoryColumn : 'industry';
                let industrInc = ele[column].toLowerCase().includes(payload.toLowerCase());
                if (industrInc) {
                    return ele
                }
            })
            store.dispatch(setSearchCategoryItemAc(payload));        
        }
        if(currentState.tempData?.searchItem){
            let searchItem = currentState.tempData?.searchItem;
            filtered = getFilterData(filtered, searchItem);
        }
    } else {
        let searchCategoryItem = currentState.tempData?.searchCategoryItem;
        if(searchCategoryItem){
            filtered = listData?.filter((ele) => {
                const column = listingType?.categoryColumn ? listingType?.categoryColumn : 'industry';
                let industrInc = ele[column].toLowerCase().includes(searchCategoryItem.toLowerCase());
                if (industrInc) {
                    return ele
                }
            })
        } else {
            filtered = listData;
        }
        filtered = getFilterData(filtered, payload);
        store.dispatch(setSearchItemAc(payload))
    }
    store.dispatch(setSearchValueAc(filtered))
}


//news modal
export const newsAddEditModal = (payload) => {
    store.dispatch(newsAddEditModalAc(payload))
}
export const mediaAddEditModal = (payload) => {
    store.dispatch(mediaAddEditModalAc(payload))
}
export const quotesAddEditModal = (payload) => {
    store.dispatch(quotesAddEditModalAc(payload))
}
export const profileAddEditModal = (payload) => {
    store.dispatch(profileAddEditModalAc(payload))
}

//subnav
export const setSubNavActiveLink = (payload) => {
    store.dispatch(setSubNavActiveLinkAc(payload))
}

export const setActiveTableOrGridView = (payload) => {
    store.dispatch(setActiveTableOrGridViewAc(payload))
}

export const setShowQuotes = (payload) => {
    store.dispatch(setShowQuotesAc(payload))
}
export const setQuotesLabel = (payload) => {
    store.dispatch(setQuotesLabelAc(payload))
}
export const setShowMedia = (payload) => {
    store.dispatch(setShowMediaAc(payload))
}
export const setMediaLabel = (payload) => {
    store.dispatch(setMediaLabelAc(payload))
}
export const setFromWhere = (payload) => {
    store.dispatch(setFromWhereAc(payload))
}
export const setFromListHome = (payload) => {
    store.dispatch(setFromListHomeAc(payload))
}
export const setFromSlider = (payload) => {
    store.dispatch(setFromSliderAc(payload))
}
export const setOldImage = (payload) => {
    store.dispatch(setOldImageAc(payload))
}

let iniState = {
    activeTableOrGridView: 'grid'
}
export const tempReducer = (state = iniState, action) => {
    switch (action.type) {
        case 'REST_TEMP': return { ...iniState }
        case 'DELETING_DATA_ROW': return { ...state, deletingDataRow: true }
        case 'DONE_DELETING_DATA_ROW': return { ...state, deletingDataRow: false }
        case 'LOAD_GRID_API': return { ...state, ...{ setQuickFilter: action.payload } }
        case 'SET_SEARCH_VALUE': return { ...state, ...{ searchVal: action.payload } }
        case 'SET_SEARCH_CATEGORY_ITEM': return { ...state, ...{ searchCategoryItem: action.payload } }
        case 'SET_SEARCH_ITEM': return { ...state, ...{ searchItem: action.payload } }
        case 'NEWS_ADD_EDIT_MODAL': return { ...state, ...{ newsModal: action.payload } }
        case 'MEDIA_ADD_EDIT_MODAL': return { ...state, ...{ mediaModal: action.payload } }
        case 'QUOTES_ADD_EDIT_MODAL': return { ...state, ...{ quotesModal: action.payload } }
        case 'PROFILE_ADD_EDIT_MODAL': return { ...state, ...{ profileModal: action.payload } }
        case 'SET_SUBNAV_ACTIVE_LINK': return { ...state, ...{ subnavActiveLink: action.payload } }
        case 'SET_ACTIVE_TABLE_OR_GRID_VIEW': return { ...state, ...{ activeTableOrGridView: action.payload } }
        case 'SET_SHOW_QUOTES': return { ...state, ...{ showQuotes: action.payload } }
        case 'SET_QUOTES_LABEL': return { ...state, ...{ showQuotesLabel: action.payload } }
        case 'SET_SHOW_MEDIA': return { ...state, ...{ showMedia: action.payload } }
        case 'SET_MEDIA_LABEL': return { ...state, ...{ showMediaLabel: action.payload } }
        case 'SET_FROM_WHERE': return { ...state, ...{ fromWhere: action.payload } }
        case 'SET_FROM_LIST_HOME': return { ...state, ...{ fromListHome: action.payload } }
        case 'SET_SLIDER': return { ...state, ...{ fromSlider: action.payload } }
        case 'OLD_IMAGE': return { ...state, ...{ oldImage: action.payload } }
        default: return (() => {
            return state;
        })()
    }
}