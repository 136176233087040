import React from 'react';
import { connect } from 'react-redux';
import './scss/login.scss';
import './scss/App.scss';
import './scss/actorDetails.scss';
import './scss/card.scss';
import './scss/common.scss';
import './scss/social-icons.scss';
import firebase from "./firebase";
import { store } from './store';
import HomeLay from './layouts/first-layout/home';
import ProfileLay from './layouts/first-layout/profile'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { Login } from './auth';
import { Subdomains } from './subdomains';
import ListingTypes from './listingtypes/listing-types';
import ListDetailsPage from './list-details-page';
import ListSocialPage from './list-social-page';
import LayoutStylesEditor from './layout-styles-editor';
import { AppHeader } from './common';
import { loadLsToRx, loadApplicationMode } from './redux/application';
import { loadLocalProfile } from './auth/login-rx'

const mapStateToProps = (state) => {
  return {
    application: state?.application
  }
}

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authRedirect: false,
    }
  }
  componentDidMount = () => {
    loadApplicationMode('admin');
    loadLsToRx();
    loadLocalProfile();
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        // store.dispatch(getUserFromLocalStorage(user.uid))
      } else {
        this.setState({ ...this.state, ...{ authRedirect: true, authRedirectTo: '/login' } });
      }
    });
  }

  routes = [
    { path:"/login", Component: Login },
    { path:"/subdomains/:subdomain/listingTypes/:listingTypeId/list/:listId/layout/:layoutName", Component: LayoutStylesEditor },
    { path:"/subdomains/:subdomain/listingTypes/:listingTypeId/list/:listId", name: 'ListDetailsPage', Component: ListDetailsPage },
    { path:"/subdomains/:subdomain/listingTypes/:listingTypeId/list/:listId/social", name: 'ListSocialPage', Component: ListSocialPage },
    { path:"/subdomains/:subdomain/listingTypes", name:'listingTypes', Component: ListingTypes },
    { path:"/subdomains", name: 'Home', Component: Subdomains },
    { path:"/homePathForSushil", Component: HomeLay },
    { path:"/profileForSushil", Component: ProfileLay }
  ];

  setName = (name, subdomain, matchParams) => {
    if(name === 'listingTypes') {
      return subdomain?.length>0 ? subdomain[0]?.label : matchParams?.subdomain
    } else if(name === 'ListDetailsPage') {
      return this.props?.application?.list?.name
    } else return name
  }

  render() {
    return (
      <Router>
        <div className="w-screen h-screen flex justify-center p-8">
          <Switch>
            {this.routes?.map(({ path, name, Component }, key) => (
              <Route
                exact
                path={path}
                key={key}
                render={props => {
                  const matchParams = props?.match?.params;
                  let subdomain = this.props?.application?.subdomains?.filter(subdomain => subdomain?.id === matchParams?.subdomain);
                  const crumbs = this.routes
                    // Get all routes that contain the current one.
                    .filter(({ path }) => props?.match?.path.includes(path))
                    // name = matchParams?.subdomain
                    // Swap out any dynamic routes with their param values.
                    // E.g. "/pizza/:pizzaId" will become "/pizza/1"

                    .map(({ path, name, ...rest }) => (
                    {
                      name: this.setName(name, subdomain, matchParams),
                      path: Object.keys(matchParams).length
                        ? Object.keys(matchParams).reduce(
                            (path, param) =>
                              path.replace(`:${param}`, matchParams[param]),
                            path
                          )
                        : path,
                      ...rest
                    }));
    
                  // console.log(`Generated crumbs for ${props?.match?.path}`);
                  // crumbs.map(({ name, path }) => console.log({ name, path }));
    
                  return (
                    <div className="p-8" key={key}>
                      <AppHeader crumbs={crumbs} key={key} />
                      <Component {...props} />
                    </div>
                  );
                }}
              />
            ))}
           <Route path="/">
              <Redirect to="/subdomains" />
           </Route>
          </Switch>
        {this.state.authRedirectTo ? <Redirect to={this.state.authRedirectTo} /> : ''}
        </div>
      </Router>
      );
  }
}

export default connect(mapStateToProps)(App);
