import React from 'react';

class SelectionBizContent extends React.Component {

    componentDidMount = () => {
    }

    render() {
        return (
            <div className="container mt-4 mb-5 color-dark">
                <p>The <strong>Indiaspora Business Leaders List</strong> includes 60 top executives of Indian descent. This powerful group accounts for a total of $1 trillion in annual revenue and clearly demonstrates the rise of the diaspora as C-level influencers.</p>
                <p>Business leaders were chosen from the ranks of the largest global companies. The list includes companies with Indian diaspora leaders who serve in the positions of Chief Executive Officer, President, or Chairman of the Board at their respective organizations.</p>
                <p>The most recent editions of the following databases were used as a basis for selecting members: 
                    <a className="text-info" href="https://fortune.com/fortune500/">Fortune 500</a> (which actually features 1,000 companies), 
                    <a className="text-info" href="https://www.forbes.com/global2000/#362a948f335d"> Forbes Global 2000</a>,
                    <a className="text-info" href="https://fortune.com/global500/"> Fortune Global 500</a>  and the
                    <a className="text-info" href="https://www.forbes.com/largest-private-companies/list/5/#tab:rank"> Forbes Largest Private U.S. Companies</a>.
                </p>
                <p>Data for companies on the Fortune 500 and Forbes Global 2000 were from the fiscal year ending on or before January 21, 2020. Information for companies from the Fortune Global 500 list was derived from the fiscal year ending on or before March 31, 2019. Data for companies on the Forbes Largest Private U.S. Companies list was derived from fiscal year 2019.</p>
                <p>While Fortune
                    <a className="text-info" href="https://fortune.com/franchise-list-page/fortune-500-methodology-2020"> ranks </a> 
                    its lists according to a company’s total revenue for the latest fiscal year, Forbes 
                    <a className="text-info" href="https://www.forbes.com/2011/04/20/global-2000-11-methodology.html#66b1fd762a22"> ranks </a> 
                    public companies on a mix of sales, profit, assets, and market revenue, and private companies 
                    <a className="text-info" href="https://www.forbes.com/sites/andreamurphy/2011/11/16/americas-largest-private-companies-methodology/#13d6f6c14a4a"> with more than $2 billion in revenue</a>.
                </p>
                <p>Officers at organizations headquartered in India were not considered as this list aims to highlight the contributions of the diaspora.</p>
            </div>
        );
    }
}

export default SelectionBizContent;