import React from "react";
import { Form, Spinner, Button, Alert } from 'react-bootstrap';
import { addInquiry } from '../../../../listdetails/listdetails-fs'
import { withRouter } from "react-router-dom";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: { inquiryType: "Press" }
        }
    }
    onEmailSend = async (e) => {
        e.preventDefault();
        this.setState({ ...this.state, ...{ validated: true } })
        if (e.target.checkValidity()) {
            this.setState({ ...this.state, ...{ addingInquiry: true } })
            try {
                this.setState({ ...this.state, ...{ addingInquiry: true } })
                console.log(this.state.form);
                await addInquiry(this.state.form);
                this.setState({ ...this.state, ...{ addingInquiry: false, msg: 'success', msgbody: 'Added your inquiry successfully' } })
                setTimeout(() => {
                    this.props.history.goBack();
                }, 2000)
            } catch (error) {
                this.setState({ ...this.state, ...{ addingInquiry: false, msg: 'danger', msgbody: error.message } })
            }
        }
    }
    onInputChange = (e) => {
        // console.log('Test');
        let obj = {};
        obj[e.target.id] = e.target.value;
        let newForm = { ...this.state.form, ...obj };
        this.setState({ ...this.state, ...{ form: newForm } });
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6">
                        <Form onSubmit={this.onEmailSend} noValidate validated={this.state.validated}>
                            <Form.Group controlId="firstName">
                                <Form.Label>First Name<span className="text-danger">*</span></Form.Label>
                                <Form.Control type="text" className="form-control" required value={this.state?.form?.firstName} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" className="form-control" value={this.state?.form?.lastName} onChange={this.onInputChange} />
                            </Form.Group>
                            <Form.Group controlId="email">
                                <Form.Label>Email address <span className="text-danger">*</span></Form.Label>
                                <Form.Control type="email" className="form-control" required value={this.state?.form?.email} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter email.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="affiliation">
                                <Form.Label>Affiliation</Form.Label>
                                <Form.Control type="text" className="form-control" value={this.state?.form?.affiliation} onChange={this.onInputChange} />
                            </Form.Group>
                            <Form.Group controlId="inquiryType">
                                <Form.Label>Inquiry type</Form.Label>
                                <Form.Control as="select" custom value={this.state?.form?.inquiryType} onChange={this.onInputChange}>
                                    <option value="Press">Press</option>
                                    <option value="Donation">Donation</option>
                                    <option value="Partnership">Partnership</option>
                                    <option value="Membership">Membership</option>
                                    <option value="Other">Other</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="description">
                                <Form.Label>Please elaborate on your inquiry <span className="text-danger">*</span></Form.Label>
                                <Form.Control as="textarea" rows="4" required value={this.state?.form?.description} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please field required.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="row mt-3 justify-content-center">
                                <div className="col-6  text-center">
                                    {this.state?.addingInquiry ? <Button>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            sending...
                                        </Button> :
                                        <button type="submit" className="btn btn-primary btn-block text-white">SUBMIT</button>
                                    }
                                </div>
                            </div>
                            <br></br>
                            <Alert variant={this.state.msg} style={{ textAlign: 'center' }}>
                                {this.state.msgbody}
                            </Alert>
                        </Form>
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(Contact);