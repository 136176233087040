import React from 'react';
import { connect } from 'react-redux';

import { getListingTypes } from "../../listdetails/listdetails-fs";
import Navbar from '../first-layout/components/navbar';
import Footer from '../first-layout/components/footer'
import HomeListing4 from './components/HomeListing4';

class HomeLay extends React.Component {
    componentDidMount = async() => {
        getListingTypes();
    }
    render() {
        console.log("SUBDOMAIN HomeLay", this.props)
        return (
            <div className="mainContainer">
                {/* <Navbar /> */}
                {this.props.listingTypes && <HomeListing4 listingTypes={this.props.listingTypes} />}
                <Footer />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    console.log("LISTVIEWCONTAINERRRRRRRRRRRRRRRRRRRRRRRRRRRRRR", state)
    return {
        listingTypes: state.application?.listingTypes,
    }
}

export default connect(mapStateToProps)(HomeLay);
