import React from "react";
import SEButton from '../../../common/styles-editor/SE-button'
import { connect } from "react-redux";


const mapStateToProps = (state) => {
    return {
        homeLayUtils: state.application.homeLayUtils,
        listingType: state.application.listingType,
        applicationMode: state.application.mode,
        listMetaData: state.application.listMetaData
    }
}
class ListDescription extends React.Component {
    constructor(props) {
        super(props)
        console.log(props);
    }

    render() {
        return (
            <div className="container" style={this.props.homeLayUtils.getStyle('gradDivone', this.props.listingType, this.props.applicationMode, 'homeMeta')}>
                <div className="card  lst-descritption  list-dsc-img-container border-0">
                    {/* <img src={require('../../../../assets/img/s3.png')} className="card-img" alt="..." /> */}
                    {/* <img src={this.props.homeLayUtils.getImage(`homeDescImg:css`, this.props.listMetaData, this.props.applicationMode)} className="card-img" alt="..." /> */}
                    <div className="p-3 align-items-center justify-content-center d-flex row align-content-center  pl-4 pr-4  pl-sm-4 pr-sm-4 pl-md-4 pr-md-4   pl-lg-5 pr-lg-5  pl-xl-5 pr-xl-5 ">
                        <h2 className="text-center mb-4 d-none d-sm-none d-lg-block d-xl-block" 
                            style={this.props.homeLayUtils.getStyle('homeTitle:css', this.props.listMetaData, this.props.applicationMode)}>
                            {this.props.homeLayUtils.getValue('homeTitle:css', this.props.listMetaData, this.props.applicationMode)}
                            <SEButton sekey={"homeTitle:css"} type={"text"}></SEButton>
                        </h2>
                        <h4 className="text-center mb-4 d-lg-none d-block"
                            style={this.props.homeLayUtils.getStyle('homeTitle:css', this.props.listMetaData, this.props.applicationMode)}>
                            {this.props.homeLayUtils.getValue('homeTitle:css', this.props.listMetaData, this.props.applicationMode)}
                            <SEButton sekey={"homeTitle:css"} type={"text"}></SEButton>
                        </h4>
                        <p className="text-left"
                            style={this.props.homeLayUtils.getStyle('homeDescription:css', this.props.listMetaData, this.props.applicationMode)}>
                            {this.props.homeLayUtils.getValue('homeDescription:css', this.props.listMetaData, this.props.applicationMode)}
                            <SEButton sekey={"homeDescription:css"} type={"text"}></SEButton>
                        </p>
                        <p className="text-left"
                            style={this.props.homeLayUtils.getStyle('homeDescription2:css', this.props.listMetaData, this.props.applicationMode)}>
                            {this.props.homeLayUtils.getValue('homeDescription2:css', this.props.listMetaData, this.props.applicationMode)}
                            <SEButton sekey={"homeDescription2:css"} type={"text"}></SEButton>
                        </p>
                    </div>
                </div>
                <SEButton sekey={"homeDescImg:css"} type={"img"}></SEButton>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ListDescription);