import React from 'react';
import { getNews, deleteNews } from './news-fs';
import { connect } from 'react-redux';
import { Modal, Button, Spinner } from 'react-bootstrap'
import { newsAddEditModal } from '../redux/templ'


const mapStateToProps = (state) => {
    return {
        news: state.news?.newsData,
        application: state?.application?.mode,
        list: state?.application?.list
    }
}

class News extends React.Component {
    componentDidMount = () => {
        if(this.props.application === 'consumer')  {
            getNews(this.props.list?.id, false, 'consumer');
        } else {
            this.listId = this.props?.match?.params?.listId;
            getNews(this.listId, false, 'admin');
        }
    }
    editNews = (news) => {
        newsAddEditModal({ type: 'edit', data: news, show: true })
    }
    deleteNews = async (news) => {
        this.setState({ ...this.state, ...{ deleting: true } })
        await deleteNews(this.props?.list?.id, news.id);
        this.handleClose();
        this.setState({ ...this.state, ...{ deleting: false } })
    }
    handleClose = () => {
        this.setState({ ...this.state, ...{ show: false } })
    }
    geDeleteConfirmModal = (row) => {
        return <Modal show={this.state?.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to delete news ?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    No
          </Button>
                {this.state?.deleting ? <Button>
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            deleting...
                        </Button> :
                    <Button variant="primary" onClick={() => this.deleteNews(this.state.newsToDelete)}>
                        Yes
                </Button>
                }
            </Modal.Footer>
        </Modal>
    }
    showDeleteConfirmModal = (row) => {
        this.setState({ ...this.state, ...{ show: true, newsToDelete: row } })
    }
    render() {
        return (
            <>
                {this.geDeleteConfirmModal()}
                <div className="container p-0 mb-4 mt-4">
                    <div className="row justify-content-center">
                        {this.props.news?.map((row, index) => {
                            return <div className="col-12 col-sm-6 col-lg-3 col-xl-3" key={index}>
                                <div className="card text-center border-0 mb-5 mt-3">
                                    <div className="edit-newsimg-container">
                                        <img src={row.imageUrl} className="p-2" className="card-img-top" alt="..." />
                                    </div>
                                    <div className="card-body pl-0 pr-0">
                                        <h6 className="card-title text-left mb-0">{row.title}</h6>
                                        <p className="card-text text-left">{row.subtitle}</p>
                                    </div>
                                    <div className="card-footer border-0 text-center bg-transparent p-0">
                                        <button className="btn btn-primary m-2" onClick={() => this.editNews(row)}>Edit</button>
                                        <button className="btn btn-secondary" onClick={() => { this.showDeleteConfirmModal(row) }}>Delete</button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </>
        );
    }
}


export default connect(mapStateToProps)(News);