import React from "react";
import { connect } from "react-redux";
import Slider from './slider';
import SEButton from '../../../../common/styles-editor/SE-button'
import Spacer from "../spacer";
import { loadActiveCountry } from "../../../../listdetails/listdetails-rx";
import { setFromWhere } from "../../../../redux/templ";
import { selectUUIDAndYear, selectActiveBioQuote } from "../../../../redux/list-config.selector";
import { withRouter } from "react-router-dom";
import ModalImage from "react-modal-image";

const mapStateToProps = (state) => {
    return {
        uuidAndYear: selectUUIDAndYear(state),
        activeBioQuote: selectActiveBioQuote(state),
        listingType: state.application.listingType,
        activeBio: state.application.activeBio,
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: state.application.mode,
        listMetaData: state.application.listMetaData
    }
}

class DetailBody extends React.PureComponent {
    dummyProfile = {
        "marketCap": "123B",
        "employees": "103,549",
        "hq": "Mountain View, CA",
        "industry": "",
        "lastname": "Pichai",
        "notes": "",
        "fortune500(2020)": "11",
        "title": "Chief Executive Officer",
        "revenue": "$136.82",
        "residence": "Mountain View, CA",
        "marketcap-livelinkjune1,2020marketcap": "https://www.nasdaq.com/market-activity/stocks/googl",
        "age": "47",
        "since": "2007",
        "link": "https://fortune.com/fortune500/2019/alphabet",
        "fortuneglobal500(2019)": "37",
        "description": "Sundar Pichai spearheaded Google's acquisition of Nest, the development of Android One, and the creation of Chrome OS, having an immeasurable impact on the tech giant. Now serving as the CEO og Google and parent company Alphabet, Sundar now oversees the \"Other Bets\" division of Alphabet as well.",
        "description_2": "Sundar Pichai spearheaded Google's acquisition of Nest, the development of Android One, and the creation of Chrome OS, having an immeasurable impact on the tech giant. Now serving as the CEO og Google and parent company Alphabet, Sundar now oversees the \"Other Bets\" division of Alphabet as well.",
        "birthplace": "Mumbai",
        "company": "Alphabet",
        "netIncome": "$30.73B",
        "forbesglobal2000(2020)": "13",
        "forbeslargestprivateu.s.companies(2019)": "",
        "firstname": "Sunder",
        "id": "0px0ns4fncniRmVDWi2N"
    }

    getValue = (attrName) => {

        if (this.props.applicationMode === 'admin') {
            return this.props?.activeBio?.[attrName] ? this.props?.activeBio?.[attrName] : this.dummyProfile[attrName]
        } else if (this.props.applicationMode === 'consumer' &&
            (this.props?.activeBio?.[attrName]?.length > 2 ||
                this.props?.activeBio?.[attrName] > 2 // the case of age
            )) {
            return this.props?.activeBio?.[attrName]
        } else {
            return '';
        }
    }
    getStyle = (attrName) => {
        return this.props.homeLayUtils.getStyle(`col-${attrName}-bioLabel:css`, this.props.listMetaData, this.props.applicationMode)
    }
    getValueStyle = (attrName) => {
        return this.props.homeLayUtils.getStyle(`col-${attrName}-bioValue:css`, this.props.listMetaData, this.props.applicationMode)
    }
    getLabelStyleButton = (attrName) => {
        return <>
            <SEButton sekey={`col-${attrName}-bioLabel:css`} type={"nonValuetext"}></SEButton>
        </>
    }
    getValueStyleButton = (attrName) => {
        return <>
            <SEButton sekey={`col-${attrName}-bioValue:css`} type={"nonValuetext"}></SEButton>
        </>
    }

    getAttrJsx = (attrName, classes) => {

        return <>
            <span className={classes} style={this.getValueStyle(attrName)}>
                {this.getValue(attrName)}
                {this.getValueStyleButton(attrName)}
            </span>
        </>
    }
    getDescAttrJsx = (attrName, classes) => {
        return <>
            <span className={classes} style={this.getValueStyle(attrName)}
                dangerouslySetInnerHTML={{ __html: this.getValue(attrName) }}
            >
            </span>
            <span>{this.getValueStyleButton(attrName)}</span>
        </>
    }
    getAttrWithLabelJsx = (attrName) => {
        let jsx = <>
            <span style={this.getStyle(attrName)}>
                {this.props.homeLayUtils.getLabel(attrName, this.props.listingType, 'clmnMeta')}
                {this.getLabelStyleButton(attrName)}
            </span>
        :&nbsp;
            <span style={this.getValueStyle(attrName)}>
                {this.getValue(attrName)}
                {this.getValueStyleButton(attrName)}
            </span>
            <br /></>
        if (this.props.applicationMode === 'admin') {
            return jsx
        } else if (this.props.applicationMode === 'consumer' &&
            (this.props?.activeBio?.[attrName]?.length > 2 ||
                this.props?.activeBio?.[attrName] > 2 // the case of age
            )) {
            return jsx
        } else {
            return '';
        }
    }

    getCountryJSX = () => {
        const { uuid, year } = this.props.uuidAndYear;
        const isPhilanthropyLeaders = uuid === "philanthropyLeaders" && year === "2021"
        const isSocialEntrepreneur = uuid === "socialEntrepreneurs" && year === "2022"
        if(isPhilanthropyLeaders || isSocialEntrepreneur){
            return <h4 className="mb-0 govt-country-link">
                    <span style={this.getValueStyle('country')}>{this.getAttrJsx('country', "")}</span>
                </h4>
        } else {
            return <h4 className="mb-0 govt-country-link">
                    {this.getAttrJsx('title', "")}&nbsp; | <a className="profile-name-india" href="javascript:void(0)" onClick={this.gotoCountry} style={this.getValueStyle('country')}><span style={{ cursor: 'pointer' }}>{this.getAttrJsx('country', "")}</span></a>
                </h4>
        }
    }
    gotoCountry = () => {
        const country = this.props?.activeBio.countryMeta;
        const { uuid, year } = this.props.uuidAndYear;
        loadActiveCountry(country);
        setFromWhere('local');
        let url = `/${uuid}/${year}/country/${country}`;
        this.props.history.push(url);
    }

    render() {
        let imageBackgroundColor = {};
        if (this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)) {
            imageBackgroundColor = { backgroundImage: `url(${this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)})` }
        } else {
            imageBackgroundColor = this.props.homeLayUtils.getStyle(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)
        }

        const bgImage = {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        };
        return (
            <div className="container-fluid">
                <div className="row align-items-start body-content-container">
                    <div className="col-12 col-sm-12 col-md-12 col-lx-11 col-lg-11 mb-5 mt-4">
                        <section className="pl-lg-4 pt-3 d-flex align-items-center" id="about">
                            <div className="w-100 pl-lg-3">
                                <h1 className="mb-0 d-none d-sm-block d-xl-block d-lg-block">
                                    {this.getAttrJsx('firstname', "")}&nbsp;
                                    <span className="profile-name-india mb-0" style={this.getValueStyle('lastname')}>
                                        {this.getValue('lastname')}
                                        {this.getValueStyleButton('lastname')}
                                    </span>
                                    {/* <span className="text-secondary">
                                        {this.getAttrJsx('age', "")}
                                    </span> */}
                                </h1>
                                <h1 className="mb-0 d-block d-sm-none d-xl-none d-lg-none">
                                    {this.getAttrJsx('firstname', "")}
                                    <p className="profile-name-india mb-0" style={this.getValueStyle('lastname')}>
                                        {this.getValue('lastname')}
                                        {this.getValueStyleButton('lastname')}
                                        {/* <span className="text-secondary">
                                            {this.getAttrJsx('age', "")}
                                        </span> */}
                                    </p>

                                </h1>

                                {this.getValue('company') !== "" ? <h4 className="mb-0">
                                    {this.getAttrJsx('title', "")}&nbsp;|&nbsp;{this.getAttrJsx('company', "")}
                                </h4>
                                    : this.getValue('cityOrState') ?
                                        <h4 className="mb-0">
                                            {this.getAttrJsx('title', "")}&nbsp;|&nbsp;{this.getAttrJsx('cityOrState', "")}
                                        </h4>
                                        :
                                        this.getCountryJSX()
                                }
                                <Spacer val="3" />
                                <div className="resume-content bio-resume-field">
                                    {/* <div>
                                        {this.getAttrWithLabelJsx('birthDate')}
                                    </div>
                                    <div>
                                        {this.getAttrWithLabelJsx('birthplace')}
                                    </div>
                                    {this.getAttrWithLabelJsx('dateAppointed') == "" ? <> </> : <Spacer val="3" />}
                                    <div>
                                        {this.getAttrWithLabelJsx('dateAppointed')}
                                    </div> */}
                                    <div>
                                        {this.getAttrWithLabelJsx('residence')}
                                    </div>
                                    <div>
                                        {this.getAttrWithLabelJsx('age')}
                                    </div>
                                    {this.getAttrWithLabelJsx('residence') == "" || this.getAttrWithLabelJsx('age') == "" ? <> </> : <Spacer val="3" />}
                                    {/* <Spacer val="3" /> */}
                                    <div>
                                        {this.getAttrWithLabelJsx('since')}
                                    </div>
                                    <div>
                                        {this.getAttrWithLabelJsx('hq')}
                                    </div>
                                    {/* <Spacer val="3" /> */}
                                    {this.getAttrWithLabelJsx('since') == "" || this.getAttrWithLabelJsx('hq') == "" ? <> </> : <Spacer val="3" />}

                                    {/* <div className="mb-0">As of February 24, 2020</div> */}
                                    <div>
                                        {this.getAttrWithLabelJsx('marketCap')}
                                    </div>
                                    <div>
                                        {this.getAttrWithLabelJsx('revenue')}
                                    </div>
                                    <div>
                                        {this.getAttrWithLabelJsx('netIncome')}
                                    </div>
                                    {/* <Spacer val="3" /> */}
                                    {this.getAttrWithLabelJsx('marketCap') == "" || this.getAttrWithLabelJsx('revenue') == "" || this.getAttrWithLabelJsx('netIncome') == "" ? <> </> : <Spacer val="3" />}

                                    {/* <blockquote className="blockquote bg-light rounded">
                                        <p className="mb-0 text-muted">
                                            {this.getAttrJsx('description', "")}
                                        </p>
                                        <p className="mb-0 text-muted">
                                            {this.getAttrJsx('description_2', "")}
                                        </p>
                                    </blockquote> */}
                                    <div className="lst-descritption" style={bgImage, imageBackgroundColor}>
                                        {/* <img src={require('../../../../../assets/img/s3.png')} className="card-img" alt="..." /> */}
                                        {/* <img src={this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)} className="card-img" alt="..." /> */}
                                        <div className="align-items-center justify-content-start d-flex row align-content-center  pl-4 pr-4  pl-sm-4 pr-sm-4 pl-md-4 pr-md-4   pl-lg-5 pr-lg-5  pl-xl-5 pr-xl-5 pt-4 pb-4">
                                            <p className="mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description', "")}
                                            </p>
                                            <p className="mt-2 mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description_2', "")}
                                            </p>
                                            <p className="mt-2 mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description_3', "")}
                                            </p>

                                            {this.props?.activeBioQuote &&
                                                <>
                                                    <div className="card mt-4 d-lg-none d-block">
                                                        <ModalImage
                                                            small={this.props.activeBioQuote.url}
                                                            large={this.props.activeBioQuote.url}
                                                            // small={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FIndiaspora_Quote%20Wall_Landscape%20(7).png?alt=media&token=54e821dd-bb57-4052-9771-84e99841ddec"}
                                                            // lasge={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FIndiaspora_Quote%20Wall_Landscape%20(7).png?alt=media&token=54e821dd-bb57-4052-9771-84e99841ddec"}
                                                            // small={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FAnant%20Bhalla.png?alt=media&token=92ea6c36-d10a-4807-8899-ff5c49ab2f5c"}
                                                            // large={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FAnant%20Bhalla.png?alt=media&token=92ea6c36-d10a-4807-8899-ff5c49ab2f5c"}
                                                            alt=""
                                                            showRotate={false}
                                                            hideDownload={true}
                                                            hideZoom={true}
                                                        />
                                                    </div>
                                                    <div className="card mt-4 quote-img-container d-none d-sm-none d-lg-block d-xl-block">
                                                        <ModalImage
                                                            small={this.props.activeBioQuote.url}
                                                            large={this.props.activeBioQuote.url}
                                                            // small={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FIndiaspora_Quote%20Wall_Landscape%20(7).png?alt=media&token=54e821dd-bb57-4052-9771-84e99841ddec"}
                                                            // lasge={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FIndiaspora_Quote%20Wall_Landscape%20(7).png?alt=media&token=54e821dd-bb57-4052-9771-84e99841ddec"}
                                                            // small={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FAnant%20Bhalla.png?alt=media&token=92ea6c36-d10a-4807-8899-ff5c49ab2f5c"}
                                                            // large={"https://firebasestorage.googleapis.com/v0/b/indiaspora-quotes-prod/o/images%2FAnant%20Bhalla.png?alt=media&token=92ea6c36-d10a-4807-8899-ff5c49ab2f5c"}
                                                            alt=""
                                                            showRotate={false}
                                                            hideDownload={true}
                                                            hideZoom={true}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            {/* {this.props?.activeBioQuote && <img src={this.props.activeBioQuote.url} className="card-img-top" />} */}
                                        </div>
                                    </div>
                                    <div>
                                        <SEButton sekey={"bioDescImg:css"} type={"img"}></SEButton>
                                    </div>
                                    {/* <div className="mt-2">
                                        <h5><a target="_blank" href={this.props?.activeBio?.link ? this.props?.activeBio?.link : this.dummyProfile.link}>More Info</a></h5>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lx-1 col-lg-1 pr-0 pl-0 text-center">
                        <Slider />
                    </div>
                </div>
            </div >

        );
    }
}


export default connect(mapStateToProps)(withRouter(DetailBody));
