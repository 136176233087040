import React from 'react';
import { connect } from "react-redux";
import Navbar from './components/navbar';
import SubNavbar from './components/sub-navbar'
import VideoConatainer from './components/video-container'
import ListDescription from './components/list-description'
import ListViewConatiner from './components/list-view/list-view-container'
import Footer from './components/footer'
import MapChart from './components/mapContainer';
import { hasCountryMeta, selectCache, selectVideoId } from '../../redux/list-config.selector';
import CarouselContainer from './carousel/carousel-container';
import phil2021Testimonials from './carousel/testimonials/phil-testimonials-2021.data'
import ImageConatainer from './components/image-container';

const mapStateToProps = (state) => {
    return {
        hasCountryMeta: hasCountryMeta(state),
        videoId: selectVideoId(state),
        cache: selectCache(state),
    }
}
class HomeLay extends React.Component {
    render() {
        return (
            <div className="mainContainer">
                <Navbar />
                <SubNavbar />
                {this.props?.cache?.needsImage && <ImageConatainer imageId={require("../../../assets/img/social-entrepreneur-list-new.jpeg")} />}
                {this.props?.cache?.needsVideo && <VideoConatainer videoId={this.props.videoId} />}
                {this.props.hasCountryMeta && <MapChart />}
                {this.props?.cache?.needsTestimonial && <CarouselContainer phrases={phil2021Testimonials} />}
                {this.props?.cache?.needsMainPageDescription && <div className={this.props.hasCountryMeta === false ? "mt-5 mb-5" : "mt-2 mb-5"}>
                    <ListDescription />
                </div>}
                <ListViewConatiner />
                <Footer />
            </div>
        );
    }
}

export default connect(mapStateToProps)(HomeLay);
