import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import {
    Annotation,
    ComposableMap,
    Geographies,
    Geography,
    Graticule,
    Marker
} from "react-simple-maps";
import { loadActiveCountry } from "../../../listdetails/listdetails-rx";
import { setFromWhere } from "../../../redux/templ";
import { AnnotationLabel } from "react-annotation";
import { selectCountryListDataByRSMKey, selectCountryListData } from "../../../redux/country.selector";
import { selectUUIDAndYear } from "../../../redux/list-config.selector";
import world from "./worldmap.json";

const geoUrl =
    // "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-50m.json";

function createClass(name, rules) {
    var style = document.createElement('style');
    style.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(style);
    if (!(style.sheet || {}).insertRule)
        (style.styleSheet || style.sheet).addRule(name, rules);
    else
        style.sheet.insertRule(name + "{" + rules + "}", 0);
}
const MapChart = ({ uuidAndYear, history, countryData, countryListData }) => {
    // console.log("MAPCONTAONER:", countryData)
    // console.log("MAPCONTAONER:", uuidAndYear)
    const { uuid, year } = uuidAndYear;
    useEffect(() => {
        // console.log("MAPCONTAONER useEffect:", countryListData)
        if (countryListData) {
            countryListData.forEach(country => {
                const className = `.country-bg-${country.metaKey} .annotation-note-bg`
                const rule = `height: 23px; fill: ${country.mapColor}; fill-opacity: 1;`
                createClass(className, rule);
            })
        }
        // createClass(".country-bg-guyana .annotation-note-bg", "height: 23px; width: 101px; fill: #3aa7de; fill-opacity: .5;")
    }, [countryListData])
    const handleClick = (geo) => {
        // console.log("Geo:", geo.rsmKey);
        const country = countryData[geo.rsmKey]
        gotoCountry(country.metaKey);
    }
    const gotoCountry = (country) => {
        loadActiveCountry(country);
        setFromWhere('local');
        let url = `/${uuid}/${year}/country/${country}`;
        history.push(url);
    }
    return (
        <div className="container">
            <ComposableMap width="800" height="500" projectionConfig={{
                scale: 152,
            }}>
                <Graticule stroke="#EAEAEC" />
                <Geographies geography={world}>
                    {({ geographies }) =>
                        geographies.map(geo => {
                            // console.log("hhhhhhhhhhhhhhhh", geo, geo.properties, geo.rsmKey);
                            let color = "gray";
                            const country = countryData && countryData[geo.rsmKey]
                            if (country)
                                return <Geography key={geo.rsmKey} geography={geo}
                                    fill={country.mapColor}
                                    fill-opacity="0.6"
                                    stroke="#ffff"
                                    style={{ hover: { cursor: "pointer", "fill-opacity": "1" } }}
                                    onClick={() => handleClick(geo)}
                                />
                            else
                                return <Geography key={geo.rsmKey} geography={geo}
                                    fill={color}
                                    fill-opacity="0.6"
                                    stroke="#ffff"
                                />
                        })}
                </Geographies>
                {
                    countryListData && countryListData.map(country => {
                        // console.log("Annotation label", country)
                        return <AnnotationLabel
                            x={country.x}
                            y={country.y}
                            dx={country.nx}
                            dy={country.ny}
                            color={"#afb1b3"}
                            editMode={false}
                            className={`country-bg-${country.metaKey} hand-cursor`}
                            note={{
                                "title": country.name,
                                "align": "middle",
                                "orientation": "topBottom",
                                "bgPadding": { left: 7, right: 5 },
                                "padding": 0,
                                "titleColor": "white"
                            }}
                            events={{
                                onClick: () => gotoCountry(country.metaKey)
                            }}
                        />
                    })
                }
                {/* <Marker coordinates={[-61.222504, 10.691803]} fill="#777">
                    <circle r={5} fill="#100105" stroke="#fff" strokeWidth={0} />
                </Marker>
                <Marker coordinates={[160.065033, -33.713371]} fill="#777">
                    <circle r={5} fill="#3AA7DE" stroke="#fff" strokeWidth={0} />
                </Marker>
                <Marker coordinates={[103.819839, 1.352083]} fill="#777">
                    <circle r={5} fill="#D42441" stroke="#fff" strokeWidth={0} />
                </Marker>
                <Marker coordinates={[57.552151, -20.348404]} fill="#777">
                    <circle r={5} fill="#211780" stroke="#fff" strokeWidth={0} />
                </Marker> */}
            </ComposableMap>
        </div >
    );
};

const mapStateToProps = (state) => {
    return {
        uuidAndYear: selectUUIDAndYear(state),
        countryData: selectCountryListDataByRSMKey(state),
        countryListData: selectCountryListData(state),
    }
}

export default connect(mapStateToProps)(withRouter(MapChart));
