import React from "react";
import { Dropdown } from 'react-bootstrap'
import { BsFillGridFill } from 'react-icons/bs'
import GridView from './grid-view'
import CategoryGridView from './category-grid-view'
import NewTable from './new-table'
import { AiOutlineTable } from 'react-icons/ai'
import { connect } from "react-redux";
import { setSearchValue } from '../../../../redux/index'
import { setActiveTableOrGridView } from '../../../../redux/templ'
import { filteredCategoryGridData, selectCategoryDropdown } from "../../../../redux/country.selector";
import { selectCache } from "../../../../redux/list-config.selector";
import { withRouter } from 'react-router';

const mapStateToProps = (state) => {
    return {
        categoryGridData: filteredCategoryGridData(state),
        categoryDropdown: selectCategoryDropdown(state),
        cache: selectCache(state),
        listData: state.application.listData,
        categoryColumn: state.application.listingType?.categoryColumn,
        homeLayUtils: state.application.homeLayUtils,
        activeTableOrGridView: state.tempData.activeTableOrGridView,
        searchItem: state.tempData?.searchItem,
        searchCategoryItem: state.tempData?.searchCategoryItem
    }
}
class ListViewConatiner extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            selectedDrops: 'All'
        }
    }
    onInputChanged = (e) => {
        setSearchValue(e.target.value, false);
    }
    onDdClicked = (ele) => {
        this.setState({ ...this.state, ...{ selectedDrops: ele } })
        if (ele === 'All')
            ele = ' '
        setSearchValue(ele, true);
    }
    getData = () => {
        if(this.props?.categoryDropdown){
            return this.props.categoryDropdown
        }
        if (this.props?.homeLayUtils?.getDataByClmn) {
            const column = this.props?.cache?.categoryColumn;
            let dt = this.props?.homeLayUtils?.getDataByClmn(column, this.props.listData);
            if(this.props?.cache?.sortCategoriesDropdown)
                dt = dt.sort((a, b) => a.localeCompare(b))
            return dt
        }
    }
    changeView = () => {
        if (this.props.activeTableOrGridView === 'grid') {
            setActiveTableOrGridView('table')
        } else {
            setActiveTableOrGridView('grid')
        }
    }
    render() {
        const isGovt = this.props?.match?.params?.uuid === "governmentLeaders";
        return (
            <div className="container-fluid  pb-3 pb-sm-0 pb-md-4 pb-lg-4 pb-xl-4 pr-3 pr-sm-0 pr-md-4 pr-lg-4 pr-xl-4 pl-3 pl-sm-0 pl-md-4 pl-lg-4 pl-xl-4 mb-5">
                <div className="row  justify-content-around align-items-center">
                    {/* <div className="col-12 text-center mb-3">
                        <h4 className="font-weight-bold text-secondary">2020<span className="font-weight-light"><a className="text-secondary" href="#"> INDIASPORA BUSINESS LEADER LISTS</a></span></h4>
                    </div> */}
                    <div className="col-12 m-3">
                        <div className="row  justify-content-center align-items-center">
                            <div className="col-9 col-sm-4 col-md-4 col-lg-4 col-xl-4 order-1 order-2 order-sm-1 order-md-1 order-xl-1 order-lg-1 text-right">
                                <Dropdown>
                                    <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                                        Categories:&nbsp;{this.props.searchCategoryItem ? this.props.searchCategoryItem : this.state.selectedDrops}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {this.getData().map((ele, index) => {
                                            return <Dropdown.Item onClick={() => this.onDdClicked(ele)} key={index}>{ele}</Dropdown.Item>
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="col-12  col-sm-5 col-md-5 col-lg-5 col-xl-5 order-3  order-sm-2 order-md-2 order-xl-2 order-lg-2 text-start mt-2">
                                <div className="row align-items-center">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                        <input className="form-control mr-sm-2 w-100" 
                                               id="myInput" type="search" placeholder="Search" aria-label="Search" 
                                               onChange={this.onInputChanged} value={this.props.searchItem} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 order-2 order-sm-3 order-md-3 order-xl-3 order-lg-3 text-start">
                                <button className="btn btn-outline-primary d-flex" onClick={this.changeView}>
                                    {this.props.activeTableOrGridView === 'grid' ? <AiOutlineTable /> : <BsFillGridFill />}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // this.props.activeTableOrGridView === 'grid' ? <GridView /> : <NewTable />
                }
                <div hidden={this.props.activeTableOrGridView !== 'grid'}>
                    {
                        isGovt && this.props.categoryGridData && 
                        this.props.categoryGridData.map(gridData => <CategoryGridView key={gridData.id} gridData={gridData} />)
                    }
                    { !isGovt && !this.props.categoryGridData && <GridView />}
                </div>
                <div hidden={this.props.activeTableOrGridView !== 'table'} >
                    <NewTable/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(withRouter(ListViewConatiner));