import React from 'react';
import ProfileLay from '../first-layout/profile'


class ListingLayOne extends React.PureComponent {
    render() {
        return (
            <div>
                <ProfileLay></ProfileLay>
            </div>
        );
    }
}


export default ListingLayOne;