import React from 'react';
import AboutContent from './about-content'
import Navbar from '../first-layout/components/navbar';
import SubNavbar from '../first-layout/components/sub-navbar';
import Footer from '../first-layout/components/footer';

class AboutLayout extends React.Component {
    render() {
        return (
            <div className="mainContainer">
                <Navbar />
                <SubNavbar />
                <AboutContent />
                <Footer />
            </div>
        );
    }
}

export default AboutLayout;