import { createSelector } from 'reselect'
import { findWhere } from 'underscore';
import { getFilterData } from './templ';

const application = state => state.application;
const tempData = state => state.tempData;

export const selectSearchItem = createSelector(
    [tempData],
    (tempData) => {
        if(!tempData)
            return null;
        return tempData.searchItem;
    }
)
export const selectSearchCategoryItem = createSelector(
    [tempData],
    (tempData) => {
        if(!tempData)
            return null;
        return tempData.searchCategoryItem;
    }
)
export const selectCategoryDropdown = createSelector(
    [application],
    (application) => {
        if(!application)
            return null;
        return application.categoryDropdown;
    }
)
export const selectActiveCountry = createSelector(
    [application],
    (application) => {
        console.log("selectActiveCountry Selector", application.activeCountry)
        return application.activeCountry;
    }
)

export const selectCountryListId = createSelector(
    [application],
    (application) => {
        console.log("selectCountryListId Selector", application.list)
        const {list} = application;
        if(!list)
            return null;
        const childList = findWhere(list.childLists, {type: "country"})
        return childList.listId;
    }
)

export const selectCountryListData = createSelector(
    [application, selectCountryListId],
    (application, selectCountryListId) => {
        console.log("selectCountryListData Selector", selectCountryListId)
        if(!selectCountryListId)
            return null;
        const countryListData = application[`childListData${selectCountryListId}`];
        if(!countryListData)
            return null;
        return countryListData.data;
    }
)

export const selectCountryListDataByRSMKey = createSelector(
    [application, selectCountryListId],
    (application, selectCountryListId) => {
        console.log("selectCountryListData Selector", selectCountryListId)
        if(!selectCountryListId)
            return null;
        const countryListData = application[`childListData${selectCountryListId}`];
        if(!countryListData)
            return null;
        const data = {}
        countryListData.data.forEach(country => data[country.rsmKey] = country)
        return data;
    }
)

export const selectCountryListType = createSelector(
    [application, selectCountryListId],
    (application, selectCountryListId) => {
        console.log("selectCountryListType Selector", selectCountryListId)
        if(!selectCountryListId)
            return null;
        const countryList = application[`childList${selectCountryListId}`];
        if(!countryList)
            return null;
        const listingTypeId = countryList.listingTypeId;
        const countryListtype = application[listingTypeId];
        return countryListtype;
    }
)

export const selectActiveCountryData = createSelector(
    [selectCountryListData, selectActiveCountry],
    (selectCountryListData, selectActiveCountry) => {
        console.log("selectActiveCountryData Selector", selectCountryListData, selectActiveCountry)
        const countryData = findWhere(selectCountryListData, {metaKey: selectActiveCountry})
        return countryData;
    }
)

const selectCategoryGridData = createSelector(
    [application],
    (application) => {
        console.log("selectCategoryGridData Selector", application.categoryGridData)
        return application.categoryGridData;
    }
)

export const selectCountryCategoryGridData = createSelector(
    [selectActiveCountry, selectCategoryGridData],
    (activeCountry, categoryGridData) => {
        if(!categoryGridData)
            return null;
        console.log("selectCategoryGridData Selector", activeCountry, categoryGridData)
        const countryCategoryGridData = [];
        categoryGridData.forEach((catGridData) => {
            const subCategories = catGridData.dataWithSubCategories;
            if(subCategories){
                const newSubCategories = [];
                subCategories.forEach(subCat => {
                    const filteredData = subCat?.data?.filter(data => data.countryMeta == activeCountry)
                    if(filteredData && filteredData.length > 0){
                        const newCat = {...subCat}
                        newCat.data = filteredData;
                        newSubCategories.push(newCat);
                    }
                })
                if(newSubCategories.length > 0){
                    countryCategoryGridData.push({
                        ...catGridData,
                        dataWithSubCategories: newSubCategories
                    })
                }
            }
        })
        console.log("selectCategoryGridData Selector return,", countryCategoryGridData)
        return countryCategoryGridData;
    }
)

export const filteredCategoryGridData = createSelector(
    [selectSearchItem, selectSearchCategoryItem, selectCategoryGridData],
    (searchItem, category, categoryGridData) => {
        if(!categoryGridData)
            return null;

        if(!searchItem && !category)
            return categoryGridData;
        else {
            const newCategoryGridData = [];
            if(category){
                categoryGridData.forEach(catGridData => {
                    const subCategories = catGridData.dataWithSubCategories;
                    if(subCategories){
                        const newSubCategories = [];
                        subCategories.forEach(subCat => {
                            if(subCat.subCategory === category){
                                if(searchItem){
                                    const subCatData = subCat.data;
                                    const filteredData = getFilterData(subCatData, searchItem)
                                    if(filteredData && filteredData.length > 0){
                                        newSubCategories.push({
                                            subCategory: subCat.subCategory,
                                            data: filteredData
                                        })
                                    }
                                } else {
                                    newSubCategories.push({...subCat});
                                }
                            }
                        })
                        if(newSubCategories.length > 0){
                            newCategoryGridData.push({
                                ...catGridData,
                                dataWithSubCategories: newSubCategories
                            })
                        }
                    }
                })
            } else {
                categoryGridData.forEach(catGridData => {
                    const subCategories = catGridData.dataWithSubCategories;
                    if(subCategories){
                        const newSubCategories = [];
                        subCategories.forEach(subCat => {
                            const subCatData = subCat.data;
                            const filteredData = getFilterData(subCatData, searchItem)
                            if(filteredData && filteredData.length > 0){
                                newSubCategories.push({
                                    subCategory: subCat.subCategory,
                                    data: filteredData
                                })
                            }
                        })
                        if(newSubCategories.length > 0){
                            newCategoryGridData.push({
                                ...catGridData,
                                dataWithSubCategories: newSubCategories
                            })
                        }
                    }
                })
            }
            return newCategoryGridData;
        }
    }
)

