import React from "react";
import { IoMdArrowRoundBack } from 'react-icons/io'
import { withRouter } from 'react-router';
import SEButton from "../../../common/styles-editor/SE-button";
import { connect } from "react-redux";

const mapStateToProps = (state) => {
  return {
    homeLayUtils: state.application.homeLayUtils,
    applicationMode: state.application.mode,
    listMetaData: state.application.listMetaData
  }
}
class Navbar extends React.PureComponent {
  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="container-fluid mt-2">
        <div className="row align-items-center justify-content-center mt-2 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 ie-issue-logo">
          <div className="logo-container">
            <div  className={`${this.props.isBio ? "logo-subcontainer logo-subcontainer-ie" : "logo-subcontainer"}`}>
              <a href="http://indiaspora.org">
                <img className={`${this.props.isBio ? "logo-landing-bio" : "logo-landing"}`}
                  src={require('../../../../assets/img/logo-square.jpg')} alt="Indiaspora" />
              </a>
            </div>
          </div>
          <div className="col-6 header-bg-left pt-2 pb-2"
            style={this.props.homeLayUtils.getStyle('navbarLeftDiv:css', this.props.listMetaData, this.props.applicationMode)}>
            <SEButton sekey={"navbarLeftDiv:css"} type={"div"}></SEButton>
            {
              this.props.showBack ?
                <h3 className="mb-0 text-primary d-lg-none d-block"><IoMdArrowRoundBack onClick={this.goBack} /></h3>
                :
                <h3 className="mb-0 text-primary d-lg-none d-block invisible"><IoMdArrowRoundBack onClick={this.goBack} /></h3>
            }
            <h3 className="mb-0 text-primary d-none d-sm-none d-lg-block d-xl-block invisible"><IoMdArrowRoundBack onClick={this.goBack} /></h3>
          </div>
          <div className="col-6 header-bg-right  text-right  pt-2 pb-2"
            style={this.props.homeLayUtils.getStyle('navbarLRightDiv:css', this.props.listMetaData, this.props.applicationMode)}>
            <SEButton sekey={"navbarLRightDiv:css"} type={"div"}></SEButton>
            <h3 className="mb-0 text-primary invisible"><IoMdArrowRoundBack onClick={this.goBack} /></h3>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(Navbar))