import { store } from '../store'
//enums
export const LOAD_LIST = 'LOAD_LIST';
export const LOAD_CHILD_LIST = 'LOAD_CHILD_LIST';
export const LOAD_LIST_DATA = 'LOAD_LIST_DATA';
export const LOAD_CHILD_LIST_DATA = 'LOAD_CHILD_LIST_DATA';
export const LOAD_CATEGORY_GRID_DATA = 'LOAD_CATEGORY_GRID_DATA';
export const LOAD_CATEGORY_DROPDOWN = 'LOAD_CATEGORY_DROPDOWN';
export const REMOVE_CATEGORY_GRID_DATA = 'REMOVE_CATEGORY_GRID_DATA';
export const LOAD_LIST_META_DATA = 'LOAD_LIST_META_DATA';
export const LOAD_LISTING_TYPE = 'LOAD_LISTING_TYPE'
export const LOADING_CONSUMER_DATA = 'LOADING_CONSUMER_DATA'
export const LOAD_CHILD_LISTING_TYPE = 'LOAD_CHILD_LISTING_TYPE'
export const LOAD_LISTING_TYPES = 'LOAD_LISTING_TYPES'
export const UPDATE_LIST = 'UPDATE_LIS'
export const LOAD_ACTIVE_BIO = 'LOAD_ACTIVE_BIO'
export const LOAD_ACTIVE_BIO_QUOTE = 'LOAD_ACTIVE_BIO_QUOTE'
export const REMOVE_ACTIVE_BIO_QUOTE = 'REMOVE_ACTIVE_BIO_QUOTE'
export const LOAD_ACTIVE_COUNTRY = 'LOAD_ACTIVE_COUNTRY'
//actions creators
const loadListAc = (payload) => {
    return {
        type: LOAD_LIST,
        payload
    }
}
const loadChildListAc = (payload) => {
    return {
        type: LOAD_CHILD_LIST,
        payload
    }
}
const loadListDataAc = (payload) => {
    return {
        type: LOAD_LIST_DATA,
        payload
    }
}
const loadChildListDataAc = (payload) => {
    return {
        type: LOAD_CHILD_LIST_DATA,
        payload
    }
}
const loadCategoryDropdownAc = (payload) => {
    return {
        type: LOAD_CATEGORY_DROPDOWN,
        payload
    }
}
const loadCategoryGridDataAc = (payload) => {
    return {
        type: LOAD_CATEGORY_GRID_DATA,
        payload
    }
}
const removeCategoryGridDataAc = () => {
    return {
        type: REMOVE_CATEGORY_GRID_DATA
    }
}
const loadListMetaDataAc = (payload) => {
    return {
        type: LOAD_LIST_META_DATA,
        payload
    }
}
const loadListingTypeAc = (payload) => {
    return {
        type: LOAD_LISTING_TYPE,
        payload
    }
}
const loadingConsumerDataAc = (payload) => {
    return {
        type: LOADING_CONSUMER_DATA,
        payload
    }
}
const loadChildListingTypeAc = (payload) => {
    return {
        type: LOAD_CHILD_LISTING_TYPE,
        payload
    }
}
const loadListingTypesAc = (payload) => {
    return {
        type: LOAD_LISTING_TYPES,
        payload
    }
}
const updateListAc = (payload) => {
    return {
        type: UPDATE_LIST,
        payload
    }
}
const loadActiveBioAc = (payload) => {
    return {
        type: LOAD_ACTIVE_BIO,
        payload: payload
    }
}
const loadActiveBioQuoteAc = (payload) => {
    return {
        type: LOAD_ACTIVE_BIO_QUOTE,
        payload: payload
    }
}
const removeActiveBioQuoteAc = () => {
    return {
        type: REMOVE_ACTIVE_BIO_QUOTE
    }
}
const loadActiveCountryAc = (payload) => {
    return {
        type: LOAD_ACTIVE_COUNTRY,
        payload: payload
    }
}

// actions
export const loadList = (listDetails) => {
    store.dispatch(loadListAc(listDetails))
}
export const loadChildList = (listDetails) => {
    store.dispatch(loadChildListAc(listDetails))
}
export const loadListData = (dats) => {
    store.dispatch(loadListDataAc(dats))
}
export const loadChildListData = (dats) => {
    store.dispatch(loadChildListDataAc(dats))
}
export const loadCategoryDropdown = (data) => {
    store.dispatch(loadCategoryDropdownAc(data));
}
export const loadCategoryGridData = (data) => {
    store.dispatch(loadCategoryGridDataAc(data));
}
export const removeCategoryGridData = () => {
    store.dispatch(removeCategoryGridDataAc());
}
export const loadListMetaData = (dats) => {
    store.dispatch(loadListMetaDataAc(dats))
}

export const loadingConsumerData = (payload) => {
    store.dispatch(loadingConsumerDataAc(payload))
}
export const loadListingType = (listingType) => {
    store.dispatch(loadListingTypeAc(listingType))
}
export const loadChildListingType = (listingType) => {
    store.dispatch(loadChildListingTypeAc(listingType))
}
export const loadListingTypes = (listingTypes) => {
    store.dispatch(loadListingTypesAc(listingTypes))
}
export const updateList = (payload) => {
    store.dispatch(updateListAc(payload))
}
export const loadActiveBio = (payload) => {
    store.dispatch(loadActiveBioAc(payload))
}
export const loadActiveBioQuote = (payload) => {
    store.dispatch(loadActiveBioQuoteAc(payload))
}
export const removeActiveBioQuote = () => {
    store.dispatch(removeActiveBioQuoteAc())
}
export const loadActiveCountry = (payload) => {
    store.dispatch(loadActiveCountryAc(payload))
}







//update

