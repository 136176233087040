import firebase from '../firebase';
import { store } from '../store';
import { setSubdomainsAc } from './subdomains-rx';
import { sortBy } from 'underscore'

export const getSubdomains = async () => {
    try {
        const db = firebase.firestore();
        let snap = await db.collection('subDomains').get();
        let data = [];
        snap.forEach((inQryDoc) => {
            let dwithId = inQryDoc.data();
            dwithId.id = inQryDoc.id;
            data.push(dwithId)
        })
        data = sortBy(data, function (o) { return o.order; })
        store.dispatch(setSubdomainsAc(data))
        return data;
    } catch (error) {
        return error
    }

}


