//enums
export const SET_SUBDOMAINS = 'SET_SUBDOMAINS'

//action creators
export const setSubdomainsAc = (payload) => {
    return {
        type: SET_SUBDOMAINS,
        payload
    }
}
//reducer function => helper
export const sdbRFHelper = (state, action) => {
    let obj = { subdomains: action.payload };
    let newState = { ...state, ...obj };
    return newState;
}
