import React from "react";
import '../../../scss/testimonial.scss';
import TextCarousel from "./text-carousel";
import { ImQuotesLeft } from 'react-icons/im'

const CarouselContainer = (props) => {
    let largestPhrase = undefined;
    props.phrases.forEach(phrase => {
        if(!largestPhrase)
            largestPhrase = phrase;
        else {
            if(phrase.quote.length > largestPhrase.quote.length)
                largestPhrase = phrase;
        }
    });
    return (
        <div className="container-fluid mt-5 mb-4 testimonial-container">
            <div className="container color-dark">
                <div>
                    <p className="text-center pb-4 testimonial-color">
                        <ImQuotesLeft size={48} />
                    </p>
                </div>
                <div className="position-relative">
                    <p className="text-center testimonial testimonial-hide-color">{largestPhrase.quote}</p>
                    <div className="position-absolute top-left-0">
                        <TextCarousel phrases={props.phrases} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CarouselContainer;