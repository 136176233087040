import React from 'react';
import '../../../scss/juror.scss';
import Juror from './juror';

class JurorContainer extends React.Component {

    render() {
        return (
            <div className="row mt-5 mb-5">
                <div className="col-12">
                    <div className="d-block">
                        <span className="country-name pb-1">
                            <span >{this.props.jurors.country}</span>
                        </span>
                    </div>
                    {this.props.jurors && this.props.jurors.jurors.map(juror => <Juror juror={juror} folder={this.props.folder}/>)}
                </div>
            </div>
        );
    }
}

export default JurorContainer;