import firebase from '../firebase';
import { loadNews } from './news-rx';
import { store } from "../store";

const state = store.getState();
const appMode = state?.application?.mode;
const db = firebase.firestore();
const PAGE_SIZE = 50;

export const getNews = async (listId, moreNews, applicationMode,newsCount) => {
    if (!moreNews) {   // For first time news fetch.
        try {
            let snap;
            let data = [];
            if(applicationMode === 'admin') {
                snap = await db.collection('lists').doc(listId).collection('news').orderBy('timestamp', 'desc').get();
            } else {
                snap = await db.collection('lists').doc(listId).collection('news').where("active", "==", "true").orderBy('timestamp', 'desc').limit(PAGE_SIZE).get();
            }                                   
            snap.forEach((inQryDoc) => {
                let dwithId = inQryDoc.data();
                dwithId.id = inQryDoc.id;
                data.push(dwithId);
            })
            if(applicationMode === 'admin') {
                loadNews(data, false);
            } else {
                loadNews(data, true);
            }                                   
        } catch (error) {
            console.log(error);
            return error
        }
    } else {    // For fetching more news.
        try {
            let first = db.collection('lists').doc(listId)
                .collection('news')
                .where("active", "==", "true")
                .orderBy('timestamp', 'desc')
                .limit(newsCount);
            
            first.get()
                .then(async (snapshot) => {
                    // Get the last document
                    let last = snapshot.docs[snapshot.docs.length - 1];
                    // Construct a new query starting at this document.
                    let next = await db.collection('lists').doc(listId)
                        .collection('news')
                        .orderBy('timestamp', 'desc')
                        .startAfter(last)
                        .where("active", "==", "true")
                        .limit(PAGE_SIZE)
                        .get();
                    // Use the query for pagination
                    let data = [];
                    next.forEach((inQryDoc) => {
                        let dwithId = inQryDoc.data();
                        dwithId.id = inQryDoc.id;
                        data.push(dwithId);
                    })
                    if(data?.length > 0) {
                        loadNews(data, true)
                    } else {
                        loadNews(data, false)
                    }
                });
        } catch (error) {
            console.log(error);
            return error
        }

    }
}
export const addNews = async (listId, payload) => {
    let res = await db.collection('lists').doc(listId).collection('news').add(payload);
    getNews(listId, false, 'admin');
    return res;
}
export const updateNews = async (listId, newsId, data) => {
    let snap = await db.collection('lists').doc(listId).collection('news').doc(newsId).update(data)
    getNews(listId, false, 'admin');
    return snap;
}
export const deleteNews = async (listId, newsId) => {
    let snap = await db.collection('lists').doc(listId).collection('news').doc(newsId).delete()
    getNews(listId, false, 'admin');
    return snap;
}

