import React from 'react';
import { Button } from 'react-bootstrap';
import { getSubdomains } from './subdomains-fs'
import { connect } from 'react-redux';
const mapStateToProps = (state) => {
    return {
        application: state.application,
        subdomains: state.application.subdomains
    }
}
class Subdomains extends React.Component {
    componentDidMount = async () => {
        getSubdomains();
    }

    toListingTypes = (subdomain) => {
        let url = '/subdomains/'+subdomain+'/listingTypes';
        this.props.history.push(url);
    }

    render() {
        return (
            <div className="container mt-5">
                <div className="row justify-content-center align-items-center">
                    <div className="card bg-aqua col-6 text-center p-0">
                        <div className="card-header">
                            <h3>Choose a Sub Domain to configure the lists</h3>
                        </div>
                        {this.props?.subdomains?.map((ele, index) => {
                            return <div key={index} >
                                <ul className="list-group list-group-flush p-4">
                                    <li className="list-group-item">
                                        <Button className="btn-block btn btn-primary btn-lg" onClick={() => this.toListingTypes(ele.id)}>{ele.label}</Button>
                                    </li>
                                </ul>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Subdomains);