import React from 'react';
import { Form, Button, Spinner, Alert, Modal } from 'react-bootstrap';
import { addNews, updateNews } from './news-fs';
import { scrape } from './image-scraper'
import { connect } from 'react-redux';
import { newsAddEditModal } from '../redux/templ'


const mapStateToProps = (state) => {
    return {
        newsModal: state.tempData.newsModal,
        list: state.application.list
    }
}
class AddEditNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {}
        }
    }
    handleClose = () => {
        newsAddEditModal({ show: false, type: null })
    }
    onInputChange = (e) => {
        let obj = {};
        if (e.target.id === 'newsUrl') {
            this.setState({ ...this.state, ...{ scrapping: true } })
            scrape(e.target.value, (err, meta) => {
                if (!err) {
                    this.setState({ ...this.state, ...{ ogImage: meta.data.ogImage } })
                }
                this.setState({ ...this.state, ...{ scrapping: false } })
            })
        }
        obj[e.target.id] = e.target.value;
        let newForm = { ...this.state.form, ...obj };
        this.setState({ ...this.state, ...{ form: newForm } });
    }
    onSubmit = async (e) => {
        e.preventDefault();
        this.setState({ ...this.state, ...{ validated: true } })
        if (e.target.checkValidity()) {
            let frm = { ...this.state.form };
            console.log(frm)
            frm.imageUrl = this.state.ogImage;
            frm.timestamp = new Date(frm.timestamp).getTime()
            this.setState({ ...this.state, ...{ saving: true } });
            let listId = this.props?.list?.id;
            try {
                if (this.props?.newsModal?.type === 'add') {
                    await addNews(listId, frm);
                    this.setState({})
                    this.handleClose();
                } else {
                    await updateNews(listId, frm.id, frm);
                    this.setState({})
                    this.handleClose();
                }
            } catch (error) {
                this.setState({ ...this.state, ...{ saving: false, msg: 'danger', msgbody: error.message } })
            }
        }
    }
    componentDidMount() {
        if (this.props?.newsModal?.type === 'edit') {
            let data = { ...this.props.newsModal.data };
            data.timestamp = new Date(this.props.newsModal.data.timestamp).toISOString().substring(0, 10)
            this.setState({
                ...this.state, ...{
                    form: data,
                    ogImage: this.props.newsModal.data.imageUrl
                }
            })
        } else {
            let newForm = { ...this.state.form, ...{ timestamp: new Date().toISOString().substring(0, 10) } };
            newForm.active = "true";
            newForm.primary = "true";
            this.setState({ ...this.state, ...{ form: newForm } })
        }
    }
    render() {
        return (
            <>
                <Modal show={this.props?.newsModal?.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props?.newsModal?.type === 'add' ? 'Add News' : 'Edit News'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={this.onSubmit} noValidate validated={this.state.validated}>
                            <Form.Group controlId="title">
                                <Form.Label>Title *</Form.Label>
                                <Form.Control type="text" className="form-control" required value={this.state?.form?.title} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter news title.
                        </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="subtitle">
                                <Form.Label>Subtitle *</Form.Label>
                                <Form.Control type="text" className="form-control" required value={this.state?.form?.subtitle} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter news subtitle.
                        </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="newsUrl" >
                                <Form.Label>News Url (dont type, paste URL)*</Form.Label>
                                <Form.Control className="form-control" required value={this.state?.form?.newsUrl} onChange={this.onInputChange} />
                                {this.state.scrapping ? <span>fetching preview</span> : ''}
                                {this.state?.ogImage ? <div style={{ padding: '10px' }}>
                                    <img height="100" width="100" src={this.state?.ogImage} alt=""/>
                                </div> : ''}
                            </Form.Group>
                            <Form.Group controlId="timestamp">
                                <Form.Label>Date *</Form.Label>
                                <Form.Control type="date" className="form-control" required value={this.state?.form?.timestamp} onChange={this.onInputChange} />
                                <Form.Control.Feedback type="invalid">
                                    Please enter date.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="active">
                                <Form.Label>Is Active ?</Form.Label>
                                <Form.Control as="select" custom value={this.state?.form?.active}  onChange={this.onInputChange}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="primary">
                                <Form.Label>Is Primary ?</Form.Label>
                                <Form.Control as="select" custom value={this.state?.form?.primary} onChange={this.onInputChange}>
                                    <option value="true">Yes</option>
                                    <option value="false">No</option>
                                </Form.Control>
                            </Form.Group>
                            <div className="row mt-3 justify-content-center">
                                <div className="col-6  text-center">
                                    {this.state?.saving ? <Button>
                                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            saving...
                        </Button> :
                                        <button type="submit" className="btn btn-primary btn-block text-white">SUBMIT</button>
                                    }
                                </div>
                            </div>
                            <br></br>
                            <Alert variant={this.state.msg} style={{ textAlign: 'center' }}>
                                {this.state.msgbody}
                            </Alert>
                        </Form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}



export default connect(mapStateToProps)(AddEditNews);