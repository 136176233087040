import firebase from '../firebase';
import { loadList, loadChildList, loadListData, loadListMetaData, updateList, loadListingType, 
        loadChildListingType, loadActiveBio, loadListingTypes, loadChildListData,
        loadCategoryGridData, removeCategoryGridData, loadingConsumerData,
        loadCategoryDropdown, loadActiveBioQuote, removeActiveBioQuote } from './listdetails-rx'
import { exportExcelCount } from "../redux/application";
import { store } from "../store";
import { batch } from 'react-redux';
import { sortBy } from 'underscore';
import { deletingDataRow, doneDeletingDataRow } from '../redux/templ';

const db = firebase.firestore();

export const getList = async (listId, isChild) => {
    let snap = await db.collection('lists').doc(listId).get();
    let obj = snap.data();
    obj.id = snap.id;
    if(isChild)
        loadChildList(obj)
    else 
        loadList(obj);
    return obj
}

export const getListWithUUIDLstTYID = async (listingTypeId, uuid) => {
    let snap = await db.collection('lists')
        .where("listingTypeId", "==", listingTypeId)
        .where("listingTypeUuid", "==", uuid).get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    loadList(data[0]);
    return data[0];
}
export const getDatas = async (listId, isChild) => {
    let snap = await db.collection('lists').doc(listId).collection('data').get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    if(isChild)
        loadChildListData({data: data, id: listId})
    else 
        loadListData(data);
    return data;
}
export const getMetaData = async (listId) => {
    let snap = await db.collection('lists').doc(listId).collection('metadata').get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    loadListMetaData(data);
    return 'done'
}
const getListingType = async (listingTypeId, isChild) => {
    let snap = await db.collection('listingTypes').doc(listingTypeId).get();
    let data = snap.data();
    data.id = snap.id;
    if(isChild)
        loadChildListingType(data)
    else 
        loadListingType(data);
    return data;
}

export const getListingTypes = async () => {
    let snap = await db.collection('listingTypes').get();
    let docs = snap.docs;
    const listTypes = docs.map((doc) => {
        return doc.data();
    })
    loadListingTypes(listTypes);
    return listTypes;
}

export const getData = async (listId, listingTypeId) => {
    batch(async () => {
        await getDatas(listId);
        const listData = await getList(listId);
        await getMetaData(listId);
        await getListingType(listingTypeId);
        console.log("*************************************************")
        console.log(listData);
        if(listData && listData.childLists){
            listData.childLists.forEach(async (child) => {
                const childListData = await getList(child.listId, true);
                await getListingType(childListData.listingTypeId, true);
                await getDatas(child.listId, true);
                // await getList(child.listId, true);
            })
        }
        return 'done';
    })
}

const getListsAndOtherData = async (uuid, year) => {
    let snap = await db.collection('lists')
        .where("listingTypeUuid", "==", uuid)
        .where("year", "==", year)
        .get();

    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    if (data.length) {
        const list = data[0]
        const listType = await getListingType(data[0].listingTypeId);
        const listId = list.id;
        let datas = await getDatas(listId);
        datas = datas.sort((a, b) => {
            if(a.lastname || a.firstname)
                return (a.lastname + a.firstname).localeCompare(b.lastname + b.firstname)
            if(a.name)
                return (a.name).localeCompare(b.name)
        })
        await getMetaData(listId);
        await loadList(list);
        if(list.childLists){
            await loadChildLists(listType, list, datas)
        } else {
            // remove category grid data and any other thing
            await removeCategoryGridData();
            await loadCategoryDropdown(null);
        }
        return 'done';
    } else {
        return 'done';
    }
}

const loadChildLists = async (listType, list, datas) => {
    let hasCategory = false
    let hasCountry = false
    list.childLists.forEach( async (childList) => {
        if(childList.type === "category") {
            await loadCategories(listType, childList, datas);
            hasCategory = true;
        } else if(childList.type === "country") {
            await loadCountries(listType, childList, datas);
            hasCountry = true;
        }
    })
    if(!hasCategory){
        await removeCategoryGridData();
    }
}
const loadCountries = async (listType, childList, datas) => {
    const childListId = childList.listId;
    const list = await getList(childListId, true);
    const listingTypeId = list.listingTypeId;
    await getListingType(listingTypeId, true);
    await getDatas(childListId, true);
}

const loadCategories = async (listType, childList, datas) => {
    const childListId = childList.listId;
    const childList1 = await getList(childListId, true);
    // const childListType = await getListingType(childList.listingTypeId, true);
    const columnKey = listType.categoryMeta.columnKey;
    const childListData = await getDatas(childListId, true);
    const orderedCategories = sortBy(childListData, function (o) { return o.order; })
    let subCategoriesForDropdown = ['All'];
    orderedCategories.forEach(category => {
        const metaKey = category.metaKey;
        if(childList1.hasSubCategories && category.subCategories){
            category.hasSubCategories = true;
            const arrayOfSubCats = category.subCategories.split("::")
            const dataWithSubCategories = [];
            arrayOfSubCats.forEach(subCat => {
                subCategoriesForDropdown.push(subCat);
                const subCatData= datas.filter(data => data[columnKey] === metaKey && data.subCategory === subCat)
                if(subCatData && subCatData.length > 0){
                    const dataForSubCat = {subCategory: subCat}
                    dataForSubCat.data = subCatData;
                    dataWithSubCategories.push(dataForSubCat);
                }
            })
            category.dataWithSubCategories = dataWithSubCategories;
        } else {
            category.data = datas.filter(data => data[columnKey] === metaKey)
        }
    })
    if(subCategoriesForDropdown.length < 1){
        subCategoriesForDropdown = null;
    }
    loadCategoryDropdown(subCategoriesForDropdown)
    loadCategoryGridData(orderedCategories)
    console.log(orderedCategories);
}

export const getConsumerHomeData = async (uuid, year) => {
    loadingConsumerData(true);
    await getListsAndOtherData(uuid, year);
    loadingConsumerData(false);
    return 'done';
}
export const removeBioQuote = () => {
    removeActiveBioQuote();
}
export const getQuoteForBio = async (listId, bioLink) => {
    let snap = await db.collection('lists').doc(listId).collection('quotes').where("bioLink", "==", bioLink).get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    if (data.length) {
        console.log("getQuoteForBio", data);
        loadActiveBioQuote(data[0]);
        // load the first quote found for the bio
    }
}
export const getBio = async (uuid, name) => {
    let snap = await db.collection('listingTypes').where("uuid", "==", uuid).get();
    let data = [];
    snap.forEach((inQryDoc) => {
        let dwithId = inQryDoc.data();
        dwithId.id = inQryDoc.id;
        data.push(dwithId)
    })
    if (data.length) {
        loadListingType(data[0]);
        let snap = await db.collection('lists').doc(data[0].listId).collection('data').get();
        let newData = []
        snap.forEach((inQryDoc) => {
            let dwithId = inQryDoc.data();
            dwithId.id = inQryDoc.id;
            if (dwithId.bioLink === name) {
                newData.push(dwithId)
            }
        })
        if (newData.length) {
            loadActiveBio(newData[0]);
        }
    }
}





//updates


export const updateBasicDetails = async (listId, payload) => {
    await db.collection('lists').doc(listId).update(payload);
    updateList(payload);
}
export const updateListingTypesClmns = async (listingTypeId, newClmns) => {
    await db.collection('listingTypes').doc(listingTypeId).update({ columns: newClmns });
    getListingType(listingTypeId)
}
export const updateListingTypesColumn = async (listingTypeId, oldObj, newObj) => {
    // since this is an array
    var columnRef = db.collection("listingTypes").doc(listingTypeId);
    // we will first have to remove the array item
    await columnRef.update({
        columns: firebase.firestore.FieldValue.arrayRemove(oldObj)
    });
    await columnRef.update({
        columns: firebase.firestore.FieldValue.arrayUnion(newObj)
    });
}
export const updateListingTypeHomeMeta = async (listingTypeId, objToSave) => {
    await db.collection('listingTypes').doc(listingTypeId).update(objToSave);
    getListingType(listingTypeId)
}


const looper = async (count, array, listId, resolve, reject, isChild) => {
    if (count < array.length) {
        console.log(count + ' / ' + array.length);
        exportExcelCount(count + ' of ' + array.length)
        await db.collection('lists').doc(listId).collection('data').add(array[count]);
        looper(count + 1, array, listId, resolve, reject, isChild);
    } else {
        getDatas(listId, isChild);
        resolve(true);
        console.log('done adding');
    }
}

export const exelImport = async (listId, array, isChild) => {
    exportExcelCount('Deleting old data!!', listId);
    let snap = await firebase.firestore().collection('lists').doc(listId).collection('data').get();
    snap.forEach((element) => {
        element.ref.delete()
    })
    let pr = new Promise((resolve, reject) => {
        looper(0, array, listId, resolve, reject, isChild);
    })
    return pr;
}

export const addListDataRow = async (listId, data, isChild) => {
    await db.collection('lists').doc(listId).collection('data').add(data);
    await getDatas(listId, isChild);
}
export const deleteListDataRow = async (listId, docId, isChild) => {
    deletingDataRow()
    await db.collection('lists').doc(listId).collection('data').doc(docId).delete();
    getDatas(listId, isChild);
    doneDeletingDataRow()
}
export const updateListData = async (listId, dataId, data, isChild) => {
    await firebase.firestore().collection('lists').doc(listId).collection('data').doc(dataId).update(data)
    getDatas(listId, isChild);
}
export const updateListMetaData = async (listId, dataId, data) => {
    await firebase.firestore().collection('lists').doc(listId).collection('metadata').doc(dataId).update(data)
    getMetaData(listId);
}






//inquiries
//addnew inquiry
export const addInquiry = async (payload) => {
    return await db.collection('inquiries').add(payload);
}
