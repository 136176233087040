import firebase from '../firebase';
import { ogMetaDetail } from "../redux/application";
const db = firebase.firestore();



export const getOGMeta = async (dataId) => {
    let snap = await db.collection('ogMeta').doc(dataId).get();
    let data = snap.data();
    ogMetaDetail(data);
    return 'done'
}

export const updateOGMeta = async (dataId, data) => {
    await firebase.firestore().collection('ogMeta').doc(dataId).update(data)
    getOGMeta(dataId);
    return 'done'
}
