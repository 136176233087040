import firebase from '../firebase';
// const ff = firebase.functions();


export const scrape = async (url, cb) => {
    const instance = firebase.functions().httpsCallable('getOgImages');
    try {
        const response = await instance({
            url: url,
        });
        cb(null, response);
    } catch (e) {
        cb(e);
    }
}