import React from "react";
import SocialIcon from "../social-icons";
import { connect } from "react-redux";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { withRouter } from 'react-router';
import SEButton from '../../../../common/styles-editor/SE-button'
import Spacer from "../spacer";
import { store } from "../../../../store";
import { selectUUIDAndYear } from "../../../../redux/list-config.selector";

const mapStateToProps = (state) => {
    const needsSquareBio = state.application.cache?.needsSquareBio;
    const bioImageFolderName = state.application.cache?.bioImageFolderName;
    const bioImageURLPropName = state.application.cache?.bioImageURLPropName;
    return {
        listingType: state.application.listingType,
        uuidAndYear: selectUUIDAndYear(state),
        list: state.application.list,
        bioClasses1: needsSquareBio ? "img-fluid img-profile mx-auto mb-2" : "img-fluid img-profile  rounded-circle mx-auto mb-2",
        bioClasses2: needsSquareBio ? "avatar-size" : "rounded-circle avatar-size",
        bioClasses3: needsSquareBio ? "avatar-mediu-size avatar-mobile-border" : "rounded-circle avatar-mediu-size avatar-mobile-border",
        bioImageFolderName: bioImageFolderName,
        bioImageURLPropName: bioImageURLPropName,
        activeBio: state.application.activeBio || {},
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: state.application.mode,
        listMetaData: state.application.listMetaData,
        fromWhere: state.tempData?.fromWhere,
        fromSlider: state.tempData?.fromSlider
    }
}

class Sidenave extends React.PureComponent {
    goBack = () => {
        let props = this.props;
        if ((props.fromWhere !== 'local' && props.history?.length <= 3) ||
            (props.fromWhere !== 'local' && props.history?.length <= 3 & props.fromSlider)) {
            props.history.push(`/${this.props?.listingType.uuid}/${this.props?.list.year}`);
        } else {
            props.history.goBack();
        }
    }

    dummyProfile = {
        "notes": "",
        "company": "ArcelorMittal",
        "firstname": "Lakshmi N.",
        "lastname": "Mittal",
        "title": "Chairman & CEO",
        "hq": "Luxembourg City, Luxembourg",
        "industry": "",
        "fortune500": "120",
        "fortuneGlobal500": "120",
        "forbesGlobal": "650",
        "forbesPrivate": "",
        "employees": "191,000",
        "revenue": "$76.03",
        "netIncome": "$5.15B",
        "marketCap": "",
        "bioImageUrl": "https://firebasestorage.googleapis.com/v0/b/indiaspora-prod.appspot.com/o/profile-images%2FSundar-pichai.png?alt=media&token=9fe1f479-5d77-4a18-bea4-1af5fb963b79",
        "marketCapLink": "https://finance.yahoo.com/quote/MT/",
        "birthplace": "Sadulpur, India",
        "residence": "Kensington Gardens, UK",
        "age": "69",
        "twitter": "https://twitter.com/ArcelorMittal",
        "link": "https://fortune.com/global500/2019/arcelormittal",
        "description": "Lakshmi serves as the chairman and CEO of ArcelorMittal, the world's largest steelmaker. Coming from a steel family, he separated from his siblings to start Mittal Steel, which he went on to merge the company with France's Arcelor in 2006."
    }
    constructor(props) {
        super(props);
        this.state = {
            activeBio: {}
        }
    }
    componentDidMount = () => {
        if (this.props.applicationMode === 'admin') {
            this.setState({ ...this.state, ...{ activeBio: this.dummyProfile } })
        } else {
            this.setState({ ...this.state, ...{ activeBio: this.props?.activeBio } })
        }
        store.subscribe(() => {
            let globalState = store.getState();
            let activeBio = globalState?.application?.activeBio;
            let mode = globalState?.application?.mode;
            this.setState({ ...this.state, ...{ activeBio: activeBio ? activeBio : mode == 'admin' ? this.dummyProfile : {} } })
        });
        setTimeout(() => {
            this.props.showDetail();
        }, 300)
    }
    getLabel = (id, name, value) => {
        if (value) {
            return <span className="sidenave-caption"
                style={this.props.homeLayUtils.getStyle(`col-${id}-bioLabel:css`, this.props.listMetaData, this.props.applicationMode)}>
                {this.props.homeLayUtils.getLabel(id, this.props.listingType, 'clmnMeta')}
                <SEButton sekey={`col-${id}-bioLabel:css`} type={"nonValuetext"}></SEButton>
            </span>
        }
    }
    getVal = (id, value) => {
        if (value) {
            return <span
                style={this.props.homeLayUtils.getStyle(`col-${id}-bioValue:css`, this.props.listMetaData, this.props.applicationMode)}>
                {value}
                <SEButton sekey={`col-${id}-bioValue:css`} type={"nonValuetext"}></SEButton>
            </span>
        }
    }

    getSrc = (imageUrl, bioLink) => {
        const indexOfToken = imageUrl?.lastIndexOf("&token")
        if (imageUrl && indexOfToken && indexOfToken != -1)
            imageUrl = imageUrl.substring(0, indexOfToken)
        let src = imageUrl?.replace(`/${this.props.bioImageFolderName}%2F${bioLink}`, `/${this.props.bioImageFolderName}%2Fthumbnails%2F${bioLink}_225x225`);
        return src;
    }

    render() {
        const isPhilanthropyLeaders = this.props.uuidAndYear?.uuid === "philanthropyLeaders" 
        const isSocialEntrepreneur = this.props.uuidAndYear?.uuid === "socialEntrepreneurs" 
        return (
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-dark profile-bg-india align-items-center justify-content-center side-navbar-scroll hide-scrollbar fixed-top d-none d-sm-none d-lg-block d-xl-block" id="sideNav"
                    style={this.props.homeLayUtils.getStyle("bioSideNav:css", this.props.listMetaData, this.props.applicationMode)}>
                    <SEButton sekey={"bioSideNav:css"} type={"div"}></SEButton>
                    <h3 className="mb-0 bio-back-button hand-cursor  text-left "
                        style={this.props.homeLayUtils.getStyle("bioSideNavBack:css", this.props.listMetaData, this.props.applicationMode)}>
                        <a><IoMdArrowRoundBack onClick={this.goBack} /></a>
                        <SEButton sekey={"bioSideNavBack:css"} type={"nonValuetext"}></SEButton>
                    </h3>
                    <a className="navbar-brand js-scroll-trigger justify-content-center align-items-center">
                        <span className="d-none d-lg-block">
                            <img src={this.getSrc(this.state.activeBio[this.props.bioImageURLPropName], this.state.activeBio?.bioLink)} className={this.props.bioClasses1} alt="..." />
                        </span>
                        <span className="d-lg-none d-block">
                            <img src={this.getSrc(this.state.activeBio[this.props.bioImageURLPropName], this.state.activeBio?.bioLink)} className={this.props.bioClasses2} alt="..." />
                        </span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Spacer val="3" />
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="col-12">
                            {(isPhilanthropyLeaders || isSocialEntrepreneur) && <h3 className="js-scroll-trigger mb-4 p-0 profile-rank-india remove-text-transform">
                                {this.getVal('country', this.state.activeBio?.country)}
                            </h3>}
                            <h3 className="js-scroll-trigger p-0 profile-rank-india">
                                {this.getVal('fortune500', this.state.activeBio?.fortune500)}
                            </h3>

                            <h5 className="nav-link js-scroll-trigger font-weight-bolder mb-3 p-0">
                                {this.getLabel('fortune500', 'Fortune 500', this.state.activeBio?.fortune500)}
                            </h5>
                            <h3 className="js-scroll-trigger p-0 profile-rank-india">
                                {this.getVal('fortuneGlobal500', this.state.activeBio?.fortuneGlobal500)}
                            </h3>
                            <h5 className="nav-link js-scroll-trigger font-weight-bolder mb-3 p-0">
                                {this.getLabel('fortuneGlobal500', 'Global 500', this.state.activeBio?.fortuneGlobal500)}
                            </h5>
                            <h3 className="js-scroll-trigger  p-0 profile-rank-india">
                                {this.getVal('forbesGlobal', this.state.activeBio?.forbesGlobal)}
                            </h3>
                            <h5 className="nav-link  font-weight-bolder js-scroll-trigger mb-3  p-0">
                                {this.getLabel('forbesGlobal', 'Forbes Global 2000', this.state.activeBio?.forbesGlobal)}
                            </h5>
                            <h3 className="js-scroll-trigger  p-0 profile-rank-india">
                                {this.getVal('forbesPrivate', this.state.activeBio?.forbesPrivate)}
                            </h3>
                            <h5 className="nav-link  font-weight-bolder js-scroll-trigger mb-3  p-0">
                                {this.getLabel('forbesPrivate', 'Forbes Largest Private', this.state.activeBio?.forbesPrivate)}
                            </h5>


                            <h5 className="nav-link js-scroll-trigger font-weight-bolder p-0 remove-text-transform">
                                {this.getLabel('dateAppointed', 'Date Appointed', this.state.activeBio?.dateAppointed)}
                            </h5>
                            <h3 className="js-scroll-trigger mb-4 p-0 profile-rank-india remove-text-transform">
                                {this.getVal('dateAppointed', this.state.activeBio?.dateAppointed)}
                            </h3>

                            <h5 className="nav-link js-scroll-trigger font-weight-bolder p-0 remove-text-transform">
                                {this.getLabel('birthDate', 'Date of Birth', this.state.activeBio?.birthDate)}
                            </h5>
                            <h3 className="js-scroll-trigger  mb-4 p-0 profile-rank-india remove-text-transform">
                                {this.getVal('birthDate', this.state.activeBio?.birthDate)}
                            </h3>


                            <h5 className="nav-link js-scroll-trigger font-weight-bolder p-0 remove-text-transform">
                                {this.getLabel('birthplace', 'Birth Place', this.state.activeBio?.birthplace)}
                            </h5>
                            <h3 className="js-scroll-trigger  mb-4 p-0 profile-rank-india remove-text-transform">
                                {this.getVal('birthplace', this.state.activeBio?.birthplace)}
                            </h3>

                            <Spacer val="5" />
                            <SocialIcon data={this.state.activeBio} owner={'BioPage'} />

                        </div>
                    </div>
                </nav>
                <div className="col d-lg-none d-block  text-center mt-4 profile-bg-india card pl-3 pl-3 pt-3 pb-3"
                    style={this.props.homeLayUtils.getStyle("bioSideNav:css", this.props.listMetaData, this.props.applicationMode)}>
                    <SEButton sekey={`bioSideNav:css`} type={"div"}></SEButton>
                    <img className={this.props.bioClasses3} src={this.getSrc(this.state.activeBio[this.props.bioImageURLPropName], this.state.activeBio?.bioLink)} alt="" />
                    <Spacer val="3" />
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('fortune500', this.state.activeBio?.fortune500)}
                    </h3>
                    <p className="mb-2 p-0">
                        {this.getLabel('fortune500', 'Fortune 500', this.state.activeBio?.fortune500)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('fortuneGlobal500', this.state.activeBio?.fortuneGlobal500)}
                    </h3>
                    <p className="mb-2 p-0">
                        {this.getLabel('fortuneGlobal500', 'Global 500', this.state.activeBio?.fortuneGlobal500)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('forbesGlobal', this.state.activeBio?.forbesGlobal)}
                    </h3>
                    <p className="mb-0 p-0">
                        {this.getLabel('forbesGlobal', 'Forbes Global 2000', this.state.activeBio?.forbesGlobal)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('forbesPrivate', this.state.activeBio?.forbesPrivate)}
                    </h3>
                    <p className="mb-0 p-0">
                        {this.getLabel('forbesPrivate', 'Forbes Largest Private', this.state.activeBio?.forbesPrivate)}
                    </p>


                    <p className="mb-0 p-0">
                        {this.getLabel('dateAppointed', 'Date Appointed', this.state.activeBio?.dateAppointed)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('dateAppointed', this.state.activeBio?.dateAppointed)}
                    </h3>
                    <p className="mb-0 p-0">
                        {this.getLabel('birthDate', 'Date of Birth', this.state.activeBio?.birthDate)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('birthDate', this.state.activeBio?.birthDate)}
                    </h3>
                    <p className="mb-0 p-0">
                        {this.getLabel('birthplace', 'Birth Place', this.state.activeBio?.birthplace)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                        {this.getVal('birthplace', this.state.activeBio?.birthplace)}
                    </h3>


                    <Spacer val="3" />
                    <SocialIcon data={this.state.activeBio} owner={'BioPage'} />
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps)(withRouter(Sidenave));
