import React from "react";
import { withRouter } from "react-router-dom";
import { setFromListHome } from "../../../redux/templ";
import { FaAngleDown } from 'react-icons/fa';
import { Dropdown, Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";
class HomeListing4 extends React.Component {

    gotoListing = (listTypeId, year) => {
        setFromListHome(true);
        this.props.history.push(`/${listTypeId}/${year}`);
    }

    render() {
        return (
            <>
            <div className="ht-100">
                <Navbar bg="light" expand="lg">
                    <Navbar.Brand href="http://indiaspora.org">
                        <img
                            src={require('../../../../assets/img/logo-trans.png')}
                            alt="Indiaspora"
                            className="logo-list-landing"
                        />
                    </Navbar.Brand>
                    <Nav className="mr-auto">
                    <Navbar.Text>
                    &nbsp;
                    </Navbar.Text>
                    </Nav>
                    <Dropdown navbar={true}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="dd-valign btn-larger-text">
                            Lists
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight={true}>
                            <Dropdown.Item onClick={() => this.gotoListing("socialEntrepreneurs", "2022")}>2022 INDIASPORA SOCIAL ENTREPRENEURS LIST</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.gotoListing("philanthropyLeaders", "2021")}>2021 INDIASPORA PHILANTHROPY LEADERS LIST</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => this.gotoListing("governmentLeaders", "2021")}>2021 INDIASPORA GOVERNMENT LEADERS LIST</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => this.gotoListing("businessLeaders", "2020")}>2020 INDIASPORA BUSINESS LEADERS LIST</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Navbar>
                <div className="position-relative">
                <section className="Hero row d-flex justify-content-center position-absolute top-0 left-0 right-0 bottom-0">
                {/* <div className="container-fluid mt-5">
                    <div className="row align-items-center justify-content-center mt-2 mt-sm-4 mt-md-4 mt-lg-4 mt-xl-4 ie-issue-logo">
                        <div className="logo-container-left">
                            <div  className={`${this.props.isBio ? "logo-subcontainer logo-subcontainer-ie" : "logo-subcontainer"}`}>
                            <a href="http://indiaspora.org">
                                <img className="logo-list-landing"
                                src={require('../../../../assets/img/logo-rect.jpg')} alt="Indiaspora" />
                            </a>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="zIndex-1">
                    <div className="backgroundColor-grayMedium position-absolute top-0 left-0 right-0 bottom-0">
                        <div id="coverImageContainer" className="HomepagePhotoSlider-image"></div>
                    </div>
                </div>
                <div className="Hero-overlay position-absolute zIndex-6"></div>
                <div className="position-relative d-flex zIndex-7 text-center align-items-center p-2 pt-auto pt-sm-5">
                    <div className="marginHorizontal-auto text-center justify-content-center pt-auto pt-sm-5 pl-2 pr-2 pb-0 pb-sm-5">
                        <h1 className="h1-4rem lineHeight-1d5 lineHeight-1d25-m text-light text-center fontWeight-800 mb-4">Inspire. Influence. Impact.</h1>
                        <h3 className="h3 h3-2-half-rem light double-giving text-light remove-text-transform pb-0 pb-sm-5 fontWeight-700">Indiaspora Lists: Showcasing the Indian Diaspora as a Force for Good</h3>
                        <br />
                        <br />
                        {/* <div className="row">
                            <div className="col-12 col-lg-8 offset-lg-2">
                                <div
                                    className=" h3 light double-giving text-light mt-xs-1 mt-md-3 ml-md-5 mr-md-5 ml-3 mr-3 pr-0 pl-0 pl-md-3 pr-md-3">
                                    Indiaspora Lists: Showcasing the Indian Diaspora as a force for good.
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <FaAngleDown className="hero-down-arrow"></FaAngleDown>
            </section>
            </div>
            </div>
            {/* <div className="ht-90">
                &nbsp;
            </div> */}
            <div className="container-fluid position-relative home-list-container-4 mb-4 mt-4">
                {/* <div className="Hero-overlay position-absolute zIndex-6"></div> */}
                <div className="row  justify-content-around">
                    {/* <div className="col-12 col-sm-8 col-lg-8 col-xl-8 p-3">
                        <HomeListDescription />
                    </div> */}
                    <div className="col-12 col-md-10 col-lg-10 col-xl-8 pt-5 pb-2 pr-5 pl-5">
                        <div className="row pb-0 pb-sm-5" onClick={() => this.gotoListing("socialEntrepreneurs", "2022")}>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pr-lg-5 order-2 order-lg-1">
                                <h3 className="card-title"><strong className="home-list-title">2022 INDIASPORA SOCIAL ENTREPRENEURS LIST</strong></h3>
                                <p className="card-text">The younger generation of Indian-origin entrepreneurs are heavily involved in social entrepreneurship. This list features young social entrepreneurs who are making impactful contributions to society through their enterprises.</p>
                            </div>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pl-lg-5 order-1 order-lg-2">
                                <img src={require("../../../../assets/img/social-entrepreneur-list-new.jpeg")} className="card-img" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-10 col-xl-8 pt-5 pb-2 pr-5 pl-5">
                        <div className="row pb-0 pb-sm-5" onClick={() => this.gotoListing("philanthropyLeaders", "2021")}>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pr-lg-5"> 
                                <img src={require("../../../../assets/img/phil-leader-list.jpg")} className="card-img" alt="..." />
                            </div>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pl-lg-5">
                                <h3 className="card-title"><strong className="home-list-title">2021 INDIASPORA PHILANTHROPY LEADERS LIST</strong></h3>
                                <p className="card-text">The Indian Diaspora is incredibly generous. Our Philanthropy Leaders List spotlights 100 Diaspora leaders who are changing the world for the better through their philanthropic contributions and engagement.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-10 col-xl-8 pt-5 pb-2 pr-5 pl-5">
                        <div className="row pb-0 pb-sm-5" onClick={() => this.gotoListing("governmentLeaders", "2021")}>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pr-lg-5 order-2 order-lg-1">
                                <h3 className="card-title"><strong className="home-list-title">2021 INDIASPORA GOVERNMENT LEADERS LIST</strong></h3>
                                <p className="card-text">Presidents. Prime ministers. Chief justices. Diplomats. And now vice president of the United States. Meet the diaspora’s 200 top government officials serving in 15 different countries.</p>
                            </div>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pl-lg-5 order-1 order-lg-2">
                                <img src={require("../../../../assets/img/gov_leader_list-new.jpg")} className="card-img" alt="..." />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-10 col-lg-10 col-xl-8 p-5">
                        <div className="row" onClick={() => this.gotoListing("businessLeaders", "2020")}>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pr-lg-5">
                                <img src={require("../../../../assets/img/business-leader-list-new.jpg")} className="card-img" alt="..." />
                            </div>
                            <div className="col-12 col-lg-6 hand-cursor pl-0 pr-0 pl-lg-5">
                                <h3 className="card-title"><strong className="home-list-title">2020 INDIASPORA BUSINESS LEADERS LIST</strong></h3>
                                <p className="card-text">For our inaugural list, we identified the top names in business. Meet the CEOs, Presidents, or Chairs of the Board of Directors who are leading the largest global companies today.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default withRouter(HomeListing4)