import { combineReducers } from 'redux';
import { listDetailsReducer } from './list-details-page-op';
import { profileReducer } from './auth/login-rx';
import { applicationReducer } from './redux';
import { tempReducer } from './redux';
import { newsReducer } from './news/news-rx'
import { mediaReducer } from './media/media-rx'
import { quotesReducer } from "./quotes/quotes-rx";

export const RootReducer = combineReducers({
    application: applicationReducer,
    listDetails: listDetailsReducer,
    tempData: tempReducer,
    news: newsReducer,
    media: mediaReducer,
    quotes: quotesReducer,
    profile: profileReducer
})