import React from "react";
import { connect } from "react-redux";
import Slider from './slider';
import SEButton from '../../../../common/styles-editor/SE-button'
import Spacer from "../spacer";
import CategoryGridView from '../list-view/category-grid-view';
import { selectActiveCountryData, selectCountryListType, selectCountryCategoryGridData } from "../../../../redux/country.selector";

const mapStateToProps = (state) => {
    const applicationMode = state.application.mode;
    const countryData = applicationMode == "admin" ? dummyProfile : selectActiveCountryData(state);
    return {
        listingType: selectCountryListType(state),
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: applicationMode,
        listMetaData: state.application.listMetaData,
        countryData: countryData,
        categoryGridData: selectCountryCategoryGridData(state),
    }
}

const dummyProfile = {
    "marketCap": "123B",
    "employees": "103,549",
    "hq": "Mountain View, CA",
    "industry": "",
    "lastname": "Pichai",
    "notes": "",
    "fortune500(2020)": "11",
    "title": "Chief Executive Officer",
    "revenue": "$136.82",
    "residence": "Mountain View, CA",
    "marketcap-livelinkjune1,2020marketcap": "https://www.nasdaq.com/market-activity/stocks/googl",
    "age": "47",
    "since": "2007",
    "link": "https://fortune.com/fortune500/2019/alphabet",
    "fortuneglobal500(2019)": "37",
    "description": "Sundar Pichai spearheaded Google's acquisition of Nest, the development of Android One, and the creation of Chrome OS, having an immeasurable impact on the tech giant. Now serving as the CEO og Google and parent company Alphabet, Sundar now oversees the \"Other Bets\" division of Alphabet as well.",
    "description_2": "Sundar Pichai spearheaded Google's acquisition of Nest, the development of Android One, and the creation of Chrome OS, having an immeasurable impact on the tech giant. Now serving as the CEO og Google and parent company Alphabet, Sundar now oversees the \"Other Bets\" division of Alphabet as well.",
    "birthplace": "Mumbai",
    "company": "Alphabet",
    "netIncome": "$30.73B",
    "forbesglobal2000(2020)": "13",
    "forbeslargestprivateu.s.companies(2019)": "",
    "firstname": "Sunder",
    "id": "0px0ns4fncniRmVDWi2N"
}
class DetailBody extends React.PureComponent {

    getValue = (attrName) => {
        if (this.props.applicationMode === 'admin') {
            return this.props.countryData?.[attrName] ? this.props.countryData?.[attrName] : this.dummyProfile[attrName]
        } else if (this.props.applicationMode === 'consumer' &&
            (this.props.countryData?.[attrName]?.length > 2 ||
                this.props.countryData?.[attrName] > 2 // the case of age
            )) {
            return this.props.countryData?.[attrName]
        } else {
            return '';
        }
    }
    getStyle = (attrName) => {
        return this.props.homeLayUtils.getStyle(`col-${attrName}`, this.props.listMetaData, this.props.applicationMode)
    }
    getValueStyle = (attrName) => {
        return this.props.homeLayUtils.getStyle(`col-${attrName}`, this.props.listMetaData, this.props.applicationMode)
    }
    getLabelStyleButton = (attrName) => {
        return <>
            <SEButton sekey={`col-${attrName}-bioLabel:css`} type={"nonValuetext"}></SEButton>
        </>
    }
    getValueStyleButton = (attrName) => {
        return <>
            <SEButton sekey={`col-${attrName}-bioValue:css`} type={"nonValuetext"}></SEButton>
        </>
    }
    getAttrJsx = (attrName, classes) => {
        return <>
            <span className={classes} style={this.getValueStyle(attrName)}>
                {this.getValue(attrName)}
                {this.getValueStyleButton(attrName)}
            </span>
        </>
    }
    getDescAttrJsx = (attrName, classes) => {
        return <>
            <span className={classes} style={this.getValueStyle(attrName)}
                dangerouslySetInnerHTML={{ __html: this.getValue(attrName) }}
            >
            </span>
            <span>{this.getValueStyleButton(attrName)}</span>
        </>
    }

    getAttrWithLabelJsx = (attrName) => {
        let jsx = <>
            <span style={this.getStyle(attrName)}>
                {this.props.homeLayUtils.getLabel(attrName, this.props.listingType, 'clmnMeta')}
                {this.getLabelStyleButton(attrName)}
            </span>
        :&nbsp;
            <span style={this.getValueStyle(attrName)}>
                {this.getValue(attrName)}
                {this.getValueStyleButton(attrName)}
            </span>
            <br /></>
        if (this.props.applicationMode === 'admin') {
            return jsx
        } else if (this.props.applicationMode === 'consumer' &&
            (this.props.countryData?.[attrName]?.length > 2 ||
                this.props.countryData?.[attrName] > 2 // the case of age
            )) {
            return jsx
        } else {
            return '';
        }
    }

    render() {
        let imageBackgroundColor = {};
        if (this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)) {
            imageBackgroundColor = { backgroundImage: `url(${this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)})` }
        } else {
            imageBackgroundColor = this.props.homeLayUtils.getStyle(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)
        }

        const bgImage = {
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        };
        return (
            <div className="container-fluid">
                <div className="row align-items-start body-content-container">
                    <div className="col-12 col-sm-12 col-md-12 col-lx-11 col-lg-11 mb-5 mt-4">
                        <section className="pt-3 d-flex align-items-center" id="about">
                            <div className="w-100 pl-lg-5 pr-lg-5">
                                <h1 className="mb-0">
                                    <span className="profile-name-india mb-0" style={{ "color": this.props.countryData?.color_sidebar }}>
                                        {this.getValue('name')}
                                        {/* CANADA */}
                                        {this.getValueStyleButton('name')}
                                    </span>
                                    {/* <span className="text-secondary">
                                        {this.getAttrJsx('age', "")}
                                    </span> */}
                                </h1>
                                <Spacer val="3" />
                                <div className="resume-content bio-resume-field">
                                    <div className="lst-descritption">
                                        {/* <img src={require('../../../../../assets/img/s3.png')} className="card-img" alt="..." /> */}
                                        {/* <img src={this.props.homeLayUtils.getImage(`bioDescImg:css`, this.props.listMetaData, this.props.applicationMode)} className="card-img" alt="..." /> */}
                                        <div className="align-items-center justify-content-start d-flex row align-content-center  pl-4 pr-4  pl-sm-4 pr-sm-4 pl-md-4 pr-md-4   pl-lg-4 pr-lg-4  pl-xl-4 pr-xl-4 pt-4 pb-4  link-color-container">
                                            <style jsx>{`
                                                    .link-color-container a{
                                                        color:${this.props.countryData?.color_sidebar};
                                                    }
                                                    `}</style>
                                            <p className="mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description', "")}
                                                {/* Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. */}
                                            </p>
                                            <p className="mt-2 mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description_2', "")}

                                            </p>
                                            <p className="mt-2 mb-0 text-muted text-left">
                                                {this.getDescAttrJsx('description_3', "")}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <SEButton sekey={"bioDescImg:css"} type={"img"}></SEButton>
                                    </div>
                                    {/* <div className="mt-2">
                                        <h5><a target="_blank" href={this.props.countryData?.link ? this.props.countryData?.link : this.dummyProfile.link}>More Info</a></h5>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        {
                            this.props.categoryGridData &&
                            this.props.categoryGridData.map(gridData => <CategoryGridView key={gridData.id} gridData={gridData} />)
                        }
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lx-1 col-lg-1 pr-0 pl-0 text-center">
                        <Slider />
                    </div>
                </div>
            </div >

        );
    }
}


export default connect(mapStateToProps)(DetailBody);
