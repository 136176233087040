import React from 'react';
import JurorContainer from './juror/juror-container';
import phil2021Jurors from './juror/phil-jurors-2021.data';

class SelectionPhilContent extends React.Component {

    componentDidMount = () => {
    }

    render() {
        return (
            <div className="container mt-4 mb-5 color-dark">
                <p>Creating a global list of philanthropic leaders is an inherently challenging task. <a className="text-info" href="https://www.indiaspora.org/">Indiaspora’s</a> philosophy is to inspire generosity by spotlighting individuals who have made exceptional impact through their work and contributions.  The <strong>Indiaspora Philanthropy Leaders List</strong> is the product of rigorous research, input from partners in the philanthropy ecosystem, and the guidance of our jurors.</p>
                <p>We approached the task of developing this list with careful consideration using the following guiding principles:
                    <ul>
                        <li>This list is not intended as a ranking of big donors but a celebration of expansive philanthropic vision and consistent support of social good.</li>
                        <li>We took a regional focus in spotlighting philanthropic leaders, as there are obvious limitations in treating donation amounts as the sole criteria across the globe.</li>
                        <li>We researched multiple sources to curate our list, including leaders of the India Philanthropy Alliance, reputed studies, earlier verified lists, and publicly shared documents.</li>
                    </ul>
                </p>
                <p>We are pleased to present this list for the first time in 2021 to celebrate the philanthropic contributions of the Indian diaspora.  However, despite our careful research, we recognize this list is by no means exhaustive.  We welcome suggestions of other exceptional philanthropic leaders who should be spotlighted and will evaluate recommendations and add to our list as necessary. </p>
                <p><strong>Finally, we are immensely grateful for the time and commitment of our jurors.  These nine leaders in philanthropy, from across continents, helped us ensure our process was nuanced and meaningful:</strong></p>
                <div className="row">
                    <div className="col-12">
                        {phil2021Jurors.map(jurors => <JurorContainer jurors={jurors} folder="phil2021"/>)}
                    </div>
                </div>
                <p>We would also like to thank Raj Kothari, Jayesh Parekh, and Senator Ratna Omidvar who served as advisors to the Indiaspora 2021 Philanthropy Leaders List.  Thank you for your time and your invaluable insights.</p>
            </div>
        );
    }
}

export default SelectionPhilContent;