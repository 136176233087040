import React from 'react';
import { withRouter } from "react-router-dom";


class AboutContent extends React.Component {
   
    componentDidMount = () => {
        const { params } = this.props.match;
        const { uuid, year } = params;
        const isBizLeaders = uuid === "businessLeaders" && year === "2020"
        const isGovtLeaders = uuid === "governmentLeaders" && year === "2021"
        const isPhilLeaders = uuid === "philanthropyLeaders" && year === "2021"
        const isSocialEntrepreneur = uuid === "socialEntrepreneurs" && year === "2022"
        if(!isBizLeaders && !isGovtLeaders && !isPhilLeaders && !isSocialEntrepreneur){
            this.props.history.replace("/");
        }
        this.setState({ ...{ isBizLeaders, isGovtLeaders, isPhilLeaders, isSocialEntrepreneur } });
    }    

    render() {
        return (
            <>
            {
                this.state?.isBizLeaders && <div className="container mt-4 mb-5 color-dark">
                    {/* <h3>About</h3> */}
                    <p>The <strong>Indiaspora Business Leaders List</strong> recognizes pioneering members of the Indian diaspora who have driven their companies to the pinnacle of corporate success.</p>
                    <p>The first of the Indiaspora Lists, Indiaspora aims to highlight the advances of the Indian diaspora and to raise its profile in the global consciousness in areas such as government, academia, and philanthropy.</p>
                    <p>The Business Leaders List demonstrates the rise of business people of Indian descent.</p>
                    <p>It also shines a spotlight on the presence of a glass ceiling, as the diaspora are not immune. The dearth of women business leaders who have risen to the top spot in corporations is acutely felt. Out of 1,000 companies honored on the Fortune 500 list, only 61 have women CEOs; the Indiaspora List has a marginally higher percentage of women, yet only accounts for 5 women leaders out of 60 leaders on the List. While the Indian diaspora are making strides in the business world, there are still significant advancements needed to achieve a gendered balance.</p>
                    <p><a className="text-info" href="https://www.indiaspora.org/">Indiaspora</a> is a 501c(3) nonprofit organization established to transform the success of the Indian diaspora into meaningful impact worldwide. Our members form a powerful network of global leaders from diverse backgrounds and professions who build stronger communities with a culture of giving and inspiring social change.</p>
                    <h3 className="pt-3">Acknowledgements</h3>
                    <p>We would like to thank <a className="text-info" href="https://www.linkedin.com/in/sreenivasan/">Sree Sreenivasan</a>, the inaugural Marshall R. Loeb visiting professor at Stony Brook University School of Journalism in New York, and Cofounder of Digimentors, for serving as an advisor for this initiative.</p>
                </div>
            }
            {
                this.state?.isGovtLeaders && <div className="container mt-4 mb-5 color-dark">
                    <p>The <strong>2021 Indiaspora Government Leaders List</strong> recognizes more than 200 Indian diaspora leaders who have ascended to the highest echelons of public service in 15 countries across the globe, with nearly 70 of these leaders holding senior, cabinet-level positions.</p>
                    <p>The Indiaspora Lists aim to highlight the advances of the Indian diaspora and to raise their profile in the global consciousness in areas such as business, academia, and philanthropy.</p>
                    <p>The 2021 Indiaspora Government Leaders List demonstrates the rise of the Indian diaspora as public servants and as political and policy leaders.</p>
                    <p>Indiaspora (<a className="text-info" href="https://www.indiaspora.org/">www.indiaspora.org</a>) is a nonprofit community of powerful global Indian leaders from diverse backgrounds and professions who are committed to inspiring the diaspora to be a force for positive impact by providing a platform to collaborate, engage, and catalyze social change.</p>
                </div>
            }
            {
                this.state?.isPhilLeaders && <div className="container mt-4 mb-5 color-dark">
                    <p>The Indiaspora Lists highlight the advances of the Indian diaspora in areas such as business, government, academia, and philanthropy.</p>
                    <p>The <strong>Indiaspora Philanthropy Leaders List</strong> spotlights 100 leaders of the Indian diaspora who have made a significant impact with their philanthropic contributions and engagement.</p>
                    <p>This list demonstrates the tremendous generosity of the Indian diaspora, and our hope is that it serves as an inspiration for others to give.</p>
                    <p><a className="text-info" href="https://www.indiaspora.org/">Indiaspora</a> is a nonprofit community of powerful global Indian leaders from diverse backgrounds and professions who are committed to inspiring the diaspora to be a force for good by providing a platform to collaborate, engage, and catalyze social change.</p>
                </div>
            }
            {
                this.state?.isSocialEntrepreneur && <div className="container mt-4 mb-5 color-dark">
                    <p>The Indiaspora Lists highlight the advances of the Indian diaspora in areas such as business, government, academia, and philanthropy.</p>
                    <p>This list, which spotlights young Indian-origin social entrepreneurs from around the world, demonstrates ways in which for-profit enterprises can contribute heavily to society.</p>
                    <p><a className="text-info" href="https://www.indiaspora.org/">Indiaspora</a> is a nonprofit community of powerful global Indian leaders from diverse backgrounds and professions who are committed to inspiring the diaspora to be a force for good by providing a platform to collaborate, engage, and catalyze social change.</p>
                </div>
            }
            </>
        );    
}


}

export default withRouter(AboutContent);