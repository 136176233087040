import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

function VerticalModal(props) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header> */}
            <Modal.Body className='text-center'>
                <h5>{props?.bodyText}</h5>
                <Spinner animation="border" size="sm" className="text-success" /> &nbsp; <h5><strong className='text-lowercase'>{props?.bodyText2}</strong></h5>
              </Modal.Body>
            {/* <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default VerticalModal