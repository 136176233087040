import React from 'react';
import { connect } from 'react-redux';
import { Form, Image, Spinner, Button, Toast } from "react-bootstrap";
import firebase from "../app/firebase";
import Config from "../firebase-configs.json";
import { getData } from "./listdetails/listdetails-fs";
import { updateOGMeta, getOGMeta } from "./ogMeta/ogMeta-details-fs";

const mapStateToProps = (state) => {
    return {
        ogMeta: state.application.ogMeta,
        list: state.application.list,
    }
}

class ListSocialPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            title: '',
            description: '',
            image: '',
            updating: false,
            imageUpload: false,
            showToastModal: false
        }
    }

    componentDidMount = async () => {
        if(!this.props.list) {
            this.listId = this.props.match.params.listId;
            this.listingTypeId = this.props.match.params.listingTypeId;
            this.subdomain = this.props.match.params.subdomain;
            await getData(this.listId, this.listingTypeId);
        }
        let list = this.props.list;
        this.seKey = `${list?.listingTypeUuid}::${list?.year}`;
        await getOGMeta(this.seKey);
        this.setState(this.props.ogMeta);
    }

    handleImageUpload = (e) => {
        this.setState({imageUpload: true});
        const image = e.target.files[0];
        if (image && image.name) {
            var storage = firebase.app().storage(Config.socialBucket);
            let uploadTask = storage.ref('/social/' + image.name).put(image);
            uploadTask.on('state_changed',
            (snapShot) => {
                // let per = snapShot.bytesTransferred / snapShot.totalBytes * 100
                // per = parseInt(per);
                    // this.setState({ ...this.state, ...{ updatingTableRow: false, uploadStatus: true } })
                }, (err) => {
                    this.setState({imageUpload: false});
                }, () => {
                    storage.ref('social').child(image.name).getDownloadURL()
                        .then(async (fireBaseUrl) => {
                            const newState = {...this.state, image: fireBaseUrl}
                            delete newState.imageUpload;
                            delete newState.updating;
                            setTimeout(() => {
                                this.setState({image: fireBaseUrl, imageUpload: false});
                            }, 1000)
                        })
                })
        } else {
            // console.log('pass image name')
        }
    }

    saveChanges = async () => {
        this.setState({updating: true});
        let newState = this.state;
        delete newState.imageUpload;
        delete newState.updating;
        delete newState.showToastModal;
        await updateOGMeta(this.seKey, newState);
        this.setState({updating: false, showToastModal: true});
    }

    showToast = (message) => {
        return (
            <div style={{ width: '400px' }}>
                <Toast style={{
                    position: 'absolute',
                    top: "0",
                    right: 0,
                }} show={this.state?.showToastModal} onClose={() => this.setState({ showToastModal: false })} delay={5000} autohide>
                    {/* <Toast.Header className="bg-light">
                    <strong className="mr-auto text-success">Update</strong>
                </Toast.Header> */}
                    <Toast.Body className="text-success bg-white">{'List Social Page is Saved.'}</Toast.Body>
                </Toast>

            </div>
        )
    }

    render() {
        return (
            <Form className="container col-6"><br/>
                {this.state?.showToastModal ?
                    this.showToast()
                : ''}
                <Form.Group controlId="title">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" placeholder="Title" value={this.state?.title} onChange={e => this.setState({title: e.target.value})}/>
                </Form.Group>
                <Form.Group controlId="desc">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" placeholder="Description" value={this.state?.description} onChange={e => this.setState({description: e.target.value})} />
                </Form.Group>
                <Form.Group controlId="image">
                    <Form.Label>Image</Form.Label> <br/>
                    <Image src={this.state?.image} rounded thumbnail className={'col-6'}/>
                    <Form.File type="file" accept="image/*"
                       onChange={this.handleImageUpload} />
                    <p hidden={!this.state.imageUpload}><Spinner animation="grow" size="sm"/> uploading...</p>
                </Form.Group>
                <Form.Group>
                    <div className="text-center row">
                        <div className="col-8 text-right">
                            <Button variant="secondary">
                                Close
                            </Button>
                        </div>
                        <div className="col-4 text-left">
                        {this.state?.updating ? <Button>
                        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                                updating...
                                            </Button> :
                        <Button variant="primary" onClick={this.saveChanges}>
                            Save
                        </Button>
                        }
                        </div>
                    </div>
                </Form.Group>
            </Form>
        );
    }
}
export default connect(mapStateToProps)(ListSocialPage);