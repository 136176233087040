import React from "react";
import { Navbar, Nav } from 'react-bootstrap'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSubNavActiveLink, setShowQuotes, setQuotesLabel } from '../../../redux/templ'
import { getConsumerHomeData } from '../../../listdetails/listdetails-fs'

const mapStateToProps = (state) => {
    return {
        uuid: state.application?.listingType?.uuid,
        hideNews: state.application?.listingType?.homeMeta?.hideNews,
        showMedia: state.application?.listingType?.homeMeta?.showMedia,
        list : state.application.list,
        subnavActiveLink: state.tempData.subnavActiveLink
    }
}
class SubNavbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount = async() => {
        const params = this.props.match.params;
        if(!this.props.list?.id) {
            await getConsumerHomeData(params.uuid, params.year);
        }
    }

    style = {
        "borderBottom": "2px solid red",
        "display": "inline-block",
        "lineHeight": "0.85"
    }
    onLinkClick = (linkName) => {
        if(this.state.activeLink === linkName)
            return;
        let url;
        if (linkName === 'lists') {
            url = '/';
        } else if (linkName === 'home') {
            url = '/' + this.props?.uuid + '/' + this.props.list.year;
        } else {
            url = '/' + this.props?.uuid + '/' + this.props.list.year + '/' + linkName;
        }
        if(linkName === 'news') {
            setShowQuotes(false);
            setQuotesLabel('Quotes');
        }
        setSubNavActiveLink(linkName);
        this.props.history.push(url);
    }
    activeButtonStyle = (linkName) => {
        let style = {
            "textDecoration": "underline",
            "textDecorationColor": "red"
        }
        if (this.state.activeLink === linkName) {
            return style
        }
    }
    render() {
        return (
            <React.Fragment>
                {/* Mobile siplay */}
                <Navbar bg="white" expand="lg" className="mt-1 mb-4 align-items-center  d-lg-none d-block">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    {/* <div className="d-lg-none d-block mr-2">
                        <SocialIcon className="d-none d-sm-block" />
                    </div> */}
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center align-items-center">
                        <Nav className="text-center">
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('lists')} style={this.props.subnavActiveLink === 'lists' ? this.style : {}}><h5 className="m-0">Lists</h5></Nav.Link>
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('about')} style={this.props.subnavActiveLink === 'about' ? this.style : {}}><h5 className="m-0">ABOUT</h5></Nav.Link>
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('selectionProcess')} style={this.props.subnavActiveLink === 'selectionProcess' ? this.style : {}}><h5 className="m-0">SELECTION</h5></Nav.Link>
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('home')} style={this.props.subnavActiveLink === 'home' ? this.style : {}}><h5 className="m-0">Home</h5></Nav.Link>
                            {!this.props?.hideNews && <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('news')} style={this.props.subnavActiveLink === 'news' ? this.style : {}}><h5 className="m-0">NEWS</h5></Nav.Link>}
                            {this.props?.showMedia && <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('inMedia')} style={this.props.subnavActiveLink === 'inMedia' ? this.style : {}}><h5 className="m-0">IN THE MEDIA</h5></Nav.Link>}
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('contact')} style={this.props.subnavActiveLink === 'contact' ? this.style : {}}><h5 className="m-0">CONTACT</h5></Nav.Link>
                        </Nav>
                        {/* <div className="d-none d-sm-block ml-3">
                            <SocialIcon />
                        </div> */}
                    </Navbar.Collapse>
                </Navbar>
                {/* Desktop display */}
                <div className="container-fluid mt-4 mb-3 align-items-center  d-none d-sm-none d-lg-block d-xl-block">
                    <div className="row align-items-center no-gutters justify-content-center mt-4 mt-sm-4 mt-md-4 mt-lg-1 mt-xl-1 pt-2 pb-2 relative">
                        <div className="col-5 text-right">
                            <div className="row align-items-center justify-content-end">
                                <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('lists')} style={this.props.subnavActiveLink === 'lists' ? this.style : {}}><h5 className="m-0">Lists</h5></Nav.Link>
                                <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('about')} style={this.props.subnavActiveLink === 'about' ? this.style : {}}><h5 className="m-0">ABOUT</h5></Nav.Link>
                                <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('selectionProcess')} style={this.props.subnavActiveLink === 'selectionProcess' ? this.style : {}}><h5 className="m-0">SELECTION</h5></Nav.Link>
                            </div>
                        </div>
                        <div className="col-1 text-center">
                            <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('home')} style={this.props.subnavActiveLink === 'home' ? this.style : {}}><h5 className="m-0 text-nowrap">Home</h5></Nav.Link>
                        </div>
                        <div className="col-5 text-right">
                            <div className="row align-items-center justify-content-start">
                                {!this.props?.hideNews && <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('news')} style={this.props.subnavActiveLink === 'news' ? this.style : {}}><h5 className="m-0">NEWS</h5></Nav.Link>}
                                {this.props?.showMedia && <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('inMedia')} style={this.props.subnavActiveLink === 'inMedia' ? this.style : {}}><h5 className="m-0">IN THE MEDIA</h5></Nav.Link>}
                                <Nav.Link className="font-weight-bold pl-4 pr-4 text-secondary" onClick={() => this.onLinkClick('contact')} style={this.props.subnavActiveLink === 'contact' ? this.style : {}}><h5 className="m-0">CONTACT</h5></Nav.Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(SubNavbar));