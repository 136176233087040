import React from 'react';
import { connect } from 'react-redux';
import Navbar from './layouts/first-layout/components/navbar';
import SubNavbar from './layouts/first-layout/components/sub-navbar';
import Footer from './layouts/first-layout/components/footer';
import { setSubNavActiveLink } from './redux/templ'
import News from "./news";
import Media from "./media";
import Quotes from "./quotes/quotes";
import { setShowQuotes, setQuotesLabel, setShowMedia, setMediaLabel } from "./redux/templ";
import { selectLoadingConsumer } from './redux/list-config.selector';
import { BounceLoader } from 'react-spinners';

const mapStateToProps = (state) => {
    return {
        news: state?.news?.newsData,
        list: state.application.list,
        showQuotes: state.tempData?.showQuotes,
        showQuotesLabel: state.tempData?.showQuotesLabel,
        showMedia: state.tempData?.showMedia,
        showMediaLabel: state.tempData?.showMediaLabel,
        loading: selectLoadingConsumer(state),
    }
}

const loaderCss = `
    display: block;
    margin: 0 auto;
    border-color: red;
`
class PageNews extends React.Component {

    constructor(props){
        super(props);
        this.state={
            showQuotes: false,
            linkTitle: 'Quotes',
            showMedia: false,
            mediaLinkTitle: 'In the media',
        }
    }

    componentDidMount = async () => {
        let pathLast = this.props?.location?.pathname?.slice(-4);
        if(pathLast === 'otes') {
            setShowQuotes(true);
            setQuotesLabel(this.props.showQuotes ? 'Quotes' : 'Back  to News');
            setShowMedia(false);
            setMediaLabel('In the media');
        } else if(pathLast === 'edia') {
            setShowQuotes(false);
            setQuotesLabel('Quotes');
            setShowMedia(true);
            setMediaLabel(this.props.showMedia ? 'In the media' : 'Back  to News');
        } else {
            setShowMedia(false);
            setMediaLabel('In the media');
            setShowQuotes(false);
            setQuotesLabel('Quotes');
        }
        setSubNavActiveLink('news');
    }

    showQuotes = () => {
        let pathParams = this.props?.match?.params;
        setShowMedia(false);
        setMediaLabel('In the media');
        setShowQuotes(!this.props.showQuotes);
        setQuotesLabel(this.props.showQuotes ? 'Quotes' : 'Back  to News');
        if(!this.props.showQuotes) {
            this.props.history.push(`/${pathParams?.uuid}/${pathParams?.year}/news/quotes`);
        } else {
            this.props.history.push(`/${pathParams?.uuid}/${pathParams?.year}/news`);
        }    
    }

    showMedia = () => {
        let pathParams = this.props?.match?.params;
        setShowQuotes(false);
        setQuotesLabel('Quotes');
        setShowMedia(!this.props.showMedia);
        setMediaLabel(this.props.showMedia ? 'In the media' : 'Back  to News');
        if(!this.props.showMedia) {
            this.props.history.push(`/${pathParams?.uuid}/${pathParams?.year}/news/media`);
        } else {
            this.props.history.push(`/${pathParams?.uuid}/${pathParams?.year}/news`);
        }    
    }

    render() {
        return (
            <>
            {!this.props.loading && 
                <div className="mainContainer">
                    <Navbar />
                    <SubNavbar />
                    <div className="container mb-0 mt-0">
                        <div className="row align-content-center justify-content-center">
                            <a target="_blank" className="text-secondary" onClick={this.showMedia} style={{ cursor: 'pointer' }}><h6 className="text-center  under-line mb-4 pl-3 pr-3">{this.props?.showMediaLabel}</h6></a>
                            {this.props?.list?.urls?.pressRelease && <a href={this.props?.list?.urls?.pressRelease} target="_blank" className="text-secondary"><h6 className="text-center under-line mb-4 pl-3 pr-3">Press Release</h6></a>}
                            {this.props?.list?.urls?.usPressRelease && <a href={this.props?.list?.urls?.usPressRelease} target="_blank" className="text-secondary"><h6 className="text-center under-line mb-4 pl-3 pr-3">U.S. Press Release</h6></a>}
                            {this.props?.list?.urls?.indiaPressRelease && <a href={this.props?.list?.urls?.indiaPressRelease} target="_blank" className="text-secondary"><h6 className="text-center  under-line mb-4 pl-3 pr-3">India Press Release</h6></a>}
                            {this.props?.list?.urls?.slidePresentation && <a href={this.props?.list?.urls?.slidePresentation} target="_blank" className="text-secondary"><h6 className="text-center  under-line mb-4 pl-3 pr-3">Slide Presentation</h6></a>}
                            {this.props?.list?.urls?.listOfLeaders && <a href={this.props?.list?.urls?.listOfLeaders} target="_blank" className="text-secondary"><h6 className="text-center  under-line mb-4 pl-3 pr-3">LIST OF LEADERS</h6></a>}
                            <a target="_blank" className="text-secondary" onClick={this.showQuotes} style={{ cursor: 'pointer' }}><h6 className="text-center  under-line mb-4 pl-3 pr-3">{this.props?.showQuotesLabel}</h6></a>
                        </div>
                    </div>
                    {this.props?.list?.id && 
                        <div hidden={this.props.showQuotes || this.props.showMedia}><News /></div>
                    }
                    {this.props?.list?.id && 
                        <div hidden={!this.props.showQuotes}><Quotes history={this.props.history} urls={this.props?.list?.urls}/></div>
                    }
                    {this.props?.list?.id && 
                        <div hidden={!this.props.showMedia}><Media history={this.props.history}/></div>
                    }
                    <Footer />
                </div>
            }
            {this.props.loading && <div className="loader-container">
                <BounceLoader
                    css={loaderCss}
                    size={150}
                    color={"#f8b13e"}
                    loading={this.props.loading}
                />
                </div>}
            </>
        );
    }
}

export default connect(mapStateToProps)(PageNews) 