import React from "react";
import SocialIcon from "../social-icons";
import { connect } from "react-redux";
import { IoMdArrowRoundBack } from 'react-icons/io';
import { withRouter } from 'react-router';
import SEButton from '../../../../common/styles-editor/SE-button'
import Spacer from "../spacer";
import { store } from "../../../../store";
import { selectActiveCountryData, selectCountryListType } from "../../../../redux/country.selector";

const mapStateToProps = (state) => {
    const applicationMode = state.application.mode;
    const countryData = applicationMode == "admin" ? dummyProfile : selectActiveCountryData(state);
    console.log("SIDENAV: MAPSTATETOPROPS", countryData)
    return {
        listingType: selectCountryListType(state),
        list: state.application.list,
        homeLayUtils: state.application.homeLayUtils,
        applicationMode: applicationMode,
        listMetaData: state.application.listMetaData,
        fromWhere: state.tempData?.fromWhere,
        countryData: countryData,
        fromSlider: state.tempData?.fromSlider
    }
}
const dummyProfile = {
    "name": "Canada",
    "population": "555555",
    "diasporaPopulation": "333333",
    "percentPopulation": "3 percent",
    "description": "Lorem ipsum description",
    "description_2": "Lorem ipsum 2nd description",
    "imageUrl": "",
    "twitter": "https://twitter.com/ArcelorMittal",
}
class Sidenave extends React.PureComponent {
    goBack = () => {
        let props = this.props;
        if ((props.fromWhere !== 'local' && props.history?.length <= 3) ||
            (props.fromWhere !== 'local' && props.history?.length <= 3 & props.fromSlider)) {
            props.history.push(`/${this.props?.listingType.uuid}/${this.props?.list.year}`);
        } else {
            props.history.goBack();
        }
    }

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount = () => {
        // if (this.props.applicationMode === 'admin') {
        //     this.setState({ ...this.state, ...{ activeBio: this.dummyProfile } })
        // } else {
        //     this.setState({ ...this.state, ...{ activeBio: this.props?.countryData } })
        // }
        // store.subscribe(() => {
        //     let globalState = store.getState();
        //     let activeBio = globalState?.application?.activeBio;
        //     let mode = globalState?.application?.mode;
        //     this.setState({ ...this.state, ...{ activeBio: activeBio ? activeBio : mode == 'admin' ? this.dummyProfile : {} } })
        // })
    }
    getLabel = (id, name, value) => {
        if (value) {
            return <span
                style={this.props.homeLayUtils.getStyle(`col-${id}-bioLabel:css`, this.props.listMetaData, this.props.applicationMode)}>
                {this.props.homeLayUtils.getLabel(id, this.props.listingType, 'clmnMeta')}
                <SEButton sekey={`col-${id}-bioLabel:css`} type={"nonValuetext"}></SEButton>
            </span>
        }
    }
    getVal = (id, value) => {
        if (value) {
            return <span
                style={this.props.homeLayUtils.getStyle(`col-${id}-bioValue:css`, this.props.listMetaData, this.props.applicationMode)}>
                {value}
                <SEButton sekey={`col-${id}-bioValue:css`} type={"nonValuetext"}></SEButton>
            </span>
        }
    }


    render() {
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^")
        console.log("sidenav", this.props.countryData)
        return (
            <div className="container govt-leader-list">
                <nav className="navbar navbar-expand-lg navbar-dark profile-bg-india align-items-center justify-content-center fixed-top d-none d-sm-none d-lg-block d-xl-block" id="sideNav"
                    style={{ "background-color": this.props.countryData?.color_sidebar }}>
                    <SEButton sekey={"bioSideNav:css"} type={"div"}></SEButton>
                    <h3 className="mb-0 bio-back-button hand-cursor  text-left"
                        style={{ "color": this.props.countryData?.sidebarPrimaryColor }}>
                        <a><IoMdArrowRoundBack onClick={this.goBack} /></a>
                        <SEButton sekey={"bioSideNavBack:css"} type={"nonValuetext"}></SEButton>
                    </h3>
                    <a className="navbar-brand js-scroll-trigger justify-content-center align-items-center">
                        <span className="d-none d-lg-block">
                            <img src={this.props.countryData?.imageUrl} className="img-fluid img-profile  mx-auto mb-2" alt="..." />
                        </span>
                        <span className="d-lg-none d-block">
                            <img src={this.props.countryData?.imageUrl} className="rounded-circle avatar-size" alt="..." />
                        </span>
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <Spacer val="3" />
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <div className="col-12 flag-section">
                            {/* <img src={require("../../../../../assets/flags/canada.png")} class="card-img" alt="..." /> */}
                            <h3 className="js-scroll-trigger p-0 profile-rank-india" style={{ "color": this.props.countryData?.sidebarPrimaryColor }}>
                                {this.getVal('population', this.props.countryData?.population)}
                            </h3>
                            <h5 className="nav-link js-scroll-trigger font-weight-bolder mb-3 p-0" style={{ "color": this.props.countryData?.sidebarSecondaryColor }}>
                                {this.getLabel('population', 'Total population', this.props.countryData?.population)}
                            </h5>
                            <h3 className="js-scroll-trigger p-0 profile-rank-india" style={{ "color": this.props.countryData?.sidebarPrimaryColor }}>
                                {this.getVal('population', this.props.countryData?.diasporaPopulation)}
                            </h3>
                            <h5 className="nav-link js-scroll-trigger font-weight-bolder mb-3 p-0" style={{ "color": this.props.countryData?.sidebarSecondaryColor }}>
                                {this.getLabel('diasporaPopulation', 'Diaspora population', this.props.countryData?.diasporaPopulation)}
                                <p><small>({this.getVal('percentPopulation', this.props.countryData?.percentPopulation)} percent of population)</small></p>
                            </h5>
                            <SocialIcon data={this.props.countryData} owner={'BioPage'} />

                        </div>
                    </div>
                </nav>
                <div className="col d-lg-none d-block  text-center mt-4 profile-bg-india card pl-3 pl-3 pt-3 pb-3"
                    style={{ "background-color": this.props.countryData?.color_sidebar }}>
                    <SEButton sekey={`bioSideNav:css`} type={"div"}></SEButton>
                    <img className="avatar-mobile-border" src={this.props.countryData?.imageUrl} alt="" />
                    <Spacer val="3" />
                    <h3 className="mb-0 p-0 profile-rank-india">
                    {this.getVal('population', this.props.countryData?.population)}
                    </h3>
                    <p className="mb-2 p-0">
                    {this.getLabel('population', 'Total population', this.props.countryData?.population)}
                    </p>
                    <h3 className="mb-0 p-0 profile-rank-india">
                    {this.getVal('population', this.props.countryData?.diasporaPopulation)}
                    </h3>
                    <p className="mb-2 p-0">
                    {this.getLabel('diasporaPopulation', 'Diaspora population', this.props.countryData?.diasporaPopulation)}
                                <p><small>({this.getVal('percentPopulation', this.props.countryData?.percentPopulation)} percent of population)</small></p>
                    </p>
                    <Spacer val="3" />
                    <SocialIcon data={this.props.countryData} owner={'BioPage'} />
                </div>
            </div>
        );
    }
}


export default connect(mapStateToProps)(withRouter(Sidenave));
