import { createSelector } from 'reselect'
import { findWhere } from 'underscore';

const application = state => state.application;

export const selectLoadingConsumer = createSelector(
    [application],
    (application) => {
        return application.loadingConsumer;
    }
)
export const selectCache = createSelector(
    [application],
    (application) => {
        return application.cache;
    }
)
export const selectActiveBioQuote = createSelector(
    [application],
    (application) => {
        return application.activeBioQuote;
    }
)
export const selectList = createSelector(
    [application],
    (application) => {
        console.log("list-config-selectList Selector", application.list)
        return application.list;
    }
)

export const selectListingType = createSelector(
    [application],
    (application) => {
        console.log("list-config-selectListingType Selector", application.listingType)
        return application.listingType;
    }
)

export const selectUUIDAndYear = createSelector(
    [selectList],
    (list) => {
        if(!list)
            return null;
        const uuid = list.listingTypeUuid;
        const {year} = list;
        return {uuid, year};
    }
)

export const selectVideoId = createSelector(
    [selectList],
    (list) => {
        if(!list)
            return null;
        console.log("list-config-videoId Selector", list.videoId)
        return list.videoId;
    }
)

export const selectChildLists = createSelector(
    [selectList],
    (list) => {
        if(!list)
            return null;
        console.log("list-config-selectList Selector", list.childLists)
        return list.childLists;
    }
)

export const hasCountryMeta = createSelector(
    [selectChildLists],
    (childLists) => {
        let hasCountryMeta = false;
        if(childLists){
            const filtered = findWhere(childLists, {type: "country"})
            if(filtered)
                hasCountryMeta = true;
        }
        return hasCountryMeta;
    }
)

